import {
	VirtualTable,
	TextAutoWrap,
	SimpleTableBodyCellRoot,
} from '@compstak/ui-kit';
import styled from 'styled-components';
import { DataSet, DataSetType, Chart } from '../../../analytics';
import { BorderWrap, TableWrap } from '../../../../LeagueTables/LeagueTable';
import { UnderlyingLeasesDataTable } from './UnderlyingLeasesDataTable';

type UnderlyingDataTableProps = {
	dataSet: DataSet | undefined;
	chart: Chart;
};

export const UnderlyingDataTable = ({
	dataSet,
	chart,
}: UnderlyingDataTableProps) => {
	return (
		<StyledWrap>
			<TableWrap>
				<BorderWrap>
					{dataSet && dataSet.type === DataSetType.COMMERCIAL ? (
						<UnderlyingLeasesDataTable dataSet={dataSet} chart={chart} />
					) : (
						<VirtualTable
							id="chartbuilder-underlying-data"
							rows={[]}
							columns={[]}
							maxBodyHeight={600}
							bodyRowHeight={48}
							isBodyHoverable
						/>
					)}
				</BorderWrap>
			</TableWrap>
		</StyledWrap>
	);
};

const StyledWrap = styled.div`
	margin: 1rem 0 1.75rem 0;

	*::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	*::-webkit-scrollbar-thumb {
		border-radius: 8px;
		background-color: ${({ theme }) => theme.colors.gray.gray300};
	}

	${TextAutoWrap} {
		width: 100%;
	}
	${SimpleTableBodyCellRoot}:not(:nth-child(-n+4)) {
		justify-content: flex-end;
	}

	.body-cell.body-cell.hover {
		background-color: ${({ theme }) => theme.colors.neutral.n20};
	}
`;
