import { Button, ButtonProps, IconCross, MQB } from '@compstak/ui-kit';
import * as Dialog from '@radix-ui/react-dialog';
import { APP_HEADER_HEIGHT } from 'constants/sizes';
import {
	ComponentPropsWithoutRef,
	PropsWithChildren,
	ReactNode,
	RefObject,
	useEffect,
	useRef,
	useState,
} from 'react';
import styled, { css } from 'styled-components';
import { APP_WRAPPER_ID } from 'ui/UI';

type ModalView = 'fullScreen' | 'wide' | 'medium' | 'large';

export type ModalProps = PropsWithChildren<{
	className?: string;
	onClose?: () => void;
	contentProps?: Dialog.DialogContentProps &
		ComponentPropsWithoutRef<'div'> & { view?: ModalView };
	overlayChildren?: (ref: RefObject<HTMLDivElement>) => ReactNode;
}>;

export const Modal = ({
	children,
	className,
	onClose,
	contentProps,
	overlayChildren,
}: ModalProps) => {
	const overlayChildrenRef = useRef<HTMLDivElement>(null);

	const [container, setContainer] = useState<HTMLElement | null>(null);

	useEffect(() => {
		const container = document.getElementById(APP_WRAPPER_ID);
		if (container) {
			setContainer(container);
		}
	}, []);

	const onInteractOutside: Dialog.DialogContentProps['onInteractOutside'] = (
		ev
	) => {
		const target = ev.target as HTMLElement;
		if (
			overlayChildrenRef.current?.contains(target) ||
			target.className.match('feedback') ||
			target.parentElement?.className.match('feedback')
		) {
			ev.preventDefault();
			return;
		}
	};

	return (
		<Dialog.Root open onOpenChange={(open) => !open && onClose?.()}>
			<Dialog.Portal container={container}>
				<ModalOverlay className={className}>
					<ModalCloseButton>
						<ModalCloseIcon />
					</ModalCloseButton>
					{overlayChildren?.(overlayChildrenRef)}
					<ModalContent
						onInteractOutside={onInteractOutside}
						onPointerDownOutside={onPointerDownOutsideModal}
						{...contentProps}
					>
						{children}
					</ModalContent>
				</ModalOverlay>
			</Dialog.Portal>
		</Dialog.Root>
	);
};

export const ModalOverlay = styled(Dialog.Overlay)`
	display: flex;
	top: ${APP_HEADER_HEIGHT}px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	overflow-y: auto;
	z-index: ${({ theme }) => theme.zIndex.overlay + theme.zIndex.modal};
	position: fixed;
	width: 100%;
	height: calc(100% - ${APP_HEADER_HEIGHT}px);
	background-color: ${({ theme }) => theme.colors.gray.gray400TransparentCC};
	backdrop-filter: blur(5px);
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

export const ModalButtons = styled.div`
	display: flex;
	justify-content: flex-end;
	padding-top: 1rem;
	gap: 1rem;
`;

const fullScreenCss = css`
	display: flex;
	flex-direction: column;
	margin: 0;
	height: 100%;
	overflow-y: auto;
	max-width: unset;
`;

const wideScreenCss = css`
	@media (min-width: ${MQB.T_834}) {
		max-width: 1360px;
		margin: 0.5rem;
	}
`;

const mediumScreenCss = css`
	@media (min-width: ${MQB.T_834}) {
		max-width: 700px;
	}
`;

const largeScreenCss = css`
	@media (min-width: ${MQB.T_834}) {
		max-width: 1000px;
	}
`;

export const ModalContent = styled(Dialog.Content)<{ view?: ModalView }>`
	width: 100%;
	min-width: 300px;
	max-width: 560px;
	margin: 3rem;
	padding: 2.5rem;
	background-color: ${({ theme }) => theme.colors.white.white};
	border-radius: 3px;
	/* to mimic full screen */
	@media (max-width: ${MQB.T_834}) {
		${fullScreenCss};
	}
	${({ view }) => view === 'fullScreen' && fullScreenCss};
	${({ view }) => view === 'wide' && wideScreenCss};
	${({ view }) => view === 'medium' && mediumScreenCss};
	${({ view }) => view === 'large' && largeScreenCss};
`;

export const ModalCloseButton = styled(Dialog.Close)`
	all: unset;
	position: absolute;
	top: 26px;
	right: 30px;
	width: 30px;
	height: 30px;
	z-index: 10;
	cursor: pointer;
	opacity: 0.6;
	&:hover {
		opacity: 1;
	}
`;

export const ModalCloseIcon = styled(IconCross)`
	width: 30px;
	height: 30px;
	fill: hsl(226, 15%, 72%);
`;

export const ModalTitle = styled.h3(
	({ theme }) => css`
		margin-bottom: 1.5rem;

		font-family: ${theme.typography.fontFamily.gotham};
		text-align: left;
		font-size: 1.5rem;
		line-height: 1.6;
		font-weight: 400;
		color: ${theme.colors.gray.gray700};
	`
);

export const ModalParagraph = styled.p`
	margin-bottom: 0.75rem;
	font-size: 1rem;
	font-family: ${({ theme }) => theme.typography.fontFamily.gotham};
`;

export const ModalInputLabel = styled.label<{ isError?: boolean }>`
	display: block;
	color: ${({ isError, theme }) =>
		isError ? theme.colors.red.red500 : theme.colors.blue.blue400};
	margin-bottom: 0.75rem;

	input,
	textarea {
		border-color: ${({ isError, theme }) =>
			isError ? theme.colors.red.red500 : theme.colors.neutral.n80};
		margin-bottom: 0;
	}
`;

export const ModalInputMessage = styled.div<{ isError?: boolean }>`
	font-size: 0.75rem;

	color: ${({ isError, theme }) =>
		isError ? theme.colors.red.red500 : theme.colors.neutral.n80};
`;

export const ModalCenteredContent = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;

export const ModalScrollableInnerContent = styled.div`
	overflow-y: auto;

	line-height: 24px;

	max-height: 25rem;
	@media (max-width: ${MQB.T_834}) {
		max-height: 31rem;
	}
`;

export const ModalButton = (props: ButtonProps) => {
	return <Button size="l" type="button" {...props} />;
};

export const onInteractOutsideModal: (
	event: Parameters<
		NonNullable<Dialog.DialogContentProps['onInteractOutside']>
	>[0],
	defaultAction?: () => void
) => void = (event, defaultAction) => {
	const target = event.target as HTMLElement;

	if (
		target.className.match?.('feedback') ||
		target.parentElement?.className.match?.('feedback')
	) {
		event.preventDefault();
	} else if (defaultAction) {
		defaultAction();
	}
};

// to prevent closing when clicking on the scrollbar
// https://github.com/radix-ui/primitives/issues/1280#issuecomment-1198248523
export const onPointerDownOutsideModal: Dialog.DialogContentProps['onPointerDownOutside'] =
	(e) => {
		const currentTarget = e.currentTarget as HTMLElement;
		if (e.detail.originalEvent.offsetX > currentTarget.clientWidth) {
			e.preventDefault();
		}
	};
