import { SEARCH_MAX_LIMIT, usePortfolioLeasesQuery } from 'api';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { routes } from 'router';

type Props = {
	portfolioId: number;
};

export const useListViewLeases = ({ portfolioId }: Props) => {
	const { filters } = usePortfolioFilters();
	const [searchParams] = routes.portfolioByIdView.useSearchParams();

	return usePortfolioLeasesQuery({
		portfolioId,
		filters: {
			...filters,
			...searchParams,
		},
		offset: 0,
		limit: SEARCH_MAX_LIMIT,
	});
};
