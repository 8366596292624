import { ChartBox } from './ChartSelect';
import { colors } from '@compstak/ui-kit';
import {
	VictoryChart,
	VictoryScatter,
	VictoryAxis,
	VictoryLabel,
	VictoryZoomContainer,
	VictoryLine,
} from 'victory';
import { Spacer } from 'PortfolioAnalytics/UI';
import { styled } from 'styled-components';
import { usePortfolioPerformanceQuery } from 'api/portfolio/charts/usePortfolioPerformanceQuery.ts';
import { usePortfolioByIdQueryV2 } from 'api/portfolio/portfolioById/usePortfolioByIdQueryV2';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import {
	PerformanceChartPopup,
	DataPoint,
} from './PortfolioPerformanceChartPopup';
import { useState, useRef, useCallback, useMemo } from 'react';
import { Spinner } from '@compstak/ui-kit';
import { ChartContainer } from 'PortfolioAnalytics/styles/PortfolioUI';
import { NoDataTab } from 'Components/NoDataMessaging/NoDataMessaging';
import { MQB } from '@compstak/ui-kit';
import { useMediaQuery } from 'react-responsive';
import { OVERVIEW_CHARTS_NO_DATA_COMPONENT_HEIGHT } from 'PortfolioAnalytics/constants';

const { blue500 } = colors.blue;
const { white } = colors.white;
const { gray700 } = colors.gray;
const { n30, n100, n300 } = colors.neutral;

type PropertyPerformanceChartProps = {
	portfolioId: number;
	filters: FiltersState;
};

const TICK_MULTIPLIERS = [1, 0.75, 0.5, 0.25];
const xTickValues = [
	...TICK_MULTIPLIERS.map((multiplier) => 100 * multiplier),
	0,
];

export const PropertyPerformanceMatrixChart = ({
	portfolioId,
	filters,
}: PropertyPerformanceChartProps) => {
	return (
		<ChartBox
			chartName="Property Performance Matrix"
			displaySelector={false}
			chartToolTip={PORTFOLIO_PERFORMANCE_TOOLTIP}
		>
			<PropertyPerformanceMatrixChartContent
				portfolioId={portfolioId}
				filters={filters}
			/>
		</ChartBox>
	);
};

export const PropertyPerformanceMatrixChartContent = ({
	portfolioId,
	filters,
}: PropertyPerformanceChartProps) => {
	const [activeDataPoint, setActiveDataPoint] = useState<DataPoint | null>(
		null
	);
	const scaleRef = useRef<{
		x: ((value: number) => number) | null;
		y: ((value: number) => number) | null;
	}>({ x: null, y: null });

	const { data, isFetching: isFetchingPortfolioPerformance } =
		usePortfolioPerformanceQuery(portfolioId, filters);
	const { data: portfolioV2, isFetching: isFetchingPortfolioById } =
		usePortfolioByIdQueryV2({
			id: portfolioId,
		});
	const isFetching = isFetchingPortfolioPerformance || isFetchingPortfolioById;
	const dataToUse = useMemo(() => data?.values || [], [data?.values]);
	const maxPerformance = useMemo(
		() => 100 * Math.max(...dataToUse.map((d) => Math.abs(d.performance))),
		[dataToUse]
	);
	const maxYTick = Math.ceil(maxPerformance / 2) * 2;
	const minYTick = -maxYTick;

	const yTickValues = useMemo(
		() => [
			...TICK_MULTIPLIERS.map((multiplier) => multiplier * minYTick),
			0,
			...TICK_MULTIPLIERS.slice()
				.reverse()
				.map((multiplier) => multiplier * maxYTick),
		],
		[minYTick, maxYTick]
	);

	const transformedData = useMemo(
		() =>
			dataToUse.map((d) => ({
				performance: 100 * d.performance,
				risk: 100 * d.risk,
				reversedRisk: 100 * (1 - d.risk),
				size: 4.5,
				propertyId: d.propertyId,
			})),
		[dataToUse]
	);
	const is1280 = useMediaQuery({ minWidth: MQB.D_1280 });
	const is1536 = useMediaQuery({ minWidth: MQB.D_1536 });
	const is1920 = useMediaQuery({ minWidth: MQB.D_1920 });
	const isNarrowScreen = !is1280;

	const chartStyles = useMemo(
		() => ({
			chartWidth: is1920 ? 520 : is1536 ? 480 : 430,
			chartHeight: is1920 ? 340 : is1536 ? 330 : 320,
			chartPadding: is1920
				? { left: 40, top: 20, right: 5, bottom: 30 }
				: is1536
					? { left: 5, top: 20, right: 5, bottom: 30 }
					: { left: 2, top: 20, right: 2, bottom: 30 },
			containerWidth: is1920 ? 614 : is1536 ? 540 : 450,
			containerHeight: is1920 ? 415 : is1536 ? 405 : 395,
			yAxisOffset: is1920 ? 50 : 25,
			yAxisTitleRightMargin: is1920 ? '-180px' : '-150px',
			yAxisTitleLeftMargin: is1920 ? '40px' : '10px',
		}),
		[is1536, is1920]
	);

	const handleDataPointClick = useCallback(
		(datum: DataPoint) => {
			activeDataPoint ? setActiveDataPoint(null) : setActiveDataPoint(datum);
		},
		[activeDataPoint]
	);

	const handleDataPointMouseOver = useCallback((datum: DataPoint) => {
		setActiveDataPoint(datum);
	}, []);

	const getPixelPosition = useCallback(
		(datum: DataPoint) => {
			const scales = scaleRef.current;
			if (
				!scales.x ||
				!scales.y ||
				typeof scales.x !== 'function' ||
				typeof scales.y !== 'function'
			) {
				return { x: 0, y: 0 };
			}
			const {
				chartWidth,
				chartHeight,
				chartPadding,
				containerWidth,
				containerHeight,
			} = chartStyles;

			const x = scales.x(datum.reversedRisk);
			const y = scales.y(datum.performance);

			if (isNaN(x) || isNaN(y)) {
				console.error('Invalid scale calculation results');
				return { x: 0, y: 0 };
			}

			const calculatedChartWidth =
				containerWidth - (chartPadding.left + chartPadding.right);
			const calculatedChartHeight =
				containerHeight - (chartPadding.top + chartPadding.bottom);

			const scaledX =
				(x / chartWidth) * calculatedChartWidth + chartPadding.left;
			const scaledY =
				(y / chartHeight) * calculatedChartHeight + chartPadding.top + 10;

			return {
				x: scaledX,
				y: scaledY,
			};
		},
		[scaleRef, chartStyles]
	);

	return !isFetching && (transformedData.length === 0 || !data) ? (
		<ChartContainer>
			<NoDataTab
				minHeight={OVERVIEW_CHARTS_NO_DATA_COMPONENT_HEIGHT}
				message=""
			/>
		</ChartContainer>
	) : isFetching ? (
		<ChartContainer>
			<Spinner size="l" />
		</ChartContainer>
	) : (
		<>
			<PerformanceMatrixContainer
				containerWidth={chartStyles.containerWidth}
				containerHeight={chartStyles.containerHeight}
			>
				<LabelRow chartWidth={chartStyles.chartWidth}>
					<QuadrantLabel>High Performer, High Risk</QuadrantLabel>
					<QuadrantLabel>Low Performer, Low Risk</QuadrantLabel>
				</LabelRow>
				<ChartAndYLabel>
					<YAxisTitle
						marginLeft={chartStyles.yAxisTitleLeftMargin}
						marginRight={chartStyles.yAxisTitleRightMargin}
					>
						Rent vs. Market Estimate (%)
					</YAxisTitle>
					<VictoryChart
						containerComponent={
							<VictoryZoomContainer
								onZoomDomainChange={() => {
									setActiveDataPoint(null);
								}}
								allowPan={true}
								constrainToVisibleArea={true}
								style={{
									border: 'none',
									padding: 0,
									width: chartStyles.chartWidth,
									height: chartStyles.chartHeight,
								}}
							/>
						}
						domainPadding={{ x: 20, y: 20 }}
						padding={chartStyles.chartPadding}
					>
						{/* X Axis */}
						<VictoryAxis
							tickValues={xTickValues}
							tickFormat={(tick: number) => `${100 - tick}%`}
							offsetY={20}
							tickLabelComponent={
								<VictoryLabel renderInPortal style={{ fontSize: 11 }} />
							}
							style={{
								axis: { stroke: 'none' },
								tickLabels: {
									fontSize: 11,
									padding: 5,
									color: gray700,
								},
								grid: { stroke: n30, strokeWidth: 1, strokeDasharray: '4,4' },
							}}
						/>
						{/* Y axis */}
						<VictoryAxis
							dependentAxis
							tickValues={yTickValues}
							offsetX={chartStyles.yAxisOffset}
							tickLabelComponent={
								<VictoryLabel renderInPortal style={{ fontSize: 11 }} />
							}
							tickFormat={(d: string) => `${d}%`}
							style={{
								axis: { stroke: 'none' },
								tickLabels: {
									fontSize: 11,
									padding: 5,
									color: gray700,
								},
								grid: { stroke: n30, strokeWidth: 1, strokeDasharray: '4,4' },
							}}
						/>
						{/* Vertical Divider */}
						<VictoryLine
							data={[
								{ x: 50, y: 1.05 * minYTick },
								{ x: 50, y: 1.05 * maxYTick },
							]}
							style={{
								data: { stroke: 'black', strokeWidth: 1 },
							}}
							x={() => 50}
						/>

						{/* Horizontal Divider */}
						<VictoryLine
							data={[
								{ x: -5, y: 0 },
								{ x: 105, y: 0 },
							]}
							style={{
								data: { stroke: 'black', strokeWidth: 1 },
							}}
							y={() => 0}
						/>

						{/* Scatter Points */}
						<VictoryScatter
							data={transformedData}
							x="reversedRisk"
							y="performance"
							labels={({ datum }) => datum.y}
							style={{
								data: {
									fill: blue500,
									cursor: 'pointer',
								},
							}}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onClick: (event, clickedProps) => {
											if (clickedProps.scale) {
												scaleRef.current = clickedProps.scale;
											}
											handleDataPointClick(clickedProps.datum);
										},
										onMouseOver: (event, hoveredProps) => {
											if (hoveredProps.scale) {
												scaleRef.current = hoveredProps.scale;
											}
											handleDataPointMouseOver(hoveredProps.datum);
										},
									},
								},
							]}
						/>
					</VictoryChart>
				</ChartAndYLabel>
				<XAxisTitle> % of Lease SqFt expiring within 24 months </XAxisTitle>
				<LabelRow chartWidth={chartStyles.chartWidth}>
					<QuadrantLabel>Low Performer, High Risk</QuadrantLabel>
					<QuadrantLabel>Low Performer, Low Risk</QuadrantLabel>
				</LabelRow>
				{activeDataPoint && portfolioV2 && !isNarrowScreen && (
					<PerformanceChartPopup
						closePopup={() => {
							setActiveDataPoint(null);
						}}
						portfolioV2={portfolioV2}
						dataPoint={activeDataPoint}
						left={`${getPixelPosition(activeDataPoint).x}px`}
						top={`${getPixelPosition(activeDataPoint).y}px`}
					/>
				)}
			</PerformanceMatrixContainer>
			<PropertyPerformanceLegend>
				<PropertyPerformanceLegendColor />
				Property
			</PropertyPerformanceLegend>
		</>
	);
};

const PerformanceMatrixContainer = styled.div<{
	containerWidth: number;
	containerHeight: number;
}>`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	border-radius: 3px;
	border: 1px solid ${n100};
	width: ${({ containerWidth }) => `${containerWidth}px`};
	height: ${({ containerHeight }) => `${containerHeight}px`};
	margin: 1rem auto;
`;

const ChartAndYLabel = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const LabelRow = styled.div<{ chartWidth: number }>`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	width: ${({ chartWidth }) => `${chartWidth}px`};
`;

const QuadrantLabel = styled.div`
	border-radius: 2px;
	border: 1px solid ${n100};
	background: ${n100};
	width: 170px;
	height: 22px;
	padding: 5px 7px;
	color: ${white};
	text-align: center;
	font-size: 0.7rem;
	font-style: normal;
	font-weight: 350;
	line-height: 0.7rem;
	letter-spacing: 0.3px;
`;

const XAxisTitle = styled.div`
	margin-top: 5px;
	margin-bottom: 10px;
	background-color: ${n30};
	color: ${n300};
	font-size: 0.6rem;
	font-weight: 350;
	line-height: 0.7rem;
	letter-spacing: 0.3px;
	padding: 0 15px;
`;

const YAxisTitle = styled.div<{ marginRight: string; marginLeft: string }>`
	display: inline-block;
	background-color: ${n30};
	color: ${n300};
	font-size: 0.6rem;
	font-weight: 350;
	line-height: 0.7rem;
	letter-spacing: 0.3px;
	transform: rotate(-90deg);
	transform-origin: left center;
	margin-right: ${({ marginRight }) => marginRight};
	margin-left: ${({ marginLeft }) => marginLeft};
	min-width: 155px;
	padding: 0 15px;
	position: relative;
	top: 70px;
`;

const PropertyPerformanceLegend = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	column-gap: 0.4rem;
	height: 25px;
	margin-top: 0.75rem;
	width: 100%;
	text-align: center;
	font-size: 0.75rem;
`;

const PropertyPerformanceLegendColor = styled.div`
	background-color: ${blue500};
	border-radius: 50%;
	height: 0.5rem;
	width: 0.5rem;
`;

const PORTFOLIO_PERFORMANCE_TOOLTIP = (
	<>
		<Spacer>
			The Portfolio Performance chart plots the performance and risk of every
			property in the portfolio.
		</Spacer>
		<Spacer>
			Performance is based on the property's rent compared with market rent.
		</Spacer>
		<Spacer>
			Risk is the percentage of leases expiring in the next 24 months.
		</Spacer>
		<Spacer>Click on any property in the chart for more information</Spacer>
	</>
);
