import styled from 'styled-components';

export const DashboardCardLoadingContainer = styled.div`
	height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
	background: ${(p) => p.theme.colors.white.white};
	border-radius: 0.125rem;
	box-sizing: border-box;
`;

export const PortfolioOverviewSectionHeader = styled.h2`
	padding: 1em;
	font-size: 1.25em;
	font-weight: 400;
	line-height: 1.25em;
	letter-spacing: 0.215px;
`;
