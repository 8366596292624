import { Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQueryV2 } from 'api/portfolio/portfolioById/usePortfolioByIdQueryV2';
import { routes } from 'router';
import styled from 'styled-components';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { MapListLoadingContainer } from '../UI';
import { PortfolioTable } from './PortfolioTable';
import { PortfolioTableLeases } from './PortfolioTableLeases';
import { ListViewAndMapViewToolbarV2 } from './ListViewAndMapViewToolbarV2';
import { ExportCompsButton } from 'Components/Export';
import { useLeasesListAndMapViewContext } from './LeasesListAndMapViewProvider';
import { RemovePropertiesButton } from 'PortfolioAnalytics/Components/RemovePropertiesButton';
import { usePortfolioProperties } from 'PortfolioAnalytics/Properties/PortfolioPropertiesProvider';

type Props = {
	portfolioId: number;
};

export const ListViewV2 = ({ portfolioId }: Props) => {
	const [searchParams] = routes.portfolioByIdView.useSearchParams();

	const isLeasesList =
		!!searchParams.expirationYears ||
		!!searchParams.tenantNames ||
		!!searchParams.tenantIndustry;

	const { filters } = usePortfolioFilters();

	const { data: portfolio } = usePortfolioByIdQueryV2({
		id: portfolioId,
		filters,
	});

	const { selectionState: propertiesSelection } = usePortfolioProperties();
	const leasesSelection = useLeasesListAndMapViewContext();

	if (!portfolio) {
		return (
			<MapListLoadingContainer>
				<Spinner />
			</MapListLoadingContainer>
		);
	}

	return (
		<Root>
			<ListViewAndMapViewToolbarV2
				view="list"
				portfolio={portfolio}
				searchParams={searchParams}
			/>
			<>{isLeasesList ? <PortfolioTableLeases /> : <PortfolioTable />}</>
			<ListViewButtonsContainer>
				<ExportCompsButton
					buttonType="table"
					compIds={
						isLeasesList
							? leasesSelection.selection
							: propertiesSelection.selection
					}
					compType={isLeasesList ? 'lease' : 'property'}
					getComps={
						isLeasesList
							? leasesSelection.getSelectedRows
							: propertiesSelection.getSelectedRows
					}
				/>
				{!isLeasesList && (
					<RemovePropertiesButton
						portfolio={portfolio}
						selectionState={propertiesSelection}
					/>
				)}
			</ListViewButtonsContainer>
		</Root>
	);
};

const Root = styled.div`
	display: grid;
	grid-template-rows: auto 1fr auto;
	box-sizing: border-box;
	height: 100%;
	* {
		box-sizing: border-box;
	}
`;

const ListViewButtonsContainer = styled.div`
	display: flex;
	margin: 1rem;
	gap: 1rem;
`;
