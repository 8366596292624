import { Market } from '@compstak/common';
import {
	BuildingClassId,
	PortfolioLeaseExpirationsMonths,
	PortfolioV2Market,
	PropertyTypeId,
	SpaceTypeName,
} from 'api';
import constate from 'constate';
import { useMarkets } from 'hooks/useMarkets';
import { isEqual, pick, uniqBy } from 'lodash';
import { FilterNumberInterval } from 'models/filters/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { routes } from 'router';

type Props = {
	portfolioMarkets: PortfolioV2Market[];
};

type UsePortfolioFilters = {
	filters: FiltersState;
	setFilters: React.Dispatch<React.SetStateAction<InnerFiltersState>>;
	resetFilters: () => void;
	portfolioMarkets: PortfolioV2Market[];
};

const SEARCH_PARAMS_KEYS: (keyof InnerFiltersState)[] = [
	'submarketIds',
	'propertyTypeIds',
	'buildingClassIds',
	'leaseExpirationsMonths',
	'buildingSize',
	'spaceTypes',
];

export const [PortfolioFiltersProvider, usePortfolioFilters] = constate(
	({ portfolioMarkets: _portfolioMarkets }: Props): UsePortfolioFilters => {
		const [searchParams, setSearchParams] =
			routes.portfolioByIdView.useSearchParams();
		const [filters, _setFilters] = useState<InnerFiltersState>({
			...initialState,
			...pick(searchParams, ...SEARCH_PARAMS_KEYS),
		});

		const marketsState = useMarkets();

		const setFilters: React.Dispatch<React.SetStateAction<InnerFiltersState>> =
			useCallback(
				(newFilters) => {
					_setFilters(newFilters);
					setSearchParams({
						...searchParams,
						...(typeof newFilters === 'function'
							? newFilters(filters)
							: newFilters),
					});
				},
				[setSearchParams]
			);

		const resetFilters = useCallback(() => {
			setFilters(initialState);
		}, []);

		const portfolioMarkets = useMemo(() => {
			const uniquePortfolioMarkets = _portfolioMarkets.reduce<
				Record<number, PortfolioV2Market>
			>((acc, market) => {
				if (acc[market.marketId]) {
					const submarkets = uniqBy(
						[...market.submarkets, ...acc[market.marketId].submarkets],
						'submarketId'
					);

					acc[market.marketId] = { ...acc[market.marketId], submarkets };
				} else {
					acc[market.marketId] = market;
				}

				return acc;
			}, {});

			return Object.values(uniquePortfolioMarkets);
		}, [_portfolioMarkets]);

		// recalculate `submarketIds` filter if portfolios submarkets have changed (previously selected submarkets might be not relevant anymore)
		useEffect(() => {
			const portfolioSubmarketIds = portfolioMarkets
				.flatMap(({ submarkets }) => submarkets)
				.map(({ submarketId }) => submarketId);
			const submarketIds = filters.submarketIds.filter((submarketId) => {
				return portfolioSubmarketIds.includes(submarketId);
			});

			if (!isEqual(submarketIds, filters.submarketIds)) {
				setFilters((prevFilters) => ({ ...prevFilters, submarketIds }));
			}
		}, [portfolioMarkets]);

		const markets = useMemo(() => {
			return portfolioMarkets
				.filter((m) => {
					return m.submarkets.some((s) =>
						filters.submarketIds.includes(s.submarketId)
					);
				})
				.map((m) => marketsState[m.marketId]);
		}, [portfolioMarkets, filters.submarketIds, marketsState]);

		return useMemo(
			() => ({
				filters: {
					...filters,
					markets,
				},
				setFilters,
				resetFilters,
				portfolioMarkets,
			}),
			[filters, setFilters, resetFilters, markets, portfolioMarkets]
		);
	}
);

const initialState: InnerFiltersState = {
	submarketIds: [],
	propertyTypeIds: [],
	buildingClassIds: [],
	leaseExpirationsMonths: null,
	buildingSize: null,
	spaceTypes: [],
};

type InnerFiltersState = Omit<FiltersState, 'markets'>;

export type FiltersState = {
	submarketIds: number[];
	propertyTypeIds: PropertyTypeId[];
	buildingClassIds: BuildingClassId[];
	leaseExpirationsMonths: PortfolioLeaseExpirationsMonths;
	buildingSize: FilterNumberInterval | null;
	markets: Market[];
	spaceTypes: SpaceTypeName[];
};
