import { ReactNode } from 'react';
import { Spinner } from '@compstak/ui-kit';
import { NoDataTab } from 'Components/NoDataMessaging/NoDataMessaging';
import {
	ChartContainer,
	ChartRoot,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import { Styled } from 'PortfolioAnalytics/Charts/PortfolioTrend/styled';
import {
	BENCHMARKING_NO_DATA_COMPONENT_HEIGHT,
	BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE,
} from '../constants';

interface BenchmarkingChartStateWrapperProps {
	isError: boolean;
	isFetching: boolean;
	noData: boolean;
	showNoDataMessage: boolean;
	children: ReactNode;
}

export const BenchmarkingChartStateWrapper = ({
	isError,
	isFetching,
	noData,
	showNoDataMessage = false,
	children,
}: BenchmarkingChartStateWrapperProps) => {
	if (isError) {
		return (
			<Styled.Error show={true}>
				<div>An error occurred while loading the chart</div>
			</Styled.Error>
		);
	}

	if (isFetching) {
		return (
			<ChartContainer>
				<Spinner size="l" />
			</ChartContainer>
		);
	}

	if (noData) {
		return (
			<ChartContainer>
				<NoDataTab
					minHeight={BENCHMARKING_NO_DATA_COMPONENT_HEIGHT}
					message={showNoDataMessage && BENCHMARKING_SELECT_PORTFOLIOS_MESSAGE}
				/>
			</ChartContainer>
		);
	}

	return <ChartRoot>{children}</ChartRoot>;
};
