import { useMemo } from 'react';
import { groupBy } from 'lodash';
import {
	Portfolio,
	PortfolioLeaseExpirationsBySpaceTypesByYears,
	SpaceTypeName,
} from 'api';
import {
	Legend,
	LegendColor,
	LegendItem,
	LegendLabel,
	ChartContainer,
	ChartAndLegendContainer,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import { useTheme } from 'styled-components';
import { CHART_HEIGHT } from './chartsConstants';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryVoronoiContainer,
	VictoryLabel,
	VictoryStack,
	VictoryTooltip,
} from 'victory';
import { ChartBox, ChartTypeLease } from './ChartSelect';
import { PORTFOLIO_CHART_COLORS } from '../constants';
import { NoDataTab } from 'Components/NoDataMessaging/NoDataMessaging';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { OVERVIEW_CHARTS_NO_DATA_COMPONENT_HEIGHT } from 'PortfolioAnalytics/constants';

type Props = {
	portfolio: Portfolio;
	minBarChartWidth?: number;
};

const LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME = 'Upcoming Lease Expirations';
const NUMBER_OF_YEARS = 5;

export const LeaseExpirationsBySpaceTypeChart = ({
	portfolio,
	minBarChartWidth,
}: Props) => {
	const { leaseExpirationsBySpaceTypesByYears } = portfolio;

	const chartData = useMemo(() => {
		if (!leaseExpirationsBySpaceTypesByYears) return [];
		const expirationsByValidYears =
			leaseExpirationsBySpaceTypesByYears.filter(filterValidYears);
		const expirationsGroupedBySpaceType = groupBy(
			expirationsByValidYears,
			(e) => e.spaceType
		);
		return Object.entries(expirationsGroupedBySpaceType) as [
			SpaceTypeName,
			PortfolioLeaseExpirationsBySpaceTypesByYears[],
		][];
	}, [leaseExpirationsBySpaceTypesByYears]);

	const navigate = useNavigate();

	const theme = useTheme();

	return (
		<ChartBox<'lease'> chartName={LEASE_EXPIRATION__BY_SPACE_TYPE_CHART_NAME}>
			{(selectedChart) => {
				if (!chartData.length) {
					return (
						<ChartContainer>
							<NoDataTab
								minHeight={OVERVIEW_CHARTS_NO_DATA_COMPONENT_HEIGHT}
								message=""
							/>
						</ChartContainer>
					);
				}

				const chartKey = CHART_TYPE_TO_KEY[selectedChart.type];

				return (
					<ChartAndLegendContainer>
						<VictoryChart
							width={minBarChartWidth ? minBarChartWidth : 480}
							height={CHART_HEIGHT}
							padding={{ left: 90, top: 50, right: 50, bottom: 50 }}
							containerComponent={
								<VictoryVoronoiContainer
									responsive={false}
									style={{ margin: '0 auto' }}
									activateData={true}
								/>
							}
						>
							<VictoryAxis
								dependentAxis
								offsetX={50}
								tickLabelComponent={
									<VictoryLabel renderInPortal style={{ fontSize: 11 }} />
								}
								style={{
									axis: { stroke: '#F0F0F0', strokeWidth: 2 },
									axisLabel: {
										fontSize: 20,
										color: '#A3A3A3',
										textOverflow: 'ellipsis',
										whiteSpace: 'no-wrap',
										overflow: 'hidden',
									},
									grid: { stroke: '#F0F0F0' },
									ticks: { stroke: '#F0F0F0' },
									tickLabels: {
										padding: 0,
										color: '#A3A3A3',
										fontSize: 10,
										lineHeight: 10,
									},
								}}
							/>
							<VictoryAxis
								padding={{ left: 90, top: 50, right: 10, bottom: 50 }}
								fixLabelOverlap
								tickFormat={(yAxisValue: number) => `${yAxisValue}`}
								tickLabelComponent={
									<VictoryLabel renderInPortal style={{ fontSize: 11 }} />
								}
								style={{
									axis: { stroke: '#F0F0F0', strokeWidth: 2 },
									axisLabel: { fontSize: 20, padding: 0, color: '#A3A3A3' },
									grid: { stroke: '#F0F0F0', border: '1px solid black' },
									ticks: { stroke: '#F0F0F0' },
									tickLabels: {
										padding: 5,
										color: '#A3A3A3',
										fontSize: 10,
										lineHeight: 10,
									},
								}}
							/>
							<VictoryStack
								colorScale={PORTFOLIO_CHART_COLORS}
								style={{
									parent: {
										height: 300,
									},
									data: {
										stroke: '#fff',
										strokeWidth: 2,
									},
									labels: {
										fontFamily: 'Roboto Mono',
										fontSize: 11,
									},
								}}
							>
								{chartData.map(([spaceTypeName, spaceTypeGroup]) => {
									const aggregatedDataBySpaceTypeAndExpiration =
										spaceTypeGroup.reduce((acc: BarDataAccumulator, data) => {
											const expirationYear = data.expirationYear;
											if (!acc[expirationYear]) {
												acc[expirationYear] = {
													spaceTypeName,
													expirationYear,
													yValue: 0,
												};
											}
											acc[expirationYear].yValue += data[chartKey];
											return acc;
										}, {});

									const barData = Object.values(
										aggregatedDataBySpaceTypeAndExpiration
									);
									return (
										<VictoryBar
											key={spaceTypeName}
											barRatio={0.6}
											data={barData}
											x="expirationYear"
											y="yValue"
											style={{
												data: {
													cursor: 'pointer',
												},
											}}
											events={[
												{
													target: 'data',
													eventHandlers: {
														onClick: (e, clickedProps) => {
															const { expirationYear } = clickedProps.datum;
															navigate(
																routes.portfolioByIdView.toHref(
																	{
																		portfolioId: portfolio.id,
																		viewType: 'list',
																	},
																	{
																		expirationYears: [expirationYear],
																		spaceTypes: [spaceTypeName],
																	}
																)
															);
														},
													},
												},
											]}
											labels={() => ' '}
											labelComponent={
												<VictoryTooltip
													flyoutStyle={{
														fill: theme.colors.gray.gray700,
													}}
													style={{ fill: theme.colors.white.white }}
													text={(d) => {
														const tooltipText =
															d.datum._y && d.datum._y > 0
																? `Click the bar to view \n ${d?.datum?.spaceTypeName} comps \n expiring in ${d?.datum?.expirationYear}`
																: `${d?.datum?.expirationYear}`;
														return tooltipText;
													}}
													flyoutWidth={160}
													flyoutHeight={70}
												/>
											}
										/>
									);
								})}
							</VictoryStack>
						</VictoryChart>
						<Legend>
							{chartData.map(([spaceType], i) => (
								<LegendItem key={i}>
									<LegendColor color={PORTFOLIO_CHART_COLORS[i]} />
									<LegendLabel>{spaceType}</LegendLabel>
								</LegendItem>
							))}
						</Legend>
					</ChartAndLegendContainer>
				);
			}}
		</ChartBox>
	);
};

const filterValidYears = (
	leaseExpiration: PortfolioLeaseExpirationsBySpaceTypesByYears
) => {
	const currentYear = new Date().getFullYear();
	const validYears = Array.from(Array(NUMBER_OF_YEARS).keys()).map(
		(n) => currentYear + n
	);
	return validYears.includes(leaseExpiration.expirationYear);
};

const CHART_TYPE_TO_KEY: {
	[key in ChartTypeLease]: ChartKey;
} = {
	leaseAmount: 'expiringLeaseAmount',
	leaseCount: 'expiringLeaseCount',
	sqft: 'totalSqFt',
};

type ChartKey = keyof Pick<
	PortfolioLeaseExpirationsBySpaceTypesByYears,
	'expiringLeaseAmount' | 'expiringLeaseCount' | 'totalSqFt'
>;

type BarDataAccumulator = {
	[year: number]: {
		expirationYear: number;
		yValue: number;
		spaceTypeName: SpaceTypeName;
	};
};
