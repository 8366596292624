import styled from 'styled-components';
import IconTick from '../../../ui/svg_icons/tick_circle_filled.svg';
import { resetFileUpload } from 'Pages/Upload/actions';
import { useAppDispatch } from 'util/useAppDispatch';
import { useModal } from '../../../providers/ModalProvider';
import {
	ModalTitle,
	ModalButton,
	ModalParagraph,
	ModalCenteredContent,
} from '../common/UI';
type Props = {
	compsAwarded: number;
};

export const UploadSuccessModal = (props: Props) => {
	const dispatch = useAppDispatch();
	const { closeModal } = useModal();

	const confirmReview = () => {
		closeModal();
		dispatch(resetFileUpload());
	};

	return (
		<UploadSuccessModalInner>
			<StyledTickIcon />
			<ModalTitle>Thank you</ModalTitle>
			<ModalParagraph>
				You will receive your credits once we review your submission.
			</ModalParagraph>
			{props.compsAwarded !== 0 ? (
				<Pill>enjoy the free comp until then</Pill>
			) : (
				false
			)}
			<UploadSuccessModalButton
				variant={'success'}
				onClick={() => {
					confirmReview();
				}}
			>
				OK REVIEW MY COMPS
			</UploadSuccessModalButton>
			<CompProcessingMessage>
				This might take up to 3 days
			</CompProcessingMessage>
		</UploadSuccessModalInner>
	);
};

const StyledTickIcon = styled(IconTick)`
	width: 45px;
	height: 45px;
	fill: ${({ theme }) => theme.colors.green.green500};
	text-align: center;
	margin: 0 auto;
`;

export function useShowUploadSuccessModal() {
	const { openModal } = useModal();
	return ({ compsAwarded }: { compsAwarded: number }) => {
		openModal({
			modalContent: <UploadSuccessModal compsAwarded={compsAwarded} />,
		});
	};
}

export type ShowUploadSuccessModal = ReturnType<
	typeof useShowUploadSuccessModal
>;

const UploadSuccessModalButton = styled(ModalButton)`
	margin-top: 1rem;
`;

const Pill = styled.div`
	font-weight: 400;
	text-transform: uppercase;
	font-size: 0.875rem;
	color: #636b80;
	letter-spacing: 0.69px;
	padding: 0.25rem 0.75rem;
	border: 1px solid #50b87f;
	border-radius: 2rem;
`;

const UploadSuccessModalInner = styled(ModalCenteredContent)`
	text-align: center;
`;

const CompProcessingMessage = styled(ModalParagraph)`
	margin-top: 1.25rem;
	font-size: 0.875rem;
	color: ${({ theme }) => theme.colors.gray.n70};
`;
