import { ChartBox } from 'PortfolioAnalytics/Charts/ChartSelect';
import { ChartLegendV2 as Legend } from '../ChartLegend';
import { portfolioNameById } from '../../utils';

import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryGroup,
	VictoryTooltip,
	VictoryVoronoiContainer,
} from 'victory';

import { BENCHMARKING_COLORS } from '../../constants';

import { useChartHistogramQueries } from 'api';
import { usePortfoliosByIdsQueriesV2 } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueriesV2';
import { useMemo } from 'react';

import { LeaseExpirationTooltip } from './LeaseExpirationTooltip';
import { ChartOption } from 'PortfolioAnalytics/Charts/ChartSelect';

import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { BenchmarkingChartStateWrapper } from '../BenchmarkingChartStatusWrapper';

export const CHART_NAME = 'Lease Expiration by Year';

export const BenchmarkingLeaseExpirationChartV2 = () => {
	return (
		<ChartBox chartName={CHART_NAME}>
			{(selectedChart) => (
				<BenchmarkingLeaseExpirationChartV2Content
					selectedChart={selectedChart}
				/>
			)}
		</ChartBox>
	);
};

const BenchmarkingLeaseExpirationChartV2Content = ({
	selectedChart,
}: {
	selectedChart: ChartOption<'lease'>;
}) => {
	const { selectedPortfolioIds } = usePortfoliosSelection();
	const { filters } = usePortfolioFilters();

	const histogramResults = useChartHistogramQueries(selectedPortfolioIds, {
		filters,
		chartGrouping: 'tenantIndustry',
		chartLimit: 5,
		chartMetric:
			selectedChart.type === 'sqft' ? 'transactionSize' : selectedChart.type,
	});

	const histogramResultsWithColors = useMemo(() => {
		return histogramResults
			.map((result, index) => ({
				...result,
				color: BENCHMARKING_COLORS[index],
			}))
			.reverse()
			.filter(
				(result) => result.data?.values && result.data?.values.length > 0
			);
	}, [histogramResults]);

	const isFetchingHistogram = histogramResults.some(
		(result) => result.isFetching
	);

	const isErrorHistogram = histogramResults.some((result) => result.isError);

	const portfoliosResults = usePortfoliosByIdsQueriesV2(
		selectedPortfolioIds.map((id) => ({ id, filters }))
	);

	const isFetchingPortfolios = portfoliosResults.some(
		(result) => result.isFetching
	);

	const isErrorPortfolios = portfoliosResults.some((result) => result.isError);

	const isFetching = isFetchingHistogram || isFetchingPortfolios;
	const isError = isErrorHistogram || isErrorPortfolios;

	const isLeaseExpirationsEmpty = useMemo(
		() =>
			selectedPortfolioIds.length > 0 &&
			histogramResults.every((result) => result?.data?.values?.length === 0),
		[histogramResults, selectedPortfolioIds]
	);

	const noData =
		selectedPortfolioIds.length === 0 ||
		histogramResults.every((result) => result?.data?.values?.length === 0);

	const legendData = useMemo(() => {
		return selectedPortfolioIds.map((_portfolioId, index) => {
			const portfolioData = histogramResults[index];
			return {
				hasData: Boolean(
					portfolioData?.data?.values && portfolioData.data.values.length > 0
				),
				name: portfolioNameById(portfoliosResults, index),
			};
		});
	}, [histogramResults, selectedPortfolioIds, portfoliosResults]);

	return (
		<BenchmarkingChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={!isLeaseExpirationsEmpty}
		>
			<VictoryChart
				domainPadding={20}
				containerComponent={
					<VictoryVoronoiContainer
						voronoiDimension="x"
						labels={() => ' '}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={
									<LeaseExpirationTooltip
										width={128}
										height={20}
										selectedChartType={selectedChart.type}
									/>
								}
							/>
						}
					/>
				}
			>
				<VictoryAxis
					style={{
						tickLabels: { fontSize: 12 },
						axis: { stroke: 'transparent' },
					}}
					// For some reason, if tickValues are not converted to strings, the values will be overlapping
					tickValues={new Array(5)
						.fill(new Date())
						.map((date: Date, i) => (date.getFullYear() + i).toString())}
				/>
				<VictoryGroup offset={6} horizontal>
					{histogramResultsWithColors.map((result, idx) => {
						const firstFiveYears = result.data?.values
							.filter((_, i) => i < 5)
							.map((value, i) => ({
								x: i + 1, // x is the year
								y: value.total,
							}));

						return (
							<VictoryBar
								barWidth={6}
								key={idx}
								data={firstFiveYears}
								style={{ data: { fill: result.color } }}
							/>
						);
					})}
				</VictoryGroup>
			</VictoryChart>
			<Legend portfoliosLegendData={legendData} colors={BENCHMARKING_COLORS} />
		</BenchmarkingChartStateWrapper>
	);
};
