import { nullValueText } from '@compstak/common';
import { ExportButton } from 'Components';
import ValueOrLockIcon from 'Components/AttributeValues/ValueOrLock/Icon';
import UnlockOrExportButton from 'Components/UnlockOrExportButton';
import { getFiltersMarkets } from 'models/filters/util/getFiltersMarkets';
import { filtersFromServerJSON } from 'models/filters/util/serverJson';
import { useMarkets } from 'hooks/useMarkets';
import React, { useEffect } from 'react';
import Select from 'react-select';
import { useExchangeDashboardState } from 'reducers/exchangeDashboard';
import { useFilters } from 'reducers/filtersReducer';
import { SavedSearch, useSavedSearches } from 'reducers/savedSearches';
import { useUnlocked } from 'reducers/unlock';
import { Link } from 'router';
import styled from 'styled-components';
import { LeaseComp } from 'types/comp';
import { formatComp } from 'util/comp-format/src/format';
import ArrowBottom from '../ui/svg_icons/arrow_bottom.svg';
import ArrowTop from '../ui/svg_icons/arrow_top.svg';
import { useExchangeDashboardLeasesActivity } from './hooks/leasesActivity/useExchangeDashboardLeasesActivity';
import {
	ButtonWrap,
	ExchangePanel,
	Table,
	TableData,
	TableHead,
	TableRow,
	TableTab,
	TableTabs,
	TableTop,
	ViewButton,
} from './UI';

export type ActivityTypeLease =
	| 'executionDate'
	| 'lastAttributeUpdate'
	| 'dateCreated'
	| 'transactionSize'
	| 'viewsInLast6Months'
	| 'recencyDate';

type TabOption = { label: string; type: ActivityTypeLease; tooltip: string };
const currentApp = window?.appConfig?.isExchange ? 'Exchange' : 'Enterprise';
const leaseTabOptions: TabOption[] = [
	{
		label: 'Recently Signed',
		type: 'executionDate',
		tooltip:
			'This tab contains recently signed deals for your selected filters.  It is displayed in reverse chronological order.',
	},
	{
		label: 'Recently Updated',
		type: 'lastAttributeUpdate',
		tooltip:
			'This tab contains existing deals that have been recently updated.  It is based on your selected filters and is displayed in reverse chronological order.',
	},
	{
		label: 'New On Compstak',
		type: 'dateCreated',
		tooltip: `This tab contains newly added comps based on your selected filters.  It may include historical comps that are newly available on CompStak ${currentApp} and is displayed in reverse chronological order.`,
	},
	{
		label: 'Large Leases',
		type: 'transactionSize',
		tooltip:
			'This tab contains the largest deals based on square footage that have closed in the last 12 months.  It is based on your selected filters.',
	},
	{
		label: 'Most Viewed',
		type: 'viewsInLast6Months',
		tooltip: `This tab contains the most viewed leases on CompStak ${currentApp} over the last six months and based on your selected filters.`,
	},
];

export const LeaseActivityTable: React.FC = () => {
	const [activeTab, setActiveTab] =
		React.useState<ActivityTypeLease>('executionDate');
	const [filters] = useFilters('exchange-dashboard');
	const [limit, setLimit] = React.useState<10 | 50>(10);

	const [selectedSavedSearch, setSelectedSavedSearch] = React.useState<{
		label: string;
		value: SavedSearch;
	} | null>(null);

	const [selectedLeaseIds, setSelectedLeaseIds] = React.useState<number[]>([]);
	const savedSearches = useSavedSearches();
	const unlocked = useUnlocked();

	const { fullPageState } = useExchangeDashboardState();
	const { data: lease, isFetching } = useExchangeDashboardLeasesActivity({
		activeTab,
		selectedSavedSearch,
		enabled: fullPageState === null,
	});

	const leaseComps = lease?.comps.map((lc) =>
		formatComp(lc as unknown as LeaseComp, 'lease')
	);

	const leaseCompsIds = lease?.comps.map((comp) => comp.id);

	const filteredAndMappedLeasesComps = React.useMemo(() => {
		return leaseComps
			?.slice(0, limit)
			.filter((s) => unlocked.unlockedComps[s.id] ?? s)
			.map((comp, index) => (
				<LeaseTableRow
					key={comp.id}
					index={index}
					comp={comp}
					setSelectedLeaseIds={setSelectedLeaseIds}
					selectedLeaseIds={selectedLeaseIds}
					displayExportButton={true}
					leaseCompsIds={leaseCompsIds}
					limit={limit}
				/>
			));
	}, [leaseComps, limit, selectedLeaseIds, unlocked.unlockedComps]);

	const emptyArray = Array.from(Array(limit).keys());

	const markets = useMarkets();

	const savedSearchOptions =
		savedSearches.savedSearchList
			?.filter((savedSearch) => {
				if (savedSearch.searchType === 'leases') {
					const savedSearchFilters = filtersFromServerJSON(
						'lease',
						markets,
						savedSearch.filters
					);
					const savedSearchMarkets = getFiltersMarkets(savedSearchFilters);

					return (
						savedSearchMarkets.length === 1 &&
						savedSearchMarkets[0].id === filters.market.id
					);
				}
				return false;
			})
			.map((savedSearch) => ({
				label: savedSearch.name,
				value: savedSearch,
			})) ?? [];

	const allChecked = React.useMemo(
		() =>
			Boolean(
				leaseComps
					?.slice(0, limit)
					?.every(({ id }) => selectedLeaseIds.includes(id))
			),
		[leaseComps, limit, selectedLeaseIds]
	);

	const sectionRef = React.useRef<HTMLElement | null>(null);
	const isMultiSelectActive =
		selectedLeaseIds.length > 0 && selectedLeaseIds.length < limit;

	useEffect(() => {
		if (selectedSavedSearch) {
			setSelectedSavedSearch(null);
		}
	}, [filters.market]);

	return (
		// @ts-expect-error TS2322: Type '{ children: Element[]; r...
		<ExchangePanel ref={sectionRef}>
			<TableTabs>
				{leaseTabOptions.map((option) => (
					<TableTab
						active={option.type === activeTab}
						key={option.type}
						onClick={() => {
							setActiveTab(option.type);
							setSelectedLeaseIds([]);
						}}
						data-tooltip={option.tooltip}
						data-tooltip-delay={500}
						data-qa-id={`lease-activity-${option.type}-tab`}
					>
						<span>{option.label}</span>
					</TableTab>
				))}
			</TableTabs>
			<TableTop>
				<ExportButton
					compType="lease"
					variant="default"
					selection={selectedLeaseIds
						.map((id) => leaseComps?.find((comp) => comp.id === id) ?? null)
						.filter(Boolean)}
				/>
				<SelectContainer>
					<Select
						onChange={(e) =>
							e?.value === null
								? setSelectedSavedSearch(null)
								: // @ts-expect-error TS2345: Argument of type 'ValueType<{ ...
									setSelectedSavedSearch(e)
						}
						data-qa-id="lease-saved-search-select"
						width="300px"
						placeholder="View by Saved Search"
						value={selectedSavedSearch}
						options={savedSearchOptions}
						isClearable={true}
						isSearchable={false}
					/>
				</SelectContainer>
			</TableTop>
			<Table>
				<colgroup>
					<col style={tableWidth} />
				</colgroup>
				<LeaseTableHeader
					checked={allChecked}
					isMultiSelectActive={isMultiSelectActive}
					selectAllIds={() =>
						allChecked
							? setSelectedLeaseIds([])
							: setSelectedLeaseIds(
									leaseComps?.slice(0, limit)?.map((c) => c.id) ?? []
								)
					}
				/>
				<tbody>
					{isFetching &&
						!filteredAndMappedLeasesComps &&
						emptyArray.map((row, index) => (
							<LeaseTableRow
								key={row}
								index={index}
								setSelectedLeaseIds={setSelectedLeaseIds}
								selectedLeaseIds={selectedLeaseIds}
								displayExportButton={true}
							/>
						))}

					{leaseComps && filteredAndMappedLeasesComps}
				</tbody>
			</Table>
			<ButtonWrap>
				{limit === 10 && leaseComps && leaseComps.length > 10 && (
					<ViewButton
						onClick={() => setLimit(50)}
						data-qa-id="lease-view-more-button"
					>
						View Top 50 <ArrowBottom width={8} />
					</ViewButton>
				)}
				{limit === 50 && (
					<ViewButton
						onClick={() => {
							sectionRef.current?.scrollIntoView({
								block: 'start',
							});
							setLimit(10);
						}}
						data-qa-id="lease-view-less-button"
					>
						View Less <ArrowTop width={8} />
					</ViewButton>
				)}
			</ButtonWrap>
		</ExchangePanel>
	);
};

type LeaseTableHeaderProps = {
	selectAllIds: () => void;
	checked: boolean;
	isMultiSelectActive: boolean;
};

const LeaseTableHeader = React.memo<LeaseTableHeaderProps>(
	({ selectAllIds, checked, isMultiSelectActive }) => {
		const leaseTableHeaderId = 'lease-activity-select-all-comps';
		return (
			<thead>
				<tr>
					<TableHead>
						<input
							type="checkbox"
							id={leaseTableHeaderId}
							data-qa-id={leaseTableHeaderId}
							className={`checkbox ${
								isMultiSelectActive ? 'tableview-indeterminate-checkbox' : ''
							}`}
							onChange={() => selectAllIds()}
							checked={checked}
						/>
						<label
							htmlFor="lease-activity-select-all-comps"
							className="checkbox"
						/>
					</TableHead>
					<TableHead>Tenant</TableHead>
					<TableHead>Address</TableHead>
					<TableHead>City</TableHead>
					<TableHead>Submarket</TableHead>
					<TableHead>SqFt</TableHead>
					<TableHead>Net Effective Rent</TableHead>
					<TableHead>Term</TableHead>
				</tr>
			</thead>
		);
	}
);
LeaseTableHeader.displayName = 'LeaseTableHeader';

type LeaseTableRowProps = {
	index: number;
	comp?: LeaseComp;
	displayExportButton: boolean;
	selectedLeaseIds: number[];
	setSelectedLeaseIds: React.Dispatch<React.SetStateAction<number[]>>;
	leaseCompsIds?: number[];
	limit?: 10 | 50;
};

const LeaseTableRow: React.FC<LeaseTableRowProps> = React.memo(
	(props) => {
		const { comp, index, leaseCompsIds, limit } = props;

		const prevCompId = leaseCompsIds?.[index - 1];
		const nextCompId = leaseCompsIds?.[index + 1];

		const compDetailsPath = {
			pathname: `/comps/leases/${comp?.id}`,
			state: {
				index,
				numberOfComps: limit,
				fromMarketDashboard: true,
				prevCompId,
				nextCompId,
				leaseCompsIds,
			},
		};

		return (
			<TableRow>
				{props.displayExportButton && (
					<TableData className="td-container">
						<ExportContainer
							comp={comp}
							setSelectedLeaseIds={props.setSelectedLeaseIds}
							selectedLeaseIds={props.selectedLeaseIds}
						/>
					</TableData>
				)}
				<TableData
					// @ts-expect-error TS18048: 'comp.tenantName.length' is po...
					data-tooltip={comp?.tenantName?.length > 50 ? comp?.tenantName : null}
				>
					<Link
						className="td-container"
						to={compDetailsPath.pathname}
						state={compDetailsPath.state}
					>
						{/* @ts-expect-error TS18048: 'comp.tenantName.length' is po... */}
						{(comp?.tenantName?.length > 50
							? comp?.tenantName?.slice(0, 47).concat('...')
							: comp?.tenantName) ?? nullValueText}
					</Link>
				</TableData>
				<TableData>
					<Link
						className="td-container"
						to={compDetailsPath.pathname}
						state={compDetailsPath.state}
					>
						{comp?.buildingAddress ?? nullValueText}
					</Link>
				</TableData>
				<TableData>
					<Link
						className="td-container"
						to={compDetailsPath.pathname}
						state={compDetailsPath.state}
					>
						{comp?.city ?? nullValueText}
					</Link>
				</TableData>
				<TableData>
					{' '}
					<StyledLink
						className="td-container"
						to={compDetailsPath.pathname}
						state={compDetailsPath.state}
					>
						{comp && (
							<ValueOrLockIcon
								comp={comp}
								compType="lease"
								// @ts-expect-error TS2739: Type '{ name: string; }' is mi...
								field={{ name: 'submarket' }}
							/>
						)}
					</StyledLink>
				</TableData>
				<TableData>
					<StyledLink
						className="td-container"
						to={compDetailsPath.pathname}
						state={compDetailsPath.state}
					>
						{comp && (
							<ValueOrLockIcon
								comp={comp}
								compType="lease"
								// @ts-expect-error TS2739: Type '{ name: string; }' is mi...
								field={{ name: 'transactionSize' }}
							/>
						)}
					</StyledLink>
				</TableData>
				<TableData>
					<StyledLink
						className="td-container"
						to={compDetailsPath.pathname}
						state={compDetailsPath.state}
					>
						{comp && (
							<ValueOrLockIcon
								comp={comp}
								compType="lease"
								// @ts-expect-error TS2739: Type '{ name: string; }' is mi...
								field={{ name: 'effectiveRent' }}
							/>
						)}
					</StyledLink>
				</TableData>
				<TableData>
					<StyledLink
						className="td-container"
						to={compDetailsPath.pathname}
						state={compDetailsPath.state}
					>
						{comp && (
							<ValueOrLockIcon
								comp={comp}
								compType="lease"
								// @ts-expect-error TS2739: Type '{ name: string; }' is mi...
								field={{ name: 'leaseTerm' }}
							/>
						)}
					</StyledLink>
				</TableData>
			</TableRow>
		);
	},
	(prevProps, nextProps) =>
		prevProps?.comp?.id === nextProps?.comp?.id &&
		prevProps?.comp?.own === nextProps?.comp?.own &&
		prevProps.selectedLeaseIds.length === nextProps.selectedLeaseIds.length
);
LeaseTableRow.displayName = 'LeaseTableRow';

const ExportContainer = (props: {
	comp?: LeaseComp;
	setSelectedLeaseIds: React.Dispatch<React.SetStateAction<number[]>>;
	selectedLeaseIds: number[];
}) => {
	if (props.comp) {
		return (
			<Wrap>
				<InputContainer>
					<input
						type="checkbox"
						className="checkbox"
						id={'comp-' + props.comp.id}
						data-comp-id={props.comp.id}
						data-qa-id={`select-lease-input`}
						onChange={() =>
							props.setSelectedLeaseIds((prevLeaseIds) => {
								// @ts-expect-error TS18048: 'props.comp' is possibly 'unde...
								if (prevLeaseIds.includes(props.comp.id)) {
									// @ts-expect-error TS18048: 'props.comp' is possibly 'unde...
									return prevLeaseIds.filter((id) => id !== props.comp.id);
								} else {
									// @ts-expect-error TS18048: 'props.comp' is possibly 'unde...
									return prevLeaseIds.concat([props.comp.id]);
								}
							})
						}
						checked={props.selectedLeaseIds.includes(props.comp.id)}
					/>
					<label htmlFor={'comp-' + props.comp.id} className="checkbox" />
				</InputContainer>
				<UnlockOrExportButton
					comp={props.comp}
					compType="lease"
					exportText=""
					height={40}
					width={90}
					configuration={'table'}
				/>
			</Wrap>
		);
	} else {
		const loadingCheckboxId = 'loading-lease-checkbox';

		return (
			<div>
				<input
					id={loadingCheckboxId}
					data-qa-id={loadingCheckboxId}
					type="checkbox"
					className="checkbox"
					checked={false}
					onChange={() => {}}
				/>
				<label className="checkbox" htmlFor={loadingCheckboxId} />
				<div className="button_button button_disabled loading" />
			</div>
		);
	}
};

const tableWidth = { width: '100px' };

const SelectContainer = styled.div`
	position: relative;
	width: 300px;
`;

const Wrap = styled.div`
	align-items: center;
	display: flex;
`;

const InputContainer = styled.div`
	width: 26px;
`;

const StyledLink = styled(Link)`
	svg {
		margin-left: 10px;
	}
`;
