import { Spinner } from '@compstak/ui-kit';
import { useAppConfig } from 'Pages/Login/reducers';
import { usePropertyByIdQuery, usePropertyLeasesQuery } from 'api';
import { CompPopupHeader } from './CompPopupHeader';
import { GenerateCompSetButton } from './GenerateCompSetButton';
import {
	CompPopupActionsV2,
	CompPopupRoot,
	CompPopupRootLoading,
	PopupActionButton,
	StyledArrowRightIcon,
} from './UI';
import { useFiltersWithAddressString } from './useFiltersWithAddressString';
import { useShouldShowPortfolioButton } from './useShouldShowPortfolioButton';

import { NearbyCompsButton } from './NearbyCompsButton';
import { MapPopupAddToPortfolioButton } from './MapPopupAddToPortfolioButton';

import IconFormatListBulleted from '../../../ui/svg_icons/format_list_bulleted.svg';
import { PropertyPageLink } from './PropertyPageLink';

type Props = {
	propertyId: number;
	closePopup: () => void;
};

export const LeasePopup = ({ propertyId, closePopup }: Props) => {
	const { data: property } = usePropertyByIdQuery({
		id: propertyId,
	});

	const { data: propertyLeases } = usePropertyLeasesQuery({
		propertyId: property?.id,
	});

	const comps = propertyLeases?.comps;

	const appConfig = useAppConfig();

	const filtersWithAddressString = useFiltersWithAddressString({
		property,
	});

	const viewRefineLeasesUrl = `/search/leases/list?${filtersWithAddressString}`;

	const showPortfolioButton = useShouldShowPortfolioButton();

	if (!property || !comps)
		return (
			<CompPopupRootLoading>
				<Spinner size="l" />
			</CompPopupRootLoading>
		);

	return (
		<CompPopupRoot>
			<CompPopupHeader property={property} closePopup={closePopup} />
			<CompPopupActionsV2>
				<PopupActionButton
					href={viewRefineLeasesUrl}
					onClick={closePopup}
					icon={<IconFormatListBulleted />}
				>
					View & Refine Leases ({comps.length})
					<StyledArrowRightIcon />
				</PopupActionButton>

				<PropertyPageLink propertyId={property.id}>
					Detailed Property Info
				</PropertyPageLink>

				<NearbyCompsButton
					property={property}
					closePopup={closePopup}
					compType="lease"
					data-qa-id="nearby-lease-comps-btn"
				>
					Nearby Lease Comps
				</NearbyCompsButton>
				<GenerateCompSetButton property={property} closePopup={closePopup} />
				{showPortfolioButton && (
					<MapPopupAddToPortfolioButton
						variant="secondary"
						propertyIds={[property.id]}
					/>
				)}

				{appConfig.isExchange && (
					<PopupActionButton
						href={`/stacking-plan/${property.id}`}
						data-qa-id="stacking-plan-btn"
						style={{ justifyContent: 'center' }}
					>
						Stacking Plan Builder
					</PopupActionButton>
				)}
			</CompPopupActionsV2>
		</CompPopupRoot>
	);
};
