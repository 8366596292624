import * as Tabs from '@radix-ui/react-tabs';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { DashboardHeaderV2 } from './DashboardHeader/DashboardHeaderV2';
import { LeaseActivityTab } from 'PortfolioAnalytics/Tabs/LeaseActivityTab';
import { PortfolioLeasesProvider } from 'PortfolioAnalytics/LeaseActivity/PortfolioLeasesProvider';
import { PropertiesTab } from 'PortfolioAnalytics/Tabs/PropertiesTab';
import { PortfolioPropertiesProvider } from 'PortfolioAnalytics/Properties/PortfolioPropertiesProvider';
import { routes, useNavigate } from 'router';
import { EmptyPortfolioV2 } from 'PortfolioAnalytics/EmptyPortfolioV2';
import { PortfolioV2 } from 'api';
import { isPortfolioEmpty } from 'PortfolioAnalytics/utils/isPortfolioEmpty';
import { OverviewTab } from 'PortfolioAnalytics/Tabs/OverviewTab';
import { PORTFOLIO_PROPERTIES_COLUMNS } from 'PortfolioAnalytics/Properties/usePortfolioPropertiesColumns';
import { PropertyComp } from 'types';

type Props = {
	portfolio: PortfolioV2;
};

export enum PortfolioTabEnum {
	OVERVIEW = 'overview',
	LEASE_ACTIVITY = 'leaseActivity',
	PROPERTIES = 'properties',
}

export const Dashboard = ({ portfolio }: Props) => {
	const portfolioId = portfolio.portfolio.id;

	const [portfolioSearchParams] = routes.portfolioByIdView.useSearchParams();

	const [tab, _setTab] = useState<PortfolioTabEnum>(
		portfolioSearchParams.tab ?? PortfolioTabEnum.OVERVIEW
	);

	const setTab = (tab: PortfolioTabEnum) => {
		_setTab(tab);

		navigate(
			routes.portfolioByIdView.toHref(
				{
					portfolioId,
					viewType: 'dashboard',
				},
				{ tab }
			)
		);
	};

	const [contentEl, setContentEl] = useState<HTMLDivElement | null>(null);

	const navigate = useNavigate();

	useEffect(() => {
		if (!Object.values(PortfolioTabEnum).includes(tab)) {
			setTab(PortfolioTabEnum.OVERVIEW);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Root>
			<Content ref={setContentEl}>
				<Tabs.Root
					value={tab}
					onValueChange={(value) => {
						setTab(value as PortfolioTabEnum);
					}}
				>
					<DashboardHeaderV2
						portfolio={portfolio}
						dashboardElement={contentEl}
						tab={tab as PortfolioTabEnum}
					/>
					{isPortfolioEmpty(portfolio) ? (
						<EmptyPortfolioV2 />
					) : (
						<TabContentContainer>
							<Tabs.Content value={PortfolioTabEnum.OVERVIEW}>
								<OverviewTab portfolioId={portfolioId} />
							</Tabs.Content>
							<Tabs.Content value={PortfolioTabEnum.LEASE_ACTIVITY}>
								<PortfolioLeasesProvider>
									<LeaseActivityTab />
								</PortfolioLeasesProvider>
							</Tabs.Content>
							<Tabs.Content value={PortfolioTabEnum.PROPERTIES}>
								<PortfolioPropertiesProvider
									portfolio={portfolio}
									includeFields={PORTFOLIO_PROPERTIES_COLUMNS.map(
										({ id }) => id as keyof PropertyComp
									)}
								>
									<PropertiesTab />
								</PortfolioPropertiesProvider>
							</Tabs.Content>
						</TabContentContainer>
					)}
				</Tabs.Root>
			</Content>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	height: 100%;
`;

const Content = styled.div`
	height: 100%;
	flex: 1;
	overflow: auto;
`;

const TabContentContainer = styled.div`
	padding: 1.5rem;
	background-color: ${(p) => p.theme.colors.neutral.n20};
`;
