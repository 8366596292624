import {
	PageTitleWrap,
	PageTitle,
	PageTitleRow,
	PageTitleRowLeft,
	PageTitleRowRight,
	PageTitleRowRightButtons,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import styled from 'styled-components';
import { PortfolioFiltersV2 } from 'PortfolioAnalytics/Dashboard/DashboardHeader/Filters/PortfolioFiltersV2';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { usePortfoliosByIdsQueriesV2 } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueriesV2';
import { isPortfolioEmpty } from 'PortfolioAnalytics/utils/isPortfolioEmpty';
import { useMemo } from 'react';

import { BenchmarkingDropdown } from './BenchmarkingDropdown';

type Props = {
	elementToExport: HTMLElement | null;
};

export const BenchmarkingViewHeader = ({ elementToExport }: Props) => {
	const { selectedPortfolioIds } = usePortfoliosSelection();
	const selectedPortfoliosResults = usePortfoliosByIdsQueriesV2(
		selectedPortfolioIds.map((id) => ({ id }))
	);

	const filtersDisabeld = useMemo(
		() =>
			selectedPortfoliosResults
				.map(({ data: portfolio }) => portfolio)
				.every((data) => isPortfolioEmpty(data)),
		[selectedPortfoliosResults]
	);

	return (
		<Container>
			<PageTitleRow>
				<PageTitleRowLeft>
					<PageTitleWrap>
						<PageTitle>Portfolio Comparison</PageTitle>
					</PageTitleWrap>
					<PortfolioFiltersV2 disabled={filtersDisabeld} />
				</PageTitleRowLeft>
				<PageTitleRowRight>
					<PageTitleRowRightButtons>
						<BenchmarkingDropdown elementToExport={elementToExport} />
					</PageTitleRowRightButtons>
				</PageTitleRowRight>
			</PageTitleRow>
		</Container>
	);
};

const Container = styled.div``;
