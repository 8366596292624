import { PortfolioV2 } from 'api';
import { TITLES, TOOLTIP_TEXT, getFormattedValueForField } from './AveragesV2';
import { StatKey } from './PortfolioStats';
import styled from 'styled-components';
import { PortfolioHelpIcon } from 'PortfolioAnalytics/Singletons/PortfolioHelpIcon';

export const PortfolioStatItem = ({
	statKey,
	portfolio,
}: {
	statKey: StatKey;
	portfolio: PortfolioV2;
}) => {
	const title = TITLES[statKey];
	const formattedValue = getFormattedValueForField(
		statKey,
		portfolio.metrics[statKey]
	);
	const tooltip = TOOLTIP_TEXT[statKey];

	return (
		<Card>
			<CardTitle>
				{title}
				{tooltip && <PortfolioHelpIcon tooltip={tooltip} />}
			</CardTitle>
			<CardValue>{formattedValue}</CardValue>
		</Card>
	);
};

const Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 0.5em;
`;

const CardTitle = styled.div`
	display: flex;
	gap: 0.5em;
	font-size: 0.875em;
	color: ${({ theme }) => theme.colors.neutral.n100};
`;

const CardValue = styled.div`
	font-size: 1em;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.gray.gray700};
`;
