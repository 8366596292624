import { BenchmarkingTrendChart } from './Trend/BenchmarkingTrendChart';
import { BenchmarkingPerformanceDistributionChart } from './PerformanceDistribution/BenchmarkingPerformanceDistributionChart';
import { ChartsContainerV2 } from 'PortfolioAnalytics/styles/PortfolioUI';
import { BenchmarkingLeaseExpirationChartV1 } from './LeaseExpiration/BenchmarkingLeaseExpirationChartV1';
import { BenchmarkingTenantIndustryChart } from './TenantIndustry/BenchmarkingTenantIndustryChart';

export const BenchmarkingCharts = () => {
	return (
		<ChartsContainerV2 narrow={true}>
			<BenchmarkingPerformanceDistributionChart />
			<BenchmarkingTrendChart />
			<BenchmarkingTenantIndustryChart />
			<BenchmarkingLeaseExpirationChartV1 />
		</ChartsContainerV2>
	);
};
