import constate from 'constate';
import { LeaseComp } from 'types';
import { useSelectionState } from 'utils/useSelectionState';
import { getFiltersFromPortfolioLeasesFilters } from './utils';
import { useCallback, useEffect, useMemo } from 'react';
import {
	SEARCH_MAX_LIMIT,
	SearchLeasesResponse,
	useSearchFilterParams,
	useSearchLeasesInfiniteQuery,
	useSearchLeasesQuery,
} from 'api';
import { useSearchTableRows } from 'Pages/Search/useSearchTableRows';
import { usePortfolioLeasesFilters } from './PortfolioLeasesFiltersProvider';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { getFiltersFromPortfolioFilters } from 'PortfolioAnalytics/utils/getFiltersFromPortfolioFilters';
import { PORTFOLIO_LEASES_COLUMNS } from './usePortfolioLeasesColumns';
import { FiltersObject } from 'models/filters/types';

export const [PortfolioLeasesProvider, usePortfolioLeases] = constate(() => {
	const { filters: portfolioFilters } = usePortfolioFilters();
	const { portfolio, filters: portfolioLeasesFilters } =
		usePortfolioLeasesFilters();

	const portfolioFiltersData = useMemo(
		() =>
			getFiltersFromPortfolioFilters({
				...portfolioFilters,
				portfolio,
			}),
		[portfolioFilters, portfolio]
	);

	const portfolioWithLeaseFilters = useMemo(
		() =>
			getFiltersFromPortfolioLeasesFilters({
				...portfolioLeasesFilters,
				...portfolioFiltersData,
				portfolio,
				leaseExpirationsMonths: portfolioFilters.leaseExpirationsMonths,
			}),
		[portfolioFilters, portfolioFiltersData, portfolioLeasesFilters, portfolio]
	);

	const filters = useMemo(() => {
		return portfolioWithLeaseFilters as FiltersObject;
	}, [portfolioWithLeaseFilters]);
	const filterParams = useSearchFilterParams({ filters });

	const infiniteLeasesQuery = useSearchLeasesInfiniteQuery({
		...filterParams,
		properties: PORTFOLIO_LEASES_COLUMNS.map(({ id }) => id as keyof LeaseComp),
	});
	const noLimitLeasesQuery = useSearchLeasesQuery(
		{
			...filterParams,
			offset: 0,
			limit: SEARCH_MAX_LIMIT,
			properties: ['id', 'marketId'],
		},
		{ enabled: false }
	);

	const infiniteRows = useSearchTableRows<LeaseComp>({
		data: infiniteLeasesQuery.data,
		getRows: useCallback((page: SearchLeasesResponse) => page.comps, []),
		isFetching: infiniteLeasesQuery.isFetchingNextPage,
	});
	const rowsForSelection = noLimitLeasesQuery.data?.comps ?? infiniteRows;

	const _selectionState = useSelectionState(rowsForSelection);
	const selectionState = {
		..._selectionState,
		toggleAllSelected: () => {
			// fetch all leases if we haven't yet and there is more to load
			if (!noLimitLeasesQuery.data && infiniteLeasesQuery.hasNextPage) {
				noLimitLeasesQuery.refetch();
			} else {
				_selectionState.toggleAllSelected();
			}
		},
	};

	useEffect(() => {
		if (noLimitLeasesQuery.data?.comps) {
			selectionState.toggleAllSelected();
		}
	}, [noLimitLeasesQuery.data]);

	useEffect(() => {
		selectionState.resetSelection();
	}, [filters]);

	const isFetching =
		infiniteLeasesQuery.isFetching || noLimitLeasesQuery.isFetching;
	const isError = infiniteLeasesQuery.isError || noLimitLeasesQuery.isError;
	const isFetchingNextPage = infiniteLeasesQuery.isFetchingNextPage;
	const hasNextPage = infiniteLeasesQuery.hasNextPage;
	const fetchNextPage = infiniteLeasesQuery.fetchNextPage;

	return {
		data: infiniteRows,
		isFetchingNextPage,
		isError,
		isFetching,
		isFetchingAll: noLimitLeasesQuery.isFetching,
		hasNextPage,
		fetchNextPage,
		selectionState,
	};
});
