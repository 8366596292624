import {
	UseInfiniteQueryOptions,
	useInfiniteQuery,
} from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import { PortfolioPropertiesResponse } from './usePortfolioPropertiesQuery';
import { API } from '@compstak/common';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { PortfolioPropertiesParams } from './types';

const PORTFOLIO_PROPERTIES_INFINITE_PAGE_SIZE = 50;

export const usePortfolioPropertiesInfiniteQuery = (
	params: PortfolioPropertiesInfiniteQueryParams,
	options?: UseInfiniteQueryOptions<
		PortfolioPropertiesResponse,
		unknown,
		PortfolioPropertiesResponse
	>
) => {
	return useInfiniteQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_PROPERTIES_INFINITE, params],
		queryFn: async ({ pageParam = 0, signal }) => {
			const { portfolioId, filters, ..._otherParams } = params;

			const otherParams = {
				limit: PORTFOLIO_PROPERTIES_INFINITE_PAGE_SIZE,
				offset: pageParam * PORTFOLIO_PROPERTIES_INFINITE_PAGE_SIZE,
				..._otherParams,
			};

			const response = await API.get<PortfolioPropertiesResponse>(
				`/pa/v2/api/portfolios/${portfolioId}/properties${getQueryStringFromPortfolioFilters(filters, otherParams)}`,
				{ signal }
			);

			return response.data;
		},
		getNextPageParam: (lastPage, pages) => {
			const hasMore =
				lastPage.length === PORTFOLIO_PROPERTIES_INFINITE_PAGE_SIZE;
			if (!hasMore) return;
			return pages.length + 1;
		},
		...options,
	});
};

export type PortfolioPropertiesInfiniteQueryParams = Omit<
	PortfolioPropertiesParams,
	'offset' | 'limit'
>;
