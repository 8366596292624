import { PortfolioV2, PortfolioV2Metrics } from 'api/types/portfolio';
import { formatInteger, formatMoney } from 'format';
import { PortfolioHelpIcon } from 'PortfolioAnalytics/Singletons/PortfolioHelpIcon';
import { HelpIconContainer } from 'PortfolioAnalytics/UI';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { nullValueText } from '@compstak/common';
import abbreviateNumber from 'ui/util/abbreviateNumber';
import { MQB } from '@compstak/ui-kit';

type Props = {
	portfolioV2: PortfolioV2;
};

export const AveragesV2 = ({ portfolioV2 }: Props) => {
	const statsAndAveragesFields = useStatsAndAverages(portfolioV2);

	return (
		<CardsRow>
			{statsAndAveragesKeys.map((field) => {
				const fieldData = statsAndAveragesFields[field];
				return (
					<Card
						key={fieldData.title}
						columnSpanNarrow={fieldData.columnSpanNarrow}
						columnSpanMid={fieldData.columnSpanMid}
						columnSpanWide={fieldData.columnSpanWide}
						columnSpanTablet={fieldData.columnSpanTablet}
					>
						<CardTitle>
							{fieldData.title}
							<HelpIconContainer>
								<PortfolioHelpIcon tooltip={fieldData.toolTipText} />
							</HelpIconContainer>
						</CardTitle>
						<CardValue>{fieldData.formattedValue}</CardValue>
					</Card>
				);
			})}
		</CardsRow>
	);
};

const statsAndAveragesKeys = [
	'marketCount',
	'propertyCount',
	'activeLeaseCount',
	'totalSqFt',
	'expiringSqFt24Months',
	'avgTransactionalSqFt',
	'avgCurrentRent',
	'avgNetEffectiveRent',
	'avgFreeRent',
	'avgLeaseTerm',
	'avgTIImprovement',
] satisfies (keyof PortfolioV2Metrics)[];

type StatsAndAveragesKey = (typeof statsAndAveragesKeys)[number];

type StatsAndAveragesFieldsData = {
	[key in StatsAndAveragesKey]: {
		formattedValue: ReturnType<typeof getFormattedValueForField>;
		toolTipText: ReactNode | string;
		title: string;
		columnSpanNarrow: number;
		columnSpanMid: number;
		columnSpanWide: number;
		columnSpanTablet: number;
	};
};

export const TOOLTIP_TEXT: Record<StatsAndAveragesKey, ReactNode> = {
	marketCount: 'This is the number of markets in the portfolio.',
	propertyCount:
		'Number of Properties is the sum of properties in each market in the portfolio. If an expiration date filter is applied, the property count will only include the number of properties with active leases.',
	totalSqFt:
		'Active Leased SqFt is the total sum of SqFt of active (non-expired) leases in the portfolio across all markets. (Leases without expiration dates are excluded.)',
	activeLeaseCount:
		'Total Active Leases is the total number of active (non-expired) leases in the portfolio across all markets.  (Leases without expiration dates are excluded.)',
	expiringSqFt24Months: `24 Months Expiring SqFt is calculated as the sum of the square footage in active (non-expired) leases which have an expiration date in the next 24 months.`,
	avgTransactionalSqFt: `Average Transaction SqFt is calculated by adding the SqFt in each active (non-expired) lease and dividing the sum by the number of leases. (Leases without expiration dates are excluded.)`,
	avgCurrentRent: `Average Current Rent is the weighted average of current rent in  $/SqFt/YR in all active (non-expired) leases. (Leases without expiration dates are excluded. Weighted average uses lease SqFt as the weight.)`,
	avgNetEffectiveRent: `Average Net Effective Rent is the weighted average of the NER $/SqFt/YR in all active (non-expired) leases. (Leases without expiration dates are excluded. Weighted average uses lease SqFt as the weight.)`,
	avgFreeRent: `Average Free Rent is the weighted average of the number of months of free rent in all active (non-expired) leases. (Leases without expiration dates are excluded. Weighted average uses lease SqFt as the weight.)`,
	avgLeaseTerm: `Average Lease Term is the weighted average of lease terms (in months) of all active (non-expired) leases. (Leases without expiration dates are excluded. Weighted average uses lease SqFt as the weight.)`,
	avgTIImprovement: `Average Tenant Improvement Allowance is the weighted average of TI in $/SqFt/YR in all active (non-expired) leases. (Leases without expiration dates are excluded. Weighted average uses lease SqFt as the weight.)`,
};

export const TITLES: Record<StatsAndAveragesKey, string> = {
	marketCount: 'Markets',
	propertyCount: '# of Properties',
	totalSqFt: 'Active Leased SqFt',
	activeLeaseCount: 'Total Active Leases',
	expiringSqFt24Months: '24 Mo. Expiring SqFt',
	avgTransactionalSqFt: 'Avg. Transaction SqFt',
	avgCurrentRent: 'Avg. Current Rent',
	avgNetEffectiveRent: 'Avg. Net Effective Rent',
	avgFreeRent: 'Avg. Free Rent',
	avgLeaseTerm: 'Avg. Lease Term',
	avgTIImprovement: 'Avg. TI Allowance',
};

const COLUMN_SPAN_TABLET = 8;

const cardWidths: Record<
	StatsAndAveragesKey,
	{
		columnSpanMid: number;
		columnSpanWide: number;
		columnSpanNarrow: number;
		columnSpanTablet: number;
	}
> = {
	marketCount: {
		columnSpanMid: 4,
		columnSpanWide: 3,
		columnSpanNarrow: 5,
		columnSpanTablet: COLUMN_SPAN_TABLET,
	},
	propertyCount: {
		columnSpanMid: 4,
		columnSpanWide: 3,
		columnSpanNarrow: 6,
		columnSpanTablet: COLUMN_SPAN_TABLET,
	},
	totalSqFt: {
		columnSpanMid: 5,
		columnSpanWide: 4,
		columnSpanNarrow: 7,
		columnSpanTablet: COLUMN_SPAN_TABLET,
	},
	activeLeaseCount: {
		columnSpanMid: 5,
		columnSpanWide: 5,
		columnSpanNarrow: 6,
		columnSpanTablet: COLUMN_SPAN_TABLET,
	},
	expiringSqFt24Months: {
		columnSpanMid: 6,
		columnSpanWide: 5,
		columnSpanNarrow: 6,
		columnSpanTablet: COLUMN_SPAN_TABLET,
	},
	avgTransactionalSqFt: {
		columnSpanMid: 7,
		columnSpanWide: 4,
		columnSpanNarrow: 6,
		columnSpanTablet: COLUMN_SPAN_TABLET,
	},
	avgCurrentRent: {
		columnSpanMid: 8,
		columnSpanWide: 4,
		columnSpanNarrow: 6,
		columnSpanTablet: COLUMN_SPAN_TABLET,
	},
	avgNetEffectiveRent: {
		columnSpanMid: 9,
		columnSpanWide: 6,
		columnSpanNarrow: 6,
		columnSpanTablet: COLUMN_SPAN_TABLET,
	},
	avgFreeRent: {
		columnSpanMid: 6,
		columnSpanWide: 4,
		columnSpanNarrow: 8,
		columnSpanTablet: COLUMN_SPAN_TABLET,
	},
	avgLeaseTerm: {
		columnSpanMid: 8,
		columnSpanWide: 5,
		columnSpanNarrow: 8,
		columnSpanTablet: COLUMN_SPAN_TABLET + 4,
	},
	avgTIImprovement: {
		columnSpanMid: 10,
		columnSpanWide: 5,
		columnSpanNarrow: 8,
		columnSpanTablet: COLUMN_SPAN_TABLET + 4,
	},
};

const useStatsAndAverages = (portfolio: PortfolioV2) => {
	const statsAndAveragesFieldsData = {} as StatsAndAveragesFieldsData;
	statsAndAveragesKeys.map((key) => {
		const value = portfolio.metrics[key];
		const formattedValue = getFormattedValueForField(key, value);
		statsAndAveragesFieldsData[key] = {
			formattedValue,
			toolTipText: TOOLTIP_TEXT[key],
			title: TITLES[key],
			columnSpanNarrow: cardWidths[key].columnSpanNarrow,
			columnSpanMid: cardWidths[key].columnSpanMid,
			columnSpanWide: cardWidths[key].columnSpanWide,
			columnSpanTablet: cardWidths[key].columnSpanTablet,
		};
	});
	return statsAndAveragesFieldsData;
};

export const getFormattedValueForField = (
	field: StatsAndAveragesKey,
	value: number | null | undefined
) => {
	if (value == null || value < 0) return nullValueText;
	switch (field) {
		case 'expiringSqFt24Months':
		case 'avgTransactionalSqFt':
			return formatInteger(value);
		case 'avgCurrentRent':
		case 'avgNetEffectiveRent':
		case 'avgTIImprovement':
			return formatMoney(value);
		case 'avgFreeRent':
		case 'avgLeaseTerm':
			return formatMonths(value);
		default:
			return abbreviateNumber(value);
	}
};

const formatMonths = (months: number) => {
	if (months === 0) return '0 mo';
	return `${months.toFixed(1)} mo`;
};

const CardsRow = styled.div`
	display: grid;
	grid-template-columns: auto;
	gap: 0.5rem;
	grid-template-columns: repeat(24, 1fr);
	margin-bottom: 1rem;
`;

const Card = styled.div<{
	columnSpanMid: number;
	columnSpanWide: number;
	columnSpanNarrow: number;
	columnSpanTablet: number;
}>`
	display: flex;
	flex-direction: column;
	padding: 12px;
	align-items: flex-start;
	gap: 0.5rem;
	height: 90px;
	border-radius: 0.5rem;
	border: 1px solid ${({ theme }) => theme.colors.gray.gray100};
	background: ${({ theme }) => theme.colors.white.white};
	box-shadow: 0px 1.5px 2px 0px rgba(16, 24, 40, 0.1);
	grid-column: span ${({ columnSpanTablet }) => columnSpanTablet};

	@media (min-width: ${MQB.D_1280}) {
		grid-column: span ${({ columnSpanNarrow }) => columnSpanNarrow};
	}

	@media (min-width: ${MQB.D_1536}) {
		padding: 20px;
		height: 105px;
		grid-column: span ${({ columnSpanMid }) => columnSpanMid};
	}

	@media (min-width: ${MQB.D_1920}) {
		grid-column: span ${({ columnSpanWide }) => columnSpanWide};
	}
`;

const CardTitle = styled.div`
	display: flex;
	gap: 0.5rem;
	color: ${({ theme }) => theme.colors.neutral.n100};
	font-weight: 325;
	font-size: 1em;
	line-height: 1.125em;
	letter-spacing: 0.08px;
	min-height: 2rem;

	@media (min-width: ${MQB.D_1280}) {
		font-size: 0.875em;
		line-height: 1.25em;
		min-height: unset;
	}

	@media (min-width: ${MQB.D_1536}) {
		font-size: 1em;
		line-height: 1.5em;
	}
`;

const CardValue = styled.div`
	font-size: 1.125em;
	font-weight: 400;
	color: ${({ theme }) => theme.colors.gray.gray700};
	font-weight: 500;
	line-height: 1.25em;
	letter-spacing: 0.12px;

	@media (min-width: ${MQB.D_1280}) {
		line-height: 1.75em;
	}

	@media (min-width: ${MQB.D_1536}) {
		font-size: 1.125em;
		line-height: 2em;
	}
`;
