import Stats from 'Components/Stats';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { FiltersObject } from 'models/filters/types';
import styled from 'styled-components';
import { CompType } from 'types';
import { MapResultsButton } from '../MapResultsButton';
import { LeagueTablesNavButton } from './LeagueTablesNavButton';
import { SearchToolbarButtons } from './SearchToolbarButtons';
import { StatsContainer } from './UI';
import { useHasAccessToLeagueTables } from 'hooks/useHasAccessToLeagueTables';

type SearchToolbarListToolbarProps = {
	compType: CompType;
	filters: FiltersObject;
	params: SearchLayoutRouteParams;
};

export const SearchToolbarListToolbar = ({
	compType,
	params,
}: SearchToolbarListToolbarProps) => {
	const hasAccessToLeagueTables = useHasAccessToLeagueTables();

	const showLeagueTables = hasAccessToLeagueTables && compType === 'sale';

	return (
		<Root>
			<SearchToolbarButtons compType={compType} params={params} />
			<StatsContainer>
				<div>
					<Stats compType={compType} view={params.view} />
				</div>
				<div>
					<MapResultsButton compType={compType} />
				</div>
				{showLeagueTables && (
					<div>
						<LeagueTablesNavButton
							compType={compType}
							isActive={params.view === 'leagueTables'}
						/>
					</div>
				)}
			</StatsContainer>
		</Root>
	);
};

const Root = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 1.25rem 1rem;
	background-color: ${(p) => p.theme.colors.white.white};
	gap: 1rem;
`;
