import { styled } from 'styled-components';
import { BenchmarkingViewHeader } from './BenchmarkingViewHeader';
import { BenchmarkingTable } from './BenchmarkingTable/BenchmarkingTable';
import { useMemo, useState } from 'react';
import { PortfolioFiltersProvider } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { PortfoliosMap } from 'PortfolioAnalytics/PortfolioMap/PortfoliosMap';

import { BenchmarkingCharts } from './BenchmarkingCharts/BenchmarkingTabCharts';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { usePortfoliosByIdsQueriesV2 } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueriesV2';

export const BenchmarkingView = () => {
	const [elementToExport, setElementToExport] = useState<HTMLDivElement | null>(
		null
	);

	const { selectedPortfolioIds } = usePortfoliosSelection();

	const selectedPortfoliosResults = usePortfoliosByIdsQueriesV2(
		selectedPortfolioIds.map((id) => ({ id }))
	);

	const portfolioMarkets = useMemo(() => {
		return selectedPortfoliosResults.flatMap(
			(result) => result.data?.markets ?? []
		);
	}, [selectedPortfoliosResults]);

	return (
		<PortfolioFiltersProvider portfolioMarkets={portfolioMarkets}>
			<Container ref={setElementToExport}>
				<BenchmarkingViewHeader elementToExport={elementToExport} />
				<PortfoliosMap portfolioIds={selectedPortfolioIds} isMapView />
				<BenchmarkingTable />
				<BenchmarkingCharts />
			</Container>
		</PortfolioFiltersProvider>
	);
};

const Container = styled.div`
	padding: 1.5rem;
	display: flex;
	flex-direction: column;
	gap: 1rem;
	min-height: 100%;
	width: 100%;
	overflow: auto;
	background-color: ${(p) => p.theme.colors.white.white};
`;
