import { ReactNode } from 'react';
import styled, { css } from 'styled-components';

export function CreditAwardCard({
	awardCredits,
	requirements,
	bonus,
}: {
	awardCredits: number;
	requirements: ReactNode;
	bonus?: BonusSpec;
}) {
	return (
		<CardOutline>
			<CardTop>
				<CardTopFstLine>
					YOU GET
					<GreenNumber big>{awardCredits}</GreenNumber> CREDITS
				</CardTopFstLine>
				<div>If your comp...</div>
			</CardTop>
			<CardBody>
				<BodyHalf>{requirements}</BodyHalf>
				{!!bonus && <BonusSection {...bonus} />}
			</CardBody>
		</CardOutline>
	);
}

const CardOutline = styled.div`
	width: 320px;
	height: 380px;
	border: 1px solid ${(props) => props.theme.colors.gray.n100};
	border-radius: 4px;

	& ul {
		list-style: disc;
		margin-left: 16px;
	}
`;
CardOutline.displayName = 'CardOutline';

const CardTop = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 120px;
	background-color: ${(props) => props.theme.colors.gray.gray200};
	border-radius: 4px 4px 0px 0px;
`;
CardTop.displayName = 'CardTop';

const CardTopFstLine = styled.div`
	display: flex;
	justify-content: center;
	align-items: baseline;
	font-weight: 450;
	line-height: 40px;
`;
CardTopFstLine.displayName = 'CardTopFstLine';

const GreenNumber = styled.span<{ big?: boolean }>`
	${(props) =>
		props.big
			? css`
					font-size: 40px;
					line-height: 40px;
				`
			: css`
					font-size: 24px;
					line-height: 24px;
				`}
	color: ${(props) => props.theme.colors.green.green500};
	margin: 0 4px;
`;
GreenNumber.displayName = 'GreenNumber';

const CardBody = styled.div`
	padding: 12px;
`;
CardBody.displayName = 'CardBody';

const BodyHalf = styled.div`
	margin-bottom: 16px;
`;
BodyHalf.displayName = 'BodyHalf';

type BonusSpec = {
	bonusAward: number;
	description: string[];
};

function BonusSection({ bonusAward, description }: BonusSpec) {
	return (
		<>
			<BonusHeader>
				<BonusTitle>
					<GreenNumber>+{bonusAward}</GreenNumber>
					BONUS CREDITS
				</BonusTitle>
				<div>If your comp also contains...</div>
			</BonusHeader>
			<ul>
				{description.map((d) => (
					<li key={d}>{d}</li>
				))}
			</ul>
		</>
	);
}

const BonusHeader = styled.div`
	display: flex;
	flex-direction: column;
	gap: 4px;
	align-items: center;
	margin-bottom: 8px;
`;
BonusHeader.displayName = 'BonusHeader';

const BonusTitle = styled.div`
	font-weight: 450;
	gap: 4px;
`;
BonusTitle.displayName = 'BonusTitle';
