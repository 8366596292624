import { PerformanceDistributionChartContent } from 'PortfolioAnalytics/Charts/PortfolioPerformanceDistributionContent';
import { Spacer } from 'PortfolioAnalytics/UI';
import { Strong } from 'utils/highlightText';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { ChartBox } from 'PortfolioAnalytics/Charts/ChartSelect';

export const BenchmarkingPerformanceDistributionChart = () => {
	const selectedPortfolioIdsForBenchmarking =
		usePortfoliosSelection().selectedPortfolioIds;
	return (
		<ChartBox
			chartName="Portfolio Performance Distribution"
			displaySelector={false}
			chartToolTip={BENCHMARKING_PERFORMANCE_TOOLTIP}
		>
			<PerformanceDistributionChartContent
				portfolioIds={selectedPortfolioIdsForBenchmarking}
			/>
		</ChartBox>
	);
};

const BENCHMARKING_PERFORMANCE_TOOLTIP = (
	<>
		<Spacer>
			The Performance chart displays a distribution of risk and performance of
			properties in each of the selected portfolios.
		</Spacer>
		<Spacer>
			<Strong>High Performance:</Strong> Properties with higher rent than the
			market.
		</Spacer>
		<Spacer>
			<Strong>Low Performance:</Strong> Properties with lower rent than the
			market.
		</Spacer>
		<Spacer>
			<Strong>High Risk:</Strong> Properties with many leases expiring in the
			next 24 months.
		</Spacer>
		<Spacer>
			<Strong>Low Risk:</Strong> Properties with few leases expiring in the next
			24 months.
		</Spacer>
	</>
);
