import {
	Flex,
	renderTableValue,
	SimpleTableColumn,
	TooltipV2,
} from '@compstak/ui-kit';
import {
	SAVED_SEARCH_TYPE_TO_COMP_TYPE,
	SavedSearch,
} from 'api/savedSearches/useSavedSearchesQuery';
import { formatDate } from 'format';
import { useMarkets } from 'hooks/useMarkets';
import { EditOptionsDropdown } from './Components/EditOptionsDropdown';
import { SavedSearchNewCount } from './Components/SavedSearchNewCount';
import { filtersFromServerJSON } from 'models/filters/util';
import { getTooltip } from 'models/filters/util/tooltips';
import styled from 'styled-components';
import dayjs from 'dayjs';
import { tooltipCss } from 'utils';

export const useSavedSearchesColumns = () => {
	const markets = useMarkets();
	const columns: SimpleTableColumn<SavedSearch>[] = [
		{
			id: 'name',
			header: () => 'Name',
			body: ({ row }) => {
				const jobLastRun =
					row.digestLastRan && dayjs(row.lastRan).isBefore(row.digestLastRan)
						? row.digestLastRan
						: row.lastRan;
				const displayCount =
					!row.dateSeen || dayjs(row.dateSeen).isBefore(jobLastRun);
				return (
					<Flex gap="0.5rem">
						<Name>{row.name}</Name>
						{displayCount && (
							<SavedSearchNewCount
								savedSearch={row}
								dateCreatedMin={jobLastRun}
							/>
						)}
					</Flex>
				);
			},
			width: 460,
			flex: 1,
		},
		{
			id: 'lastRan',
			header: () => {
				return (
					<TooltipV2
						content={
							'Demonstrates the most recent date new comps were added to this Saved Search'
						}
						contentCSS={tooltipCss}
					>
						<span>Last Updated</span>
					</TooltipV2>
				);
			},
			body: ({ row }) => {
				const jobLastRun =
					row.digestLastRan && dayjs(row.lastRan).isBefore(row.digestLastRan)
						? row.digestLastRan
						: row.lastRan;
				return renderTableValue(jobLastRun, formatDate);
			},
			width: 120,
			flex: 1,
		},
		{
			id: 'dateSeen',
			header: () => 'Last Viewed',
			body: ({ row }) =>
				renderTableValue(row.dateSeen ?? row.lastRan, formatDate),
			width: 120,
			flex: 1,
		},
		{
			id: 'filters',
			header: () => 'Filters Applied',
			body: ({ row }) => {
				const compType = SAVED_SEARCH_TYPE_TO_COMP_TYPE[row.searchType];
				const filters = filtersFromServerJSON(compType, markets, row.filters);
				const tooltip = getTooltip(filters, compType);
				return (
					<TooltipV2 content={tooltip} contentCSS={tooltipCss}>
						<span>
							{row.filters.filter((f) => f.property !== 'hidden').length}{' '}
							Filters Set
						</span>
					</TooltipV2>
				);
			},
			width: 120,
			flex: 1,
		},
		{
			id: 'edit',
			header: () => 'Edit',
			body: ({ row }) => <EditOptionsDropdown savedSearch={row} />,
			width: 48,
		},
	];

	return columns;
};

const Name = styled.div`
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
`;
