import { Spinner, MQB } from '@compstak/ui-kit';
import { usePortfolioByIdQuery } from 'api';
import {
	DashboardCardLoadingContainer,
	PortfolioOverviewSectionHeader,
} from 'PortfolioAnalytics/Dashboard/UI';
import { LeaseExpirationsBySpaceTypeChart } from './LeaseExpirationsBySpaceTypeChart';
import { BuildingClassChart } from './BuildingClassChart';
import { BuildingClassByPropertyTypeChart } from './BuildingClassByPropertyTypeChart';
import { PropertyTypeChart } from './PropertyTypeChart';
import { TenantExposureChart } from './TenantExposureChart';
import { TenantIndustryExposureChart } from './TenantIndustryExposureChart';
import { PortfolioPerformanceDistributionChart } from './PortfolioPerformanceDistributionChart';
import { PropertyPerformanceMatrixChart } from './PropertyPerformanceMatrixChart';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { ChartsContainerV2, ChartsContainer_6_5 } from '../styles/PortfolioUI';
import { useMediaQuery } from 'react-responsive';
import { useFeatureFlags } from 'hooks/useFeatureFlags';

type OverviewTabChartsProps = {
	portfolioId: number;
};

export const OverviewTabCharts = ({ portfolioId }: OverviewTabChartsProps) => {
	const { portfolioRevampPhase6_5 } = useFeatureFlags();
	const { filters } = usePortfolioFilters();

	const { data: portfolio, isFetching: isFetchingPortfolio } =
		usePortfolioByIdQuery({
			id: portfolioId,
			filters,
		});

	const isNarrowScreen = useMediaQuery({
		maxWidth: MQB.D_1280,
	});
	const isWideScreen = useMediaQuery({
		minWidth: MQB.D_1920,
	});

	const minBarChartWidth = portfolioRevampPhase6_5
		? isWideScreen
			? 440
			: isNarrowScreen
				? 400
				: 420
		: isWideScreen
			? 600
			: isNarrowScreen
				? 450
				: 400;

	if (portfolio == null || isFetchingPortfolio) {
		return (
			<DashboardCardLoadingContainer>
				<Spinner />
			</DashboardCardLoadingContainer>
		);
	}

	return (
		<>
			{portfolioRevampPhase6_5 && (
				<>
					<PortfolioOverviewSectionHeader>
						Risk Analysis
					</PortfolioOverviewSectionHeader>
					<ChartsContainer_6_5>
						<PropertyPerformanceMatrixChart
							portfolioId={portfolioId}
							filters={filters}
						/>
						<PortfolioPerformanceDistributionChart portfolioId={portfolioId} />
					</ChartsContainer_6_5>
					<ChartsContainer_6_5>
						<LeaseExpirationsBySpaceTypeChart
							portfolio={portfolio}
							minBarChartWidth={minBarChartWidth}
						/>
						<TenantExposureChart
							portfolio={portfolio}
							minBarChartWidth={minBarChartWidth}
						/>
						<TenantIndustryExposureChart portfolio={portfolio} />
					</ChartsContainer_6_5>
					<PortfolioOverviewSectionHeader>
						Portfolio Breakdown
					</PortfolioOverviewSectionHeader>
					<ChartsContainer_6_5>
						<PropertyTypeChart portfolio={portfolio} />
						<BuildingClassByPropertyTypeChart portfolio={portfolio} />
					</ChartsContainer_6_5>
				</>
			)}
			{!portfolioRevampPhase6_5 && (
				<ChartsContainerV2>
					<TenantExposureChart
						portfolio={portfolio}
						minBarChartWidth={minBarChartWidth}
					/>
					<TenantIndustryExposureChart portfolio={portfolio} />
					<LeaseExpirationsBySpaceTypeChart
						portfolio={portfolio}
						minBarChartWidth={minBarChartWidth}
					/>
					<PropertyTypeChart portfolio={portfolio} />
					<BuildingClassChart portfolio={portfolio} />
				</ChartsContainerV2>
			)}
		</>
	);
};
