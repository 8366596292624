import { OverviewTabCharts } from 'PortfolioAnalytics/Charts/OverviewTabCharts';
import { PortfolioOverviewV2 } from 'PortfolioAnalytics/Dashboard/PortfolioOverview/PortfolioOverviewV2';

type PortfolioTabProps = {
	portfolioId: number;
};

export const OverviewTab = ({ portfolioId }: PortfolioTabProps) => {
	return (<>
		<PortfolioOverviewV2 portfolioId={portfolioId} />
		<OverviewTabCharts portfolioId={portfolioId} />
	</>);
};
