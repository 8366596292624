import { FormSelect, Spinner } from '@compstak/ui-kit';
import { useAllPortfoliosQuery, useUpdatePortfolioMutation } from 'api';
import { usePortfoliosWithAggregations } from 'PortfolioAnalytics/PortfolioSidebar/usePortfoliosWithAggregations';
import { useSortedPortfolios } from 'PortfolioAnalytics/PortfolioSidebar/useSortedPortfolios';
import { PORTFOLIO_LIMIT } from 'api/portfolio/constants';
import { PORTFOLIO_LIMIT_EXCEEDED_MESSAGE } from 'PortfolioAnalytics/constants';
import { useMemo, useState } from 'react';

import { DialogFormError } from '../UI';
import { SuccessView } from './SuccessView';
import {
	Modal,
	ModalButton,
	ModalButtons,
	ModalTitle,
} from 'Components/Modals/common/UI';
import { addFeedback } from 'Singletons/Feedback/actions';
import { useDispatch } from 'react-redux';
import { RadioButton } from 'Components/RadioButton/RadioButton';
import { CreateAPortfolioDialog } from './CreateAPortfolioDialog';
import styled from 'styled-components';

export const AddToPortfolioDialog = ({
	propertyIds,
	closeDialog,
	isLoading,
}: {
	propertyIds: number[];
	closeDialog: NoArgCallback;
	isLoading?: boolean;
}) => {
	const [showSuccess, setShowSuccess] = useState(false);
	const [selectedPortfolioId, setSelectedPortfolioId] = useState<
		number | undefined
	>();

	const [newPortfolioOptionSelected, setNewPortfolioOptionSelected] =
		useState<boolean>(false);

	const { data: portfolios, isFetching: isFetchingPortfolios } =
		useAllPortfoliosQuery();
	const dispatch = useDispatch();
	const onError = () => {
		closeDialog();
		dispatch(
			addFeedback(
				'Adding properties to portfolio was unsuccessful',
				'error',
				null,
				5000
			)
		);
	};

	const { mutateAsync: updatePortfolio, isLoading: isLoadingUpdate } =
		useUpdatePortfolioMutation({
			onError,
			onSuccess: () => setShowSuccess(true),
		});

	const selectedPortfolio = useMemo(() => {
		return portfolios?.find((p) => p.id === selectedPortfolioId);
	}, [portfolios, selectedPortfolioId]);

	const onSubmit = async () => {
		if (newPortfolioOptionSelected || !selectedPortfolio) return;

		updatePortfolio({
			id: selectedPortfolio.id,
			body: {
				id: selectedPortfolio.id,
				name: selectedPortfolio.name,
				description: selectedPortfolio.description,
				newPropertyIds: propertyIds,
			},
		});
	};

	const closeCreateAPortfolioDialog = () => {
		setNewPortfolioOptionSelected(false);
	};

	const selectedPortfolioPropertyCount = useMemo(() => {
		if (!selectedPortfolio) return;

		return selectedPortfolio.markets.reduce(
			(sum, m) => sum + m.propertyCount,
			0
		);
	}, [selectedPortfolio]);

	const isPropertyCountValid =
		selectedPortfolioPropertyCount != null
			? selectedPortfolioPropertyCount + propertyIds.length <= PORTFOLIO_LIMIT
			: true;

	const portfoliosWithAggregates = usePortfoliosWithAggregations(portfolios);
	const sortedPortfolios = useSortedPortfolios({
		portfoliosWithAggregates,
		sortField: 'dateCreated',
		sortDirection: 'desc',
	});

	const options = useMemo(() => {
		return sortedPortfolios.map((p) => ({
			title: p.name,
			value: p.id,
		}));
	}, [sortedPortfolios]);

	const isSubmitDisabled =
		selectedPortfolio == null ||
		!isPropertyCountValid ||
		isLoadingUpdate ||
		newPortfolioOptionSelected;

	return (
		<>
			<Modal onClose={closeDialog}>
				{showSuccess ? (
					<SuccessView
						portfolioId={selectedPortfolioId!}
						closeDialog={closeDialog}
					/>
				) : (
					!newPortfolioOptionSelected && (
						<form
							onSubmit={(e) => {
								e.preventDefault();
								onSubmit();
							}}
						>
							<ModalTitle>Add To Portfolio</ModalTitle>
							{isLoading ? (
								<Spinner />
							) : (
								<>
									{!isPropertyCountValid && (
										<DialogFormError>
											{PORTFOLIO_LIMIT_EXCEEDED_MESSAGE}
										</DialogFormError>
									)}
									<RadioButtonsContainer>
										<RadioButton
											name="modal-radio-button"
											checked={!newPortfolioOptionSelected}
											onChange={() => setNewPortfolioOptionSelected(false)}
										>
											<RadioButtonSpan>
												Select from existing portfolios
											</RadioButtonSpan>
										</RadioButton>
										<FormSelect
											isSearchable
											isLoading={isFetchingPortfolios}
											value={selectedPortfolioId}
											items={options}
											onChange={({ selectedItem }) =>
												selectedItem &&
												setSelectedPortfolioId(Number(selectedItem.value))
											}
											noResultMessage="No Portfolios Found"
											disabled={newPortfolioOptionSelected}
										/>
										<Spacer />
										<RadioButton
											name="modal-radio-button"
											disabled={!isPropertyCountValid}
											checked={newPortfolioOptionSelected}
											onChange={() => {
												setNewPortfolioOptionSelected(true);
												setSelectedPortfolioId(undefined);
											}}
										>
											<RadioButtonSpan>Create a new portfolio</RadioButtonSpan>
										</RadioButton>
									</RadioButtonsContainer>

									<ModalButtons>
										<ModalButton
											variant="secondary"
											type="button"
											onClick={closeDialog}
										>
											Cancel
										</ModalButton>
										<ModalButton
											type="submit"
											variant="primary"
											disabled={isSubmitDisabled}
											isLoading={isLoadingUpdate}
										>
											Add To Portfolio
										</ModalButton>
									</ModalButtons>
								</>
							)}
						</form>
					)
				)}
				{newPortfolioOptionSelected && (
					<CreateAPortfolioDialog
						propertyIds={propertyIds}
						closeDialog={closeDialog}
						closeCreateAPortfolioDialog={closeCreateAPortfolioDialog}
					/>
				)}
			</Modal>
		</>
	);
};

const RadioButtonsContainer = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 0.75rem;
	margin-bottom: 1rem;
`;

const RadioButtonSpan = styled.span`
	color: ${({ theme }) => theme.colors.gray.gray700};
	font-size: 1rem;
	font-style: normal;
	font-weight: 450;
	line-height: 1.5rem;
`;

const Spacer = styled.div``;
