import React, { useMemo } from 'react';
import { Portfolio, PortfolioTenantIndustry } from 'api';
import { formatPercent } from 'format';
import { sortBy } from 'lodash';
import {
	ChartBox,
	ChartDataMappingLease,
	getTotalValuesLease,
} from './ChartSelect';
import { PortfolioPieChart } from './PortfolioPieChart';
import { NoDataTab } from 'Components/NoDataMessaging/NoDataMessaging';
import { ChartContainer } from 'PortfolioAnalytics/styles/PortfolioUI';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { OVERVIEW_CHARTS_NO_DATA_COMPONENT_HEIGHT } from 'PortfolioAnalytics/constants';

export const getTenantIndustryChartData = (portfolio: Portfolio) => {
	const { id, tenantIndustries } = portfolio;
	const tenantIndustriesData = getTenantIndustriesData(tenantIndustries);
	const totalValues = getTotalValuesLease(tenantIndustriesData);

	return {
		portfolioId: id,
		sqft: getChartOptionsData(
			tenantIndustriesData,
			totalValues.totalSqFt,
			'totalSqFt'
		),
		leaseCount: getChartOptionsData(
			tenantIndustriesData,
			totalValues.activeLeaseCount,
			'activeLeaseCount'
		),
		leaseAmount: getChartOptionsData(
			tenantIndustriesData,
			totalValues.activeLeaseAmount,
			'activeLeaseAmount'
		),
	};
};

type TenantIndustryExposureChartProps = {
	portfolio: Portfolio;
};

const TOP_INDUSTRIES_COUNT = 5;
const TENANT_INDUSTRY_CHART_NAME = `Top ${TOP_INDUSTRIES_COUNT} Tenant Industry Exposure`;

export const TenantIndustryExposureChart = ({
	portfolio,
}: TenantIndustryExposureChartProps) => {
	const chartData: ChartDataMappingLease = useMemo(
		() => getTenantIndustryChartData(portfolio),
		[portfolio]
	);

	const navigate = useNavigate();

	return (
		<ChartBox<'lease'> chartName={TENANT_INDUSTRY_CHART_NAME}>
			{(selectedChart) => {
				const data = chartData[selectedChart.type];

				if (!data.length) {
					return (
						<ChartContainer>
							<NoDataTab
								minHeight={OVERVIEW_CHARTS_NO_DATA_COMPONENT_HEIGHT}
								message=""
							/>
						</ChartContainer>
					);
				}

				return (
					<PortfolioPieChart
						data={data}
						events={[
							{
								target: 'data',
								eventHandlers: {
									onClick: (_, clickedProps) => {
										const tenantIndustries =
											clickedProps.datum.tenantIndustries;
										navigate(
											routes.portfolioByIdView.toHref(
												{ portfolioId: portfolio.id, viewType: 'list' },
												{ tenantIndustry: tenantIndustries }
											)
										);
									},
								},
							},
						]}
					/>
				);
			}}
		</ChartBox>
	);
};

type ChartKey = 'activeLeaseAmount' | 'totalSqFt' | 'activeLeaseCount';

type TenantIndustry = Pick<
	PortfolioTenantIndustry,
	ChartKey | 'tenantIndustry'
>;

const getTenantIndustriesData = (
	portfolioTenantIndustries: PortfolioTenantIndustry[]
): TenantIndustry[] => {
	const groupedIndustries = portfolioTenantIndustries.reduce<{
		[tenantIndustry: string]: TenantIndustry;
	}>(
		(
			industries,
			{ tenantIndustry, activeLeaseCount, activeLeaseAmount, totalSqFt }
		) => {
			const existingIndustry = industries[tenantIndustry];
			industries[tenantIndustry] = existingIndustry
				? {
						tenantIndustry,
						activeLeaseCount:
							existingIndustry.activeLeaseCount + activeLeaseCount,
						activeLeaseAmount:
							existingIndustry.activeLeaseAmount + activeLeaseAmount,
						totalSqFt: existingIndustry.totalSqFt + totalSqFt,
					}
				: { tenantIndustry, activeLeaseCount, activeLeaseAmount, totalSqFt };

			return industries;
		},
		{}
	);
	return Object.values(groupedIndustries);
};

const getChartOptionsData = (
	tenantIndustriesData: TenantIndustry[],
	totalValue: number,
	key: ChartKey
) => {
	const sorted = sortBy(tenantIndustriesData ?? [], (industry) => industry[key])
		.reverse()
		.map((industry) => ({
			x: industry.tenantIndustry,
			y: industry[key],
			tenantIndustries: [industry.tenantIndustry],
		}));

	const topFive = sorted.slice(0, TOP_INDUSTRIES_COUNT).map((dataPoint) => ({
		...dataPoint,
		label: formatPercent(dataPoint.y / totalValue, 1),
	}));

	const other = sorted.slice(TOP_INDUSTRIES_COUNT, sorted.length);
	const otherDataSqft = other.reduce(
		(acc, dataPoint) => acc + (dataPoint?.y ?? 0),
		0
	);

	const otherDataPoint = {
		x: 'Other industries',
		y: otherDataSqft,
		label: formatPercent(otherDataSqft / totalValue, 1),
		tenantIndustries: other.map((industry) => industry.x),
	};

	const data = [...topFive, otherDataPoint].filter(({ y }) => y > 0);
	return data;
};
