import styled from 'styled-components';

const Container = styled.div``;

const CustomTooltipContainer = styled.div`
	display: flex;
	height: 220px;
`;

const CustomTooltipBar = styled.div<{
	hide: boolean;
	right?: boolean;
	height?: string;
}>`
	position: relative;
	width: 5px;
	border-right: 1px dashed #262831;
	height: ${(p) => (p.height ? p.height : '220px')};
	margin-right: 4px;

	> div {
		position: absolute;
		bottom: 0;
		height: 9px;
		width: 9px;
		background-color: #262831;
		border: 1px solid #ffffff;
		border-radius: 50%;

		${({ right }) => right && 'right: 0;'}
	}

	${(p) =>
		p.right &&
		`
		border-right: none;
		border-left: 1px dashed #262831;
		margin-right: 0;
		height: ${p.height ? p.height : '220px'};
		margin-left: 4px;`}

	${({ hide }) => hide && 'display: none'}
`;

const CustomTooltipInfoBox = styled.div<{ height?: number }>`
	display: flex;
	flex-direction: column;
	gap: 8px;
	height: ${({ height }) => (height ? `${height}px` : '86px')};
	width: 218px;
	padding: 8px;
	border-radius: 3px;
	background-color: #262831;
	color: #ffffff;
	cursor: default;
`;

const CustomTooltipInfoBoxHeading = styled.div`
	display: flex;
	justify-content: space-between;

	> div {
		font-size: 10px;
	}
`;

const CustomTooltipInfoBoxData = styled.div`
	display: flex;
	justify-content: space-between;
	font-size: 11px;

	> div {
		display: flex;
		gap: 4px;
	}

	span {
		font-weight: 500;
	}
`;

const CustomTooltipInfoBoxIndicator = styled.div<{ color: string }>`
	background-color: ${({ color }) => color};
	height: 6px;
	width: 12px;
	border-radius: 3px;
	margin-top: 2px;
`;

const CustomTooltipInfoBoxIndicatorLayer = styled.div<{
	color: string;
	top: number;
	zIndex: number;
}>`
	background-color: ${({ color }) => color};
	height: 6px;
	width: 12px;
	border-radius: 3px;
	margin-top: 2px;
	position: absolute;
	top: ${({ top }) => top};
	left: 5;
	z-index: ${({ zIndex }) => zIndex};
`;

const CustomTooltipInfoBoxName = styled.div`
	width: 133px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
`;

export const Styled = {
	Container,
	CustomTooltipContainer,
	CustomTooltipInfoBox,
	CustomTooltipBar,
	CustomTooltipInfoBoxHeading,
	CustomTooltipInfoBoxData,
	CustomTooltipInfoBoxIndicator,
	CustomTooltipInfoBoxName,
	CustomTooltipInfoBoxIndicatorLayer,
};
