import React, { useMemo, useState } from 'react';
import { useInsightsQueries } from 'api';
import { TrendFilters } from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { DownloadBranding } from 'PortfolioAnalytics/Singletons/DownloadBranding';
import { BenchmarkingTrendChartComponent as Chart } from './BenchmarkingTrendChartComponent';
import { PortfolioTrendFiltersComponent as Filters } from 'PortfolioAnalytics/Charts/PortfolioTrend/Filters/PortfolioTrendFilterComponent';
import { Spacer } from 'PortfolioAnalytics/UI';
import {
	getTrendlineData,
	getDateForTimeSpan,
	getScatterData,
} from 'PortfolioAnalytics/Charts/PortfolioTrend/util';
import { ChartLegendV2 as Legend } from '../ChartLegend';
import { Bubbles } from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { InsightsParams } from 'api';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { getTrendQueryParams } from 'PortfolioAnalytics/Charts/PortfolioTrend/util';
import { CalculationOverview } from 'PortfolioAnalytics/Charts/PortfolioTrend/PortfolioTrendChart';
import { usePortfoliosByIdsQueriesV2 } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueriesV2';
import { BENCHMARKING_COLORS } from '../../constants';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { portfolioNameById } from '../../utils';
import { ChartBox } from 'PortfolioAnalytics/Charts/ChartSelect';
import { BenchmarkingChartStateWrapper } from '../BenchmarkingChartStatusWrapper';

const downloadBannerId = 'Trends';

type ChartData = {
	portfolio: {
		x: Date;
		y: number;
	}[];
	points: Bubbles[];
};

const portfolioHasData = (portfolioData: ChartData) =>
	(portfolioData.portfolio && portfolioData.portfolio.length > 0) ||
	(portfolioData.points && portfolioData.points.length > 0);

export const BenchmarkingTrendChart = () => {
	return (
		<ChartBox
			chartName="Trends"
			displaySelector={false}
			chartToolTip={TRENDS_TOOLTIP}
		>
			<BenchmarkingTrendChartContent />
		</ChartBox>
	);
};

export const BenchmarkingTrendChartContent = () => {
	const { selectedPortfolioIds } = usePortfoliosSelection();

	const { filters } = usePortfolioFilters();
	const portfoliosResults = usePortfoliosByIdsQueriesV2(
		selectedPortfolioIds.map((id) => ({ id, filters }))
	);

	const portfoliosData = useMemo(() => {
		return portfoliosResults.map(({ data }) => data);
	}, [portfoliosResults]);

	const isFetchingPortfoliosData = portfoliosResults.some(
		(result) => result.isFetching
	);
	const isErrorPortfoliosData = portfoliosResults.some(
		(result) => result.isError
	);

	const [trendFilters, setTrendFilters] = useState<TrendFilters>({
		metric: 'effectiveRent',
		timeSpan: 3,
		movAverage: 3,
	});

	const portfolioQueryParamsArray: InsightsParams[] = useMemo(() => {
		return portfoliosData
			.map((portfolio) => {
				if (!portfolio) {
					return null;
				}
				return getTrendQueryParams({
					filters: filters,
					portfolio: portfolio,
					trendFilters: trendFilters,
				}).portfolioQueryParams;
			})
			.filter((item): item is InsightsParams => item !== null);
	}, [trendFilters, filters, portfoliosData]);

	const portfoliosTrendResults = useInsightsQueries(portfolioQueryParamsArray);
	const portfoliosTrendData = useMemo(
		() =>
			portfoliosTrendResults.map((result, index) => {
				return { ...result.data, portfolio: portfoliosData[index] };
			}),
		[portfoliosTrendResults, portfoliosData]
	);

	const isFetchingPortfoliosTrend = portfoliosTrendResults.some(
		(result) => result.isFetching
	);
	const isPortfolioTrendError = portfoliosTrendResults.some(
		(result) => result.isError
	);
	const isError = isErrorPortfoliosData || isPortfolioTrendError;

	const isFetching = isFetchingPortfoliosData || isFetchingPortfoliosTrend;

	const chartData = useMemo(() => {
		if (
			isFetching ||
			portfoliosTrendData.every(
				(portfolioData) =>
					!portfolioData?.trendLine?.length ||
					(portfolioData.points && !portfolioData.points.length)
			)
		) {
			return [];
		}

		const timeSpanDate = getDateForTimeSpan(trendFilters.timeSpan);
		const data = portfoliosTrendData.map((portfolioData) => {
			return {
				portfolio: getTrendlineData(
					portfolioData?.trendLine ?? [],
					timeSpanDate
				),
				points: getScatterData(
					portfolioData?.points || [],
					trendFilters.metric
				),
			};
		});
		return data;
	}, [
		portfoliosTrendData,
		trendFilters.timeSpan,
		trendFilters.metric,
		isFetching,
	]);

	const legendData = useMemo(() => {
		return selectedPortfolioIds.map((_portfolioId, index) => {
			const portfolioData = chartData[index];
			return {
				hasData: portfolioData ? portfolioHasData(portfolioData) : false,
				name: portfolioNameById(portfoliosResults, index),
			};
		});
	}, [chartData, portfoliosResults, selectedPortfolioIds]);

	const filteredChartData = chartData.filter((portfolioData) =>
		portfolioHasData(portfolioData)
	);

	const allChartsAreBubble = filteredChartData.every(
		(portfolioDataset) =>
			portfolioDataset.points && portfolioDataset.points.length > 0
	);

	const noData = filteredChartData.length === 0;
	const allPortfoliosEmpty = useMemo(
		() => selectedPortfolioIds.length > 0 && noData,
		[selectedPortfolioIds, noData]
	);

	return (
		<BenchmarkingChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={!allPortfoliosEmpty}
		>
			<Filters
				filters={trendFilters}
				setMarketTrendFilters={setTrendFilters}
				isLoading={isFetching}
				isBubbleChart={allChartsAreBubble}
			/>

			<Chart
				data={chartData}
				noData={noData}
				metricFilter={trendFilters.metric}
				timeSpan={trendFilters.timeSpan}
				allChartsAreBubble={allChartsAreBubble}
				colors={BENCHMARKING_COLORS}
			/>
			<Legend portfoliosLegendData={legendData} colors={BENCHMARKING_COLORS} />

			<DownloadBranding bannerId={downloadBannerId} />
		</BenchmarkingChartStateWrapper>
	);
};

const TRENDS_TOOLTIP = (
	<>
		<Spacer>
			The Trends chart plots the moving averages of several portfolio lease
			metrics over time, for the portfolios selected for benchmarking.
		</Spacer>
		<Spacer>
			You can use the Metric selector to switch between Net Effective Rent,
			Starting Rent, Lease Term, Free Rent and Tenant Improvement Allowance.
		</Spacer>
		<Spacer>
			You can also select a timespan between one year and 15 years, and adjust
			the moving average period.
		</Spacer>
		<Spacer>
			<CalculationOverview context="charts" />
		</Spacer>
	</>
);
