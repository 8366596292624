import { UseQueryOptions, useQueries } from '@tanstack/react-query';
import { QUERY_KEYS } from 'api/constants';
import {
	PortfolioByIdParams,
	fetchPortfolioV2,
} from './usePortfolioByIdQueryV2';
import { composeEnabled } from 'api/utils';
import { PortfolioV2 } from 'api/types';

export const usePortfoliosByIdsQueriesV2 = (
	multiParams: PortfolioByIdParams[],
	options?: UseQueryOptions<PortfolioV2>
) => {
	return useQueries({
		queries: multiParams.map((params) => {
			return {
				queryKey: [QUERY_KEYS.PORTFOLIO_BY_ID_V2, params.id, params.filters],
				queryFn: () => fetchPortfolioV2(params),
				...options,
				enabled: composeEnabled(params.id != null, options?.enabled),
			};
		}),
	});
};
