import { CenterOnPoint, Map, MapInteractivity, MapStyle } from '@compstak/maps';
import { useSubmarketPolygons } from 'api/submarketPolygons/useSubmarketPolygons';
import {
	MapAnalyticsNoDataMessage,
	MapAnalyticsSettingsButton,
	MarketRentAnalyticsLayers,
	MarketRentAnalyticsLegend,
	MarketRentAnalyticsLegendContainer,
	MarketRentAnalyticsLegendVariant,
	MarketRentAnalyticsTooltip,
	useMarketRentAdjustedPercentiles,
	useMarketRentAnalyticsState,
} from 'maps';
import { useState, useMemo } from 'react';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { getIsMarketSupportedForMapAnalytics } from '../../maps';
import {
	ExchangeMapButtonsContainer,
	ExchangePanel,
	MapMessageContainer,
} from '../UI';
import { useDashboardMarketRentData } from '../useDashboardMarketRentData';
import { DATA_TEXT } from './constants';
import { MarketRentMapSettingsMenu } from './MarketRentMapSettingsMenu';
import { useMarketRentDescriptionText } from './useMarketRentDescriptionText';
import { DataNotAvailableMessage } from 'Components/DataNotAvailableMessage';
import IconMap from 'ui/svg_icons/map.svg';
import { propertyTypeFilterStatus } from 'ExchangeDashboard/utils/propertyTypeFilterStatus';

export const MarketRentMap = () => {
	const [marketRentAnalyticsState] = useMarketRentAnalyticsState();
	const { period, propertyType } = marketRentAnalyticsState;
	const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
	const [filters] = useFilters('exchange-dashboard');

	const marketId = filters.market.id;

	const filtersPropertyTypeId = filters.buildingPropertyTypeId;

	const { moreThanOnePropertyTypeSelected, incorrectPropertyTypeSelected } =
		propertyTypeFilterStatus(filtersPropertyTypeId);

	const { data: marketRentData } = useDashboardMarketRentData();

	const adjustedPercentiles = useMarketRentAdjustedPercentiles({
		percentiles: marketRentData?.percentiles ?? [],
	});

	const { data: submarketsData } = useSubmarketPolygons(filters.market);

	const isMarketSupportedForMapAnalytics =
		getIsMarketSupportedForMapAnalytics(marketId);

	const noData = marketRentData && marketRentData.percentiles.length === 0;

	const nothingToShow = !isMarketSupportedForMapAnalytics || noData;

	const canShowHeatMap =
		!nothingToShow &&
		!moreThanOnePropertyTypeSelected &&
		!incorrectPropertyTypeSelected;

	const descriptionText = useMarketRentDescriptionText(filters.market);

	const tooltipContent = (
		<>
			<div style={{ marginBottom: '0.5rem' }}>{descriptionText}</div>
			<div>{DATA_TEXT}</div>
		</>
	);

	const mapName = useMemo(
		() =>
			canShowHeatMap
				? `${propertyType} Market Rent Heat Map`
				: 'Market Rent Heat Map',
		[canShowHeatMap, propertyType]
	);

	const mapNoDataMessageTitle = moreThanOnePropertyTypeSelected
		? 'This Map Only Shows a Single Property Type'
		: incorrectPropertyTypeSelected
			? 'This Map Only Shows Industrial or Office Property Types'
			: 'No Data Available';
	const mapNoDataMessageDescription = moreThanOnePropertyTypeSelected
		? 'Please use the sidebar filters to select only one Property Type: Industrial or Office.'
		: incorrectPropertyTypeSelected
			? 'Please select either Industrial or Office Property Type using the Property Type filter in the sidebar.'
			: 'Try adjusting the filters in the sidebar to include more lease comps.';

	return (
		<ExchangePanel
			id="market-rent-heat-map"
			title={mapName}
			tooltip={tooltipContent}
		>
			<MapContainer>
				{isMarketSupportedForMapAnalytics ? (
					<Map
						interactivity={MapInteractivity.INTERACTIVE_NO_SCROLL_ZOOM}
						mapStyle={MapStyle.MONOCHROME}
						attributionProps={{
							position: 'top-right',
						}}
						logoPosition="top-left"
					>
						<CenterOnPoint
							latitude={filters.market.lat}
							longitude={filters.market.lon}
							zoom={filters.market.zoom}
						/>
						<MarketRentAnalyticsLayers
							data={marketRentData}
							adjustedPercentiles={adjustedPercentiles}
							submarketData={submarketsData}
							polygonsLayerProps={{
								highlightColor: undefined,
								getTooltipContent: (f) => (
									<MarketRentAnalyticsTooltip
										title={f.properties.submarket}
										marketRent={f.properties.marketRent}
										period={period}
									/>
								),
							}}
							hexagonLayerProps={{
								getTooltipContent: (f) => (
									<MarketRentAnalyticsTooltip
										marketRent={f.properties.marketRent}
										period={period}
									/>
								),
							}}
						/>
						{canShowHeatMap && (
							<MarketRentAnalyticsLegendContainer
								variant={MarketRentAnalyticsLegendVariant.SMALL}
							>
								<MarketRentAnalyticsLegend
									percentiles={adjustedPercentiles}
									variant={MarketRentAnalyticsLegendVariant.SMALL}
								/>
							</MarketRentAnalyticsLegendContainer>
						)}
						<ExchangeMapButtonsContainer>
							<MapAnalyticsSettingsButton
								isMenuOpen={isSettingsMenuOpen}
								onClick={() => setIsSettingsMenuOpen(!isSettingsMenuOpen)}
								data-html2canvas-ignore
							/>
						</ExchangeMapButtonsContainer>
						{isSettingsMenuOpen && <MarketRentMapSettingsMenu />}
						{!canShowHeatMap && (
							<MapMessageContainer>
								<MapAnalyticsNoDataMessage
									title={mapNoDataMessageTitle}
									description={mapNoDataMessageDescription}
								/>
							</MapMessageContainer>
						)}
					</Map>
				) : (
					<DataNotAvailableMessage
						icon={<IconMap />}
						title={'Map not available'}
						chartName={mapName}
					/>
				)}
			</MapContainer>
		</ExchangePanel>
	);
};

MarketRentMap.displayName = 'MarketRentMap';

const MapContainer = styled.div`
	position: relative;
	max-width: 100%;
	height: 500px;
	overflow: hidden;
`;
