import { PortfolioLeaseExpirationsMonths, PortfolioV2 } from 'api';
import { PortfolioLeasesFilters } from './PortfolioLeasesFiltersProvider';
import dayjs from 'dayjs';
import { LeaseFilters } from 'models/filters/lease';
import { FiltersObject } from 'models/filters/types';

export const getFiltersFromPortfolioLeasesFilters = ({
	filterBy,
	portfolio,
	activeLeasesOnly,
	leaseExpirationsMonths,
	...props
}: PortfolioLeasesFilters & {
	portfolio: PortfolioV2;
	leaseExpirationsMonths: PortfolioLeaseExpirationsMonths;
} & FiltersObject) => {
	const filters: Partial<LeaseFilters> = {
		...props,
		portfolioId: portfolio.portfolio.id,
		partial: 'false',
		sortField: 'executionQuarter',
		sortDirection: 'desc',
	};

	if (activeLeasesOnly && leaseExpirationsMonths) {
		filters.expirationDate = {
			min: new Date(),
			max: dayjs().add(leaseExpirationsMonths, 'month').toDate(),
			allowFallback: false,
		};
	} else if (activeLeasesOnly) {
		filters.expirationDate = {
			min: new Date(),
			max: null,
			allowFallback: false,
		};
	} else if (leaseExpirationsMonths) {
		filters.expirationDate = {
			min: null,
			max: dayjs().add(leaseExpirationsMonths, 'month').toDate(),
			allowFallback: true,
		};
	}

	if (filterBy) {
		const criterion = filterBy.criterion;
		const duration = filterBy.duration.value;
		const unit = filterBy.duration.unit;
		switch (criterion) {
			case 'newOnCompstak': {
				filters.dateCreated = {
					min: dayjs().subtract(duration, unit).toDate(),
					max: null,
				};
				break;
			}
			case 'recentlyUpdated': {
				filters.lastAttributeUpdate = {
					min: dayjs().subtract(duration, unit).toDate(),
					max: null,
				};
				break;
			}
		}
	}

	return filters;
};
