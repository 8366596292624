import { Market } from '@compstak/common';
import { FILTER_LABELS } from 'Components/Filters/Fields/filterLabels';
import { isArray, isDate, isObject } from 'lodash';
import {
	allFilters,
	FiltersObject,
	BooleanStringFiltersKeys,
	FilterDateInterval,
	FilterNumberInterval,
	FilterStringInterval,
	FiltersTypesKeys,
	FiltersValues,
	leasesFilters,
	mufaFilters,
	PolygonFilter,
	RadiusFilter,
	SubmarketsFilter,
	MultiPolygonFilter,
	APNFilter,
	CompIdFilter,
	DateTimestampFiltersKeys,
} from 'models/filters/types';
import { getSetFilterKeys, isSet } from 'models/filters/util/getSetFilters';
import { CompType } from 'types/comp';
import { CompFormatAttribute } from 'util/comp-format/attributes';
import { getAttributeHash } from 'util/comp-format/src/format';

const commercialChartbuilderSupportedFilters = Object.keys(leasesFilters);
const mufaChartbuilderSupportedFilters = Object.keys(mufaFilters);

export const booleanFiltersList: BooleanStringFiltersKeys[] = [
	'partial',
	'sublease',
	'isPortfolio',
];

const dateTimestampFiltersList: DateTimestampFiltersKeys[] = [
	'dateCreatedTimestamp',
];

export function getUnsupportedFilters(
	filters: FiltersObject,
	extraOptions?: { useMufaFilters?: boolean }
) {
	const useMufaFilters = extraOptions?.useMufaFilters ?? false;

	const supportedFilterKeys = useMufaFilters
		? mufaChartbuilderSupportedFilters
		: commercialChartbuilderSupportedFilters;

	const unsupportedFilters = getSetFilterKeys(filters).filter(
		(f) => !supportedFilterKeys.includes(f)
	);
	return unsupportedFilters;
}

export function getLabelForFilterAttribute({
	attribute,
	compType,
}: {
	attribute: string;
	compType: CompType;
}) {
	// @ts-expect-error TS7053: Element implicitly has an 'any...
	const labelFromOverride: string | undefined = FILTER_LABELS[attribute];
	if (typeof labelFromOverride === 'string') {
		return labelFromOverride;
	}

	const normalizedAttributeKey = attribute.endsWith('Id')
		? attribute.slice(0, -2)
		: attribute;

	const attributeInfo: CompFormatAttribute | undefined =
		// @ts-expect-error TS7053: Element implicitly has an 'any...
		getAttributeHash(compType)[normalizedAttributeKey];

	return attributeInfo?.displayName ?? normalizedAttributeKey;
}

export const isMarketFilter = (value: FiltersValues): value is Market => {
	return (
		isObject(value) && 'name' in value && 'id' in value && 'states' in value
	);
};

export const isCompIdFilter = (value: FiltersValues): value is CompIdFilter => {
	return (
		isObject(value) &&
		'value' in value &&
		Array.isArray(value.value) &&
		value.value.some((val) => typeof val === 'number') &&
		'exclude' in value &&
		typeof value.exclude === 'boolean'
	);
};

export const isMarketsFilter = (value: FiltersValues): value is Market[] => {
	// @ts-expect-error TS2345: Argument of type 'string | num...
	return isArrayFilter(value) && value.some((val) => isMarketFilter(val));
};

export const isSubmarketsFilter = (
	value: FiltersValues
): value is SubmarketsFilter => {
	return (
		isArrayFilter(value) &&
		value.some(
			(val) =>
				isObject(val) && 'name' in val && 'id' in val && !('states' in val)
		)
	);
};

export const isRadiusFilter = (value: FiltersValues): value is RadiusFilter => {
	return isObject(value) && 'center' in value && 'distance' in value;
};

export const isPolygonFilter = (
	value: FiltersValues
): value is PolygonFilter => {
	return (
		isArrayFilter(value) &&
		value.some((val) => isObject(val) && 'lng' in val && 'lat' in val)
	);
};

export const isMultiPolygonFilter = (
	value: FiltersValues
): value is MultiPolygonFilter => {
	return (
		isArrayFilter(value) &&
		value.some((val) => Array.isArray(val) && isPolygonFilter(val))
	);
};

export const isAPNFilter = (value: FiltersValues): value is APNFilter => {
	return isObject(value) && 'fips' in value && 'apn' in value;
};

export const isStringIntervalFilterValue = (
	value: FiltersValues
): value is FilterStringInterval => {
	return (
		hasIntervalFilterStructure(value) &&
		(typeof value.min === 'string' ||
			typeof value.max === 'string' ||
			'allowFallback' in value)
	);
};

export const isDateIntervalFilterValue = (
	value: FiltersValues
): value is FilterDateInterval => {
	return (
		hasIntervalFilterStructure(value) &&
		(isDate(value.min) || isDate(value.max) || 'allowFallback' in value)
	);
};

export const isNumberIntervalFilterValue = (
	value: FiltersValues
): value is FilterNumberInterval => {
	return (
		hasIntervalFilterStructure(value) &&
		(typeof value.min === 'number' ||
			typeof value.max === 'number' ||
			'allowFallback' in value)
	);
};

export const isIntervalFilterValue = (
	value: FiltersValues
): value is
	| FilterNumberInterval
	| FilterDateInterval
	| FilterStringInterval => {
	return hasIntervalFilterStructure(value);
};

export const isArrayFilter = (value: FiltersValues): value is any[] => {
	return isArray(value);
};

export const isBooleanStringFilter = (
	key: FiltersTypesKeys
): key is BooleanStringFiltersKeys => {
	return booleanFiltersList.includes(key as BooleanStringFiltersKeys);
};

export const isFilterKey = (key: string): key is keyof FiltersObject =>
	key in allFilters;

export const isDateTimestampFilterKey = (
	key: FiltersTypesKeys
): key is DateTimestampFiltersKeys => {
	return dateTimestampFiltersList.includes(key as DateTimestampFiltersKeys);
};

const hasIntervalFilterStructure = (
	value: FiltersValues
): value is
	| FilterStringInterval
	| FilterNumberInterval
	| FilterDateInterval => {
	return (
		isObject(value) &&
		('min' in value || 'max' in value || 'allowFallback' in value)
	);
};

const NESTED_FILTERS: Partial<Record<FiltersTypesKeys, FiltersTypesKeys[]>> = {
	buildingPropertyTypeId: ['buildingPropertySubtype'],
	spaceTypeId: ['spaceSubtypeId'],
	fips: ['apn'],
	insideviewOwnershipId: ['insideviewTickers'],
};

export const onNestedFilterChange = (
	attribute: FiltersTypesKeys,
	change: Partial<FiltersObject>,
	onFilterChange: (change: Partial<FiltersObject>) => void,
	nestedFilters?: Partial<Record<FiltersTypesKeys, FiltersTypesKeys[]>>
) => {
	const resetNestedFilters = change[attribute] == null;

	change = resetNestedFilters
		? {
				...change,
				...(NESTED_FILTERS[attribute] ?? nestedFilters?.[attribute])?.reduce(
					(acc, nestedFilterKey) => ({
						...acc,
						[nestedFilterKey]: null,
					}),
					{}
				),
			}
		: change;

	return onFilterChange(change);
};

export const isNestedFilterSet = (
	attribute: FiltersTypesKeys,
	filters: Partial<FiltersObject>,
	nestedFilters?: Partial<Record<FiltersTypesKeys, FiltersTypesKeys[]>>
) => {
	return (NESTED_FILTERS[attribute] ?? nestedFilters?.[attribute])?.some(
		(nestedFilter) => {
			return isSet(filters[nestedFilter], nestedFilter);
		}
	);
};

export const getSetFilterKeysExceptMarkets = (
	filters: Partial<FiltersObject>
) => {
	const {
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		market,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		markets,
		...filtersToCount
	} = filters;
	return getSetFilterKeys(filtersToCount);
};
