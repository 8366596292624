import { SpaceTypeName } from 'api';
import React from 'react';
import { DistributionMonths } from '../../../Pages/PropertyPageV2_1/api';

export type SpaceTypeItem = {
	value: SpaceTypeName;
	title: SpaceTypeName;
};

export type MonthsItem = {
	value: DistributionMonths;
	title: string;
};

export const chartYAxisLabel = 'Total Transaction SqFt';

export const barsData = [
	{
		value: 'transactionSizeSum',
		title: chartYAxisLabel,
		isIconHidden: false,
		type: 'monotone',
	},
];

export const spaceTypeItems: Array<SpaceTypeItem> = [
	{ title: 'Industrial', value: 'Industrial' },
	{ title: 'Office', value: 'Office' },
	{ title: 'Retail', value: 'Retail' },
	{ title: 'Flex/R&D', value: 'Flex/R&D' },
	{ title: 'Land', value: 'Land' },
	{ title: 'Other', value: 'Other' },
];

export const monthsItems: Array<MonthsItem> = [
	{ title: '1 year', value: 12 },
	{ title: '3 years', value: 36 },
	{ title: '5 years', value: 60 },
	{ title: '10 years', value: 120 },
	{ title: '15 years', value: 180 },
];

export const tooltipContent = (
	<>
		Distribution of&nbsp;selected metric across this property&rsquo;s submarket
		within a&nbsp;specified timespan.
	</>
);

export const headersMapping = {
	name: 'Value',
	transactionSizeSum: 'Transaction Size Sum',
};
