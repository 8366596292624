import {
	ClusterLayer,
	Map,
	MapInteractivity,
	MapRef,
	MapStyle,
} from '@compstak/maps';
import { point } from '@turf/helpers';
import { usePortfolioPropertiesQuery } from 'api';
import { MapButtonsContainer, MapSpinner, MapStyleButton } from 'maps';
import { useMemo, useState } from 'react';
import { usePortfolioFilters } from '../PortfolioFiltersProvider';
import { PortfolioPopup } from './PortfolioPopup';
import { PortfolioByIdRouteSearchParams } from 'router';
import { CenterOnMarketsEffect } from 'Pages/Search/Map/Components/MapEffects/CenterOnMarketsEffect';
import { useMarkets } from 'hooks/useMarkets';
import { usePortfolioByIdQueryV2 } from 'api/portfolio/portfolioById/usePortfolioByIdQueryV2';
import { MAP_VIEW_INCLUDE_FIELDS } from 'api/portfolio/constants';

type PortfolioMapProps = {
	onMapRef?: (mapRef: MapRef) => void;
	isMapView?: boolean;
	portfolioId: number;
	searchParams?: PortfolioByIdRouteSearchParams;
};

export const PortfolioMap = ({
	onMapRef,
	isMapView,
	portfolioId,
	searchParams,
}: PortfolioMapProps) => {
	const [mapStyle, setMapStyle] = useState(MapStyle.DEFAULT);
	const { filters } = usePortfolioFilters();
	const { data: portfolioV2 } = usePortfolioByIdQueryV2({
		id: portfolioId,
		filters,
	});
	const markets = useMarkets();

	const { data: properties, isFetching: isFetchingPortfoliosProperties } =
		usePortfolioPropertiesQuery({
			portfolioId,
			filters: {
				...filters,
				...searchParams,
			},
			includeFields: MAP_VIEW_INCLUDE_FIELDS,
		});

	const propertyPoints = useMemo(() => {
		if (!properties) return [];
		return properties.map((p) => {
			const { lon, lat } = p.geoPoint;
			return point([lon, lat], p);
		});
	}, [properties]);

	const portfolioMarkets = useMemo(() => {
		if (!portfolioV2) {
			return [];
		}

		return portfolioV2.markets.map(({ marketId }) => markets[marketId]);
	}, [portfolioV2, markets]);

	return (
		<Map
			onMapRef={onMapRef}
			interactivity={
				isMapView
					? MapInteractivity.INTERACTIVE
					: MapInteractivity.INTERACTIVE_NO_SCROLL_ZOOM
			}
			mapStyle={mapStyle}
			logoPosition="top-left"
		>
			{isFetchingPortfoliosProperties && <MapSpinner />}
			<CenterOnMarketsEffect markets={portfolioMarkets} />
			<ClusterLayer
				data={propertyPoints}
				circlesLayerProps={{ billboard: true }}
				pinsLayerProps={{
					getTooltipContent: (p) => `${p.properties.buildingAddressAndCity}`,
					getPopupContent(p, closePopup) {
						return (
							<PortfolioPopup
								closePopup={closePopup}
								isMinified={!isMapView}
								propertyId={p.properties.id}
								portfolioV2={portfolioV2!}
							/>
						);
					},
				}}
			/>
			<MapButtonsContainer>
				<MapStyleButton
					mapStyle={mapStyle}
					onClick={() => {
						setMapStyle(
							mapStyle === MapStyle.DEFAULT
								? MapStyle.SATELLITE
								: MapStyle.DEFAULT
						);
					}}
				/>
			</MapButtonsContainer>
		</Map>
	);
};
