import React from 'react';
import * as Styled from './styled';
import { styled } from 'styled-components';

import ArrowBottom from '../../../../ui/svg_icons/arrow_bottom.svg';
import useOnClickOutside from '../../../../util/useOnClickOutside';
import { MQB } from '@compstak/ui-kit';

export const DropdownContainer = styled.div`
	display: flex;
	gap: 0.5rem;
	@media only screen and (min-width: ${MQB.D_1536}) {
		gap: 0.75rem;
	}
`;

export type DropdownItem<T extends number | string> = {
	id: T;
	value: string;
	icon?: React.ReactNode;
};

type Props<T extends number | string> = {
	activeItemId: null | DropdownItem<T>['id'];
	items: Array<DropdownItem<T>>;
	onSelect: (itemId: DropdownItem<T>['id']) => void;
	title?: string;
	width?: number;
	displayArrow?: boolean;
	dataQaId?: string;
};

const Dropdown = <T extends number | string>({
	activeItemId,
	items,
	onSelect,
	title,
	width,
	displayArrow,
	dataQaId,
}: Props<T>) => {
	const dropdownMenuRef = React.useRef<HTMLDivElement>(null);
	const [isOpen, setIsOpen] = React.useState(false);

	const close = () => setIsOpen(false);

	const handleClick = () => setIsOpen((stateIsOpen) => !stateIsOpen);

	useOnClickOutside(dropdownMenuRef, close);

	React.useEffect(() => {
		return close;
	}, []);

	const onSelectItem = (item: DropdownItem<T>) => {
		const itemId = item.id;
		if (activeItemId !== itemId) {
			onSelect(itemId);
		}
		close();
	};

	const activeItem = items.find((item) => item.id === activeItemId);
	const activeItemLabel = activeItem ? (
		<DropDownLabel>
			{' '}
			{activeItem.icon} {activeItem.value}
		</DropDownLabel>
	) : (
		''
	);

	const dataQaIdString = dataQaId ? dataQaId : 'qa-id-7548393';

	return (
		<Styled.Wrapper width={width}>
			<Styled.Selector
				ref={dropdownMenuRef}
				id="selector"
				data-qa-id={dataQaIdString}
				onClick={handleClick}
			>
				<Styled.SelectorContentWrapper>
					{title && <Styled.SelectorTitle>{title}</Styled.SelectorTitle>}
					<Styled.SelectorLabel>{activeItemLabel}</Styled.SelectorLabel>
				</Styled.SelectorContentWrapper>
				{displayArrow && (
					<Styled.SelectorIconWrapper isOpen={isOpen}>
						<ArrowBottom width={10} />
					</Styled.SelectorIconWrapper>
				)}
			</Styled.Selector>
			{isOpen && (
				<Styled.ListWrapper>
					{items.map((item) => (
						<Styled.ListItem
							data-qa-id="qa-id-8585859590"
							key={item.id}
							onClick={() => onSelectItem(item)}
						>
							<Styled.ListItemText active={activeItemId === item.id}>
								{item.value}
							</Styled.ListItemText>
						</Styled.ListItem>
					))}
				</Styled.ListWrapper>
			)}
		</Styled.Wrapper>
	);
};

const DropDownLabel = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	column-gap: 0.375em;
	padding: 0 0.25em;
	font-size: 0.875em;
	line-height: 1.25em;
	height: 1.25em;
`;

export default Dropdown;
