import { MQB, Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQueryV2 } from 'api/portfolio/portfolioById/usePortfolioByIdQueryV2';
import { RefCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { usePortfolioFilters } from '../../PortfolioFiltersProvider';
import { PortfolioMap } from '../../PortfolioMap';
import { PortfolioTrendChart } from 'PortfolioAnalytics/Charts/PortfolioTrend/PortfolioTrendChart';
import { DownloadBranding } from '../../Singletons/DownloadBranding';
import {
	DashboardCardLoadingContainer,
	PortfolioOverviewSectionHeader,
} from '../UI';
import { AveragesV2 } from './AveragesV2';
import { MapRef } from 'react-map-gl';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PortfolioStats } from './PortfolioStats';

type Props = {
	portfolioId: number;
};

export const PortfolioOverviewV2 = ({ portfolioId }: Props) => {
	const { portfolioRevampPhase6_5 } = useFeatureFlags();

	const mainRef = useRef<HTMLDivElement | null>(null);
	const [mapRef, setMapRef] = useState<MapRef | null>(null);

	const onChartRef: RefCallback<HTMLElement> = (chartRef) => {
		// resize the map when chart mounts to prevent the map being cut off
		if (chartRef && mapRef && mapRef.getCanvas()) {
			mapRef.resize();
		}
	};

	const { filters } = usePortfolioFilters();

	const { data: portfolioV2, isFetching } = usePortfolioByIdQueryV2({
		id: portfolioId,
		filters,
	});

	if (!portfolioV2 || isFetching)
		return (
			<Root>
				<DashboardCardLoadingContainer>
					<Spinner />
				</DashboardCardLoadingContainer>
			</Root>
		);

	const title = `portfolio overview`;

	const downloadBannerId = `portfolio-${title.replaceAll(' ', '_')}`;

	return (
		<Root ref={mainRef}>
			{portfolioRevampPhase6_5 && (
				<PortfolioOverviewSectionHeader>
					Portfolio Overview
				</PortfolioOverviewSectionHeader>
			)}
			<MainContent>
				<MapContainer>
					<PortfolioMap portfolioId={portfolioId} onMapRef={setMapRef} />
				</MapContainer>
				<TrendContainer>
					<PortfolioTrendChart
						portfolioV2={portfolioV2}
						filters={filters}
						onChartRef={onChartRef}
					/>
				</TrendContainer>
			</MainContent>
			{portfolioRevampPhase6_5 ? (
				<PortfolioStats portfolio={portfolioV2} />
			) : (
				<AveragesV2 portfolioV2={portfolioV2} />
			)}
			<DownloadBranding bannerId={downloadBannerId} />
		</Root>
	);
};

const Root = styled.div`
	box-sizing: border-box;
`;

const MainContent = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	gap: 1rem;
	margin-bottom: 1rem;
	@media (min-width: ${MQB.D_1280}) {
		min-height: 300px;
	}
	@media (min-width: ${MQB.D_1280}) {
		grid-template-columns: repeat(2, 1fr);
	}
`;

const TrendContainer = styled.div`
	background-color: ${({ theme }) => theme.colors.white.white};
	border-radius: 8px;
	border: 1px solid ${(p) => p.theme.colors.gray.gray100};
	box-shadow: 0px 1.489px 1.985px 0px rgba(16, 24, 40, 0.1);
	overflow: hidden;
	min-height: 300px;
	@media (min-width: ${MQB.D_1536}) {
		min-height: 350px;
	}
	@media (min-width: ${MQB.D_1920}) {
		min-height: 400px;
	}
	@media (min-width: ${MQB.D_2560}) {
		min-height: 620px;
	}
`;

const MapContainer = styled.div`
	overflow: hidden;
	border-radius: 8px;
	min-height: 300px;
	@media (min-width: ${MQB.D_1536}) {
		min-height: 350px;
	}
	@media (min-width: ${MQB.D_1920}) {
		min-height: 400px;
	}
	@media (min-width: ${MQB.D_2560}) {
		min-height: 620px;
	}
`;
