import { useEffect } from 'react';
import {
	SEARCH_BY_VIEW_ROUTES,
	SearchRouteSearchParams,
	replace,
} from 'router';
import {
	filtersFromServerJSON,
	filtersToQueryString,
} from 'models/filters/util';
import { CompType } from 'types';
import { useMarkets } from 'hooks/useMarkets';
import { Spinner } from '@compstak/ui-kit';
import { mergeFilters } from 'models/filters/util/mergeFilters';
import { RouteComponentProps } from 'router/migration/types';
import { useUserQuery } from 'api';
import { useSavedSearchesQuery } from 'api/savedSearches/useSavedSearchesQuery';
import { useViewSavedSearchMutation } from 'api/savedSearches/useViewSavedSearchMutation';
import { useSavedSearchContext } from 'Components/SavedSearches/SavedSearchProvider';
import dayjs from 'dayjs';

type SearchRedirectProps = RouteComponentProps<{}, SearchRouteSearchParams> & {
	route: { compType: CompType };
};

const DEFAULT_VIEW = 'list';

export const SearchRedirectV2 = ({
	route: { compType },
	location,
}: SearchRedirectProps) => {
	const savedSearchId = location.query.savedSearchId;
	const dateCreatedMin = location.query.dateCreatedMin;
	const dateCreatedMax = location.query.dateCreatedMax;

	const { data: savedSearches } = useSavedSearchesQuery();
	const { data: user } = useUserQuery();
	const markets = useMarkets();

	const { mutate: viewSavedSearch } = useViewSavedSearchMutation();

	const { setSavedSearch } = useSavedSearchContext();

	useEffect(() => {
		if (!savedSearches) return;

		const savedSearch = savedSearches?.find(({ id }) => id === savedSearchId);

		if (savedSearch) {
			setSavedSearch(savedSearch);

			let filters = filtersFromServerJSON(
				compType,
				markets,
				savedSearch.filters
			);
			filters = mergeFilters(filters, {
				sortDirection: savedSearch.sortAsc ? 'asc' : 'desc',
				sortField: savedSearch.sortProperty,
				...(dateCreatedMin || dateCreatedMax
					? {
							dateCreated: {
								min: dateCreatedMin ? dayjs(dateCreatedMin).toDate() : null,
								max: dateCreatedMax ? dayjs(dateCreatedMax).toDate() : null,
							},
						}
					: {}),
			});
			const queryString = filtersToQueryString(filters);

			viewSavedSearch({ id: savedSearch.id });

			const compTypeToRedirectTo =
				(dateCreatedMin || dateCreatedMax) && compType === 'property'
					? 'lease'
					: compType;

			replace(
				`${SEARCH_BY_VIEW_ROUTES[compTypeToRedirectTo].toHref({ view: DEFAULT_VIEW })}?${queryString}`
			);

			return;
		}

		replace(
			`${SEARCH_BY_VIEW_ROUTES[compType].toHref({ view: DEFAULT_VIEW })}${location.search}`
		);
	}, [
		savedSearchId,
		dateCreatedMin,
		dateCreatedMax,
		savedSearches,
		user,
		location.pathname,
		location.search,
		compType,
		markets,
		viewSavedSearch,
		setSavedSearch,
	]);

	return <Spinner size="xl" isCentered />;
};
