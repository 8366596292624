import React, { RefCallback } from 'react';
import {
	VictoryAxis,
	VictoryChart,
	VictoryLabel,
	VictoryLine,
	VictoryScatter,
	VictoryArea,
	VictoryTooltip,
	VictoryVoronoiContainer,
} from 'victory';

import { PortfolioTrendChartFlyout } from './PortfolioTrendChartFlyout';
import {
	TrendMetricFilter,
	Bubbles,
} from 'PortfolioAnalytics/portfolioAnalyticsTypes';
import { formatDate } from '../util';
import { useTheme } from 'styled-components';

export type PortfolioTrendChartData = {
	portfolio: PortfolioChartData[];
	points: Bubbles[];
};

type PortfolioChartData = { x: Date; y: number };

type PortfolioTrendChartProps = {
	data: PortfolioTrendChartData;
	noData: boolean;
	metricFilter: TrendMetricFilter;
	timeSpan: number;
	isBubbleChart: boolean;
	onChartRef: RefCallback<HTMLElement>;
};

export const PortfolioTrendChartComponent = ({
	data,
	noData,
	metricFilter,
	timeSpan,
	isBubbleChart,
	onChartRef,
}: PortfolioTrendChartProps) => {
	const axisStyle = {
		fontSize: 11,
		fontFamily: 'Gotham, sans-serif',
		opacity: noData ? 0 : 1,
		color: '#667085',
		textAlign: 'right',
		fontWeight: '400',
		lineHeight: '1.25rem',
		letterSpacing: '0.06px',
	};

	const shouldFormatDateAsYear = React.useMemo(() => {
		if (isBubbleChart) return true;
		const dates = data.portfolio.map(({ x }) => x.getFullYear());
		const firstYear = Math.min(...dates);
		const lastYear = Math.max(...dates);
		return lastYear - firstYear > 3;
	}, [data.portfolio, isBubbleChart]);

	const minY = Math.min(...data.portfolio.map((d) => d.y));
	const maxY = Math.max(...data.portfolio.map((d) => d.y));
	const maxYBubble = Math.max(...data.points.map((d) => d.y));
	// adding a buffer to the lowest and highest y axis points
	const yAxisMin = isBubbleChart ? 0 : minY * 0.98;
	const yAxisMax = isBubbleChart ? maxYBubble * 1.1 : maxY * 1.02;
	const chartWidth = 600;
	const leftPadding = isBubbleChart ? 60 : 40;
	const rightPadding = 20;
	const yAxisLabelMargin = isBubbleChart ? -20 : 0;
	const isMonthsMetric = ['freeMonths', 'leaseTerm'].includes(metricFilter);
	const tickerCount = timeSpan === 5 ? 6 : 12;

	const theme = useTheme();
	const { purple700 } = theme.colors.purple;

	return (
		<div ref={onChartRef}>
			<VictoryChart
				domainPadding={2}
				padding={{
					top: 10,
					bottom: 30,
					left: leftPadding,
					right: rightPadding,
				}}
				height={320}
				width={chartWidth}
				scale={{ x: 'time' }}
				containerComponent={
					<VictoryVoronoiContainer
						voronoiDimension="x"
						labels={() => ' '}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={
									<PortfolioTrendChartFlyout
										isMonthsMetric={isMonthsMetric}
										width={220}
										metricFilter={metricFilter}
										chartWidth={chartWidth}
									/>
								}
							/>
						}
					/>
				}
			>
				<VictoryAxis
					tickLabelComponent={<VictoryLabel style={axisStyle} />}
					tickCount={tickerCount}
					tickFormat={(d: Date) =>
						formatDate(d, shouldFormatDateAsYear, isBubbleChart)
					}
					style={{
						axis: { stroke: 'none' },
						grid: { stroke: isBubbleChart ? '#F1F2F5' : 'none' },
					}}
				/>
				<VictoryAxis
					dependentAxis
					domain={[yAxisMin, yAxisMax]}
					tickLabelComponent={
						<VictoryLabel dx={yAxisLabelMargin} style={axisStyle} />
					}
					tickFormat={(m: number) => {
						return m > 0 ? (isMonthsMetric ? `${m} mo.` : `$${m}`) : m;
					}}
					style={{
						axis: { stroke: 'none' },
						grid: { stroke: noData ? 'none' : '#F1F2F5' },
					}}
				/>

				<defs>
					<linearGradient
						id="portfolioGradient"
						x1="345.844"
						y1="-55.7836"
						x2="345.844"
						y2="198.165"
						gradientUnits="userSpaceOnUse"
					>
						<stop style={{ stopColor: purple700 }} />
						<stop offset="1" style={{ stopColor: 'white', stopOpacity: '0' }} />
					</linearGradient>
					<linearGradient id="bubbleGradient" x1="0" y1="0" x2="0" y2="1">
						<stop offset="0%" stopColor={purple700} stopOpacity="1" />
						<stop offset="100%" stopColor={purple700} stopOpacity="0.5" />
					</linearGradient>
				</defs>
				{!isBubbleChart && (
					<VictoryArea
						data={data.portfolio}
						style={{
							data: {
								fill: 'url(#portfolioGradient)',
							},
						}}
					/>
				)}
				{!isBubbleChart && (
					<VictoryLine
						data={data.portfolio}
						style={{ data: { stroke: `${purple700}` } }}
					/>
				)}
				{isBubbleChart && (
					<VictoryScatter
						data={data.points}
						style={{
							data: {
								stroke: `${purple700}`,
								strokeWidth: '1px',
								fill: 'url(#bubbleGradient)',
								opacity: 0.7,
							},
						}}
					/>
				)}
			</VictoryChart>
		</div>
	);
};
