import { usePortfolioPerformanceQueries } from 'api/portfolio/charts/usePortfolioPerformanceQuery';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { useMemo } from 'react';
import {
	VictoryChart,
	VictoryAxis,
	VictoryBar,
	VictoryGroup,
	VictoryLabel,
	VictoryContainer,
	VictoryLabelProps,
} from 'victory';
import { BENCHMARKING_COLORS } from '../Benchmarking/constants';
import { portfolioNameById } from 'PortfolioAnalytics/Benchmarking/utils';
import { colors } from '@compstak/ui-kit';
import { usePortfoliosByIdsQueriesV2 } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueriesV2';
import { ChartLegendV2 as Legend } from '../Benchmarking/BenchmarkingCharts/ChartLegend';

import { MQB } from '@compstak/ui-kit';
import { useMediaQuery } from 'react-responsive';
import { BenchmarkingChartStateWrapper } from '../Benchmarking/BenchmarkingCharts/BenchmarkingChartStatusWrapper';

const GROUPS = {
	highPerformanceLowRisk: 'High Performance, Low Risk',
	lowPerformanceLowRisk: 'Low Performance, Low Risk',
	highPerformanceHighRisk: 'High Performance, High Risk',
	lowPerformanceHighRisk: 'Low Performance, High Risk',
};

const groups = Object.values(GROUPS);

const { n4, gray400, gray200 } = colors.gray;
const { n300 } = colors.neutral;

const axisStyle = {
	fill: n4,
	textAlign: 'right',
	fontSize: 11,
	fontWeight: 300,
	lineHeight: 11,
};

export const PERFORMANCE_DIST_CHART_TYPE = {
	singlePortfolio: 'singlePortfolio',
	benchmarking: 'benchmarking',
} as const;

type PerformanceDistributionChartType =
	keyof typeof PERFORMANCE_DIST_CHART_TYPE;

type PerformanceDistributionChartProps = {
	portfolioIds: number[];
	chartType?: PerformanceDistributionChartType;
};

const axisLabelStyle = {
	fill: n300,
	fontSize: '0.6em',
	fontWeight: 400,
	letterSpacing: '0.3px',
	fontFamily: 'Gotham, sans-serif',
};

const CustomAxisLabel: React.FC<VictoryLabelProps> = (props) => {
	const { x = 0, y = 0 } = props;
	return (
		<g transform={`translate(${x + 142}, ${y - 10})`}>
			<rect
				x={-15}
				y={-6}
				width={50}
				height={16}
				rx={3}
				ry={3}
				fill={gray200}
			/>
			<text x={10} y={5} style={axisLabelStyle} textAnchor="middle">
				Count
			</text>
		</g>
	);
};

export const PerformanceDistributionChartContent = (
	props: PerformanceDistributionChartProps
) => {
	const { portfolioIds, chartType = PERFORMANCE_DIST_CHART_TYPE.benchmarking } =
		props;
	const is1920 = useMediaQuery({ minWidth: MQB.D_1920 });
	const is1536 = useMediaQuery({ minWidth: MQB.D_1536 });

	const chartConfig = useMemo(() => {
		return {
			barWidth: chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking ? 7 : 20,
			chartWidth:
				chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking
					? '100%'
					: is1536
						? '550px'
						: is1920
							? '650px'
							: '450px',
			chartHeight:
				chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking
					? '100%'
					: '400px',
			xAxisGrid:
				chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking
					? { stroke: 'transparent' }
					: { stroke: gray400 },
		};
	}, [chartType, is1920, is1536]);

	const { filters } = usePortfolioFilters();
	const performanceData = usePortfolioPerformanceQueries(portfolioIds, filters);

	const portfoliosResults = usePortfoliosByIdsQueriesV2(
		portfolioIds.map((id) => ({ id, filters }))
	);

	const legendData = useMemo(() => {
		return chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking
			? portfolioIds.map((_portfolioId, index) => {
					const portfolioData = performanceData[index];
					return {
						hasData: Boolean(
							portfolioData?.data?.values &&
								portfolioData.data.values.length > 0
						),
						name: portfolioNameById(portfoliosResults, index),
					};
				})
			: [];
	}, [performanceData, portfoliosResults, portfolioIds, chartType]);

	const isError =
		performanceData.some((portfolioData) => portfolioData.isError) ||
		portfoliosResults.some((result) => result.isError);
	const isFetching =
		performanceData.some((portfolioData) => portfolioData.isFetching) ||
		portfoliosResults.some((result) => result.isFetching);

	const barData = useMemo(() => {
		return performanceData
			.map((portfolioData, index) => {
				if (!portfolioData.data?.values) return [];
				const portfolioId = portfolioIds[index];
				const groupCounts = groups.map((group) => {
					if (!portfolioData.data.values) return null;
					return {
						group,
						count: portfolioData.data.values.filter((property) => {
							if (group === GROUPS.highPerformanceLowRisk)
								return property.risk <= 0.5 && property.performance >= 0;
							if (group === GROUPS.highPerformanceHighRisk)
								return property.risk > 0.5 && property.performance >= 0;
							if (group === GROUPS.lowPerformanceLowRisk)
								return property.risk <= 0.5 && property.performance < 0;
							if (group === GROUPS.lowPerformanceHighRisk)
								return property.risk > 0.5 && property.performance < 0;
							return false;
						}).length,
						portfolioId,
						color: BENCHMARKING_COLORS[index],
					};
				});
				return groupCounts.filter((group) => group !== null);
			})
			.filter((portfolioData) =>
				portfolioData.some((data) => data && data.count > 0)
			)
			.reverse();
	}, [performanceData, portfolioIds]);

	const noData = useMemo(
		() =>
			portfolioIds.length === 0 ||
			barData.every((bars) => bars.every((bar) => bar?.count === 0)),
		[portfolioIds, barData]
	);

	const allPortfoliosEmpty = useMemo(
		() => !!portfolioIds.length && noData,
		[portfolioIds, noData]
	);

	const maxCount = !noData
		? Math.max(...barData.flat().map((d) => d?.count || 0))
		: 0;

	return (
		<BenchmarkingChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={
				chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking &&
				!allPortfoliosEmpty
			}
		>
			<VictoryChart
				containerComponent={
					<VictoryContainer
						style={{
							margin: '0 auto',
							width: chartConfig.chartWidth,
							height: chartConfig.chartHeight,
						}}
					/>
				}
				domainPadding={{ x: 20, y: 100 }}
				padding={{
					top: 10,
					bottom: 30,
					left: 160,
					right: 60,
				}}
				height={230}
				maxDomain={{ y: maxCount + 1 }}
			>
				{/* X Axis */}
				<VictoryAxis
					tickLabelComponent={<VictoryLabel style={axisStyle} />}
					style={{
						axis: { stroke: gray400 },
						grid: chartConfig.xAxisGrid,
					}}
				/>
				{/* Y Axis */}
				<VictoryAxis
					dependentAxis
					crossAxis={false}
					tickLabelComponent={<VictoryLabel style={axisStyle} />}
					label={() => ''}
					axisLabelComponent={<CustomAxisLabel />}
					style={{
						axis: { stroke: gray400 },
						grid: { stroke: gray400 },
					}}
					tickCount={5}
				/>
				<VictoryGroup horizontal offset={7}>
					{barData.reverse().map((portfolio, index) => (
						<VictoryBar
							horizontal
							key={index}
							data={portfolio.reverse()}
							x="group"
							y="count"
							style={{
								data: { fill: portfolio[0]?.color || gray400 },
							}}
							barWidth={chartConfig.barWidth}
						/>
					))}
				</VictoryGroup>
			</VictoryChart>
			{chartType === PERFORMANCE_DIST_CHART_TYPE.benchmarking && (
				<Legend
					portfoliosLegendData={legendData}
					colors={BENCHMARKING_COLORS}
				/>
			)}
		</BenchmarkingChartStateWrapper>
	);
};
