import * as Tabs from '@radix-ui/react-tabs';
import styled from 'styled-components';
import { PortfolioOptionsButtonV2 } from '../../PortfolioOptions/PortfolioOptionsButtonV2';
import { PortfolioFiltersV2 } from './Filters/PortfolioFiltersV2';
import IconExportBlue from 'ui/svg_icons/export_blue.svg';
import IconShareBlue from 'ui/svg_icons/share_icon_blue.svg';
import IconPlusVeryLight from 'ui/svg_icons/plus_very_light.svg';
import { PortfolioStatsV2 } from '../../Components/PortfolioStatsV2';
import { exportPortfolio } from 'PortfolioAnalytics/Export/exportPortfolio';
import { Button, MQB, Flex } from '@compstak/ui-kit';
import { routes, useNavigate } from 'router';
import { PortfolioTabEnum } from '../Dashboard';
import { useModal } from 'providers/ModalProvider';
import { DataRequestDialog } from 'PortfolioAnalytics/modals/DataRequestDialog';
import { ShareWithColleagueModal } from 'Components/Modals/ShareWithColleagueModal/ShareWithColleagueModal';
import { useSharePortfolioMutation } from 'api/portfolio/sharedPortfolio/useSharePortfolioMutation';
import { AddNewPropertyButton } from './UI';
import { useNewLeasesInPortfolio } from 'PortfolioAnalytics/PortfolioSidebar/useNewLeasesInPortfolio';
import {
	PageTitleWrap,
	PageTitle,
	PageTitleRow,
	PageTitleRowLeft,
	PageTitleRowRight,
	PageTitleRowRightButtons,
	PageTitleRowIconButton,
} from 'PortfolioAnalytics/styles/PortfolioUI';
import { isPortfolioEmpty as _isPortfolioEmpty } from 'PortfolioAnalytics/utils/isPortfolioEmpty';
import { PortfolioV2 } from 'api';

type Props = {
	portfolio: PortfolioV2;
	dashboardElement: HTMLElement | null;
	tab: PortfolioTabEnum;
};

export const DashboardHeaderV2 = ({
	portfolio,
	dashboardElement,
	tab,
}: Props) => {
	const isPortfolioEmpty = _isPortfolioEmpty(portfolio);
	const portfolioId = portfolio.portfolio.id;

	const { openModal, closeModal } = useModal();
	const navigate = useNavigate();
	const { mutate: sharePortfolio } = useSharePortfolioMutation();

	const { viewPortfolio, isNotificationShown } = useNewLeasesInPortfolio(
		{
			portfolioId,
		},
		{
			enabled: !isPortfolioEmpty,
		}
	);

	const { name: portfolioName, description: portfolioDescription } =
		portfolio.portfolio;

	const hasDescription =
		typeof portfolioDescription === 'string' && portfolioDescription.length > 0;

	return (
		<>
			<Container>
				<PageTitleRow>
					<PageTitleRowLeft>
						<PageTitleAndDiscriptionContainer>
							<PageTitleWrap>
								<PageTitle>{portfolioName}</PageTitle>
								<PortfolioOptionsButtonV2 portfolioV2={portfolio} />
							</PageTitleWrap>
							{hasDescription && (
								<Description>{portfolioDescription}</Description>
							)}
						</PageTitleAndDiscriptionContainer>
						<PortfolioFiltersV2 disabled={isPortfolioEmpty} />
					</PageTitleRowLeft>
					<PageTitleRowRight>
						<PageTitleRowRightButtons>
							<PageTitleRowIconButton
								variant="ghost"
								icon={<IconShareBlue />}
								onClick={() => {
									openModal({
										modalContent: (
											<ShareWithColleagueModal
												itemName={portfolioName}
												modalTitle="Share Portfolio"
												buttonText="Send portfolio"
												action={(emails, note) =>
													sharePortfolio({
														portfolioId,
														recipientEmails: emails,
														note,
													})
												}
												isWithNote
											/>
										),
									});
								}}
							></PageTitleRowIconButton>
							{![
								PortfolioTabEnum.LEASE_ACTIVITY,
								PortfolioTabEnum.PROPERTIES,
							].includes(tab) && (
								<PageTitleRowIconButton
									variant="ghost"
									icon={<IconExportBlue />}
									onClick={() => {
										exportPortfolio({
											filename: portfolioName,
											element: dashboardElement,
										});
									}}
								/>
							)}
							<AddNewPropertyButton
								variant="primary"
								icon={<IconPlusVeryLight />}
								onClick={() => {
									navigate(routes.searchPropertiesHome.toHref());
								}}
							>
								Add New Property
							</AddNewPropertyButton>
						</PageTitleRowRightButtons>
						<SendDataRequest
							variant="ghost"
							onClick={() => {
								openModal({
									modalContent: (
										<DataRequestDialog
											portfolioId={portfolioId}
											closeDialog={closeModal}
										/>
									),
								});
							}}
						>
							Send a data request to us
						</SendDataRequest>
					</PageTitleRowRight>
				</PageTitleRow>
				<PageTitleRow>
					<TabList>
						<Tab value={PortfolioTabEnum.OVERVIEW} disabled={isPortfolioEmpty}>
							Overview
						</Tab>
						<Tab
							value={PortfolioTabEnum.LEASE_ACTIVITY}
							disabled={isPortfolioEmpty}
							onClick={() => viewPortfolio(portfolioId)}
						>
							<Flex alignItems="flex-start" gap="0.25rem">
								<span>Lease Activity</span>
								{isNotificationShown && <RedIndicator />}
							</Flex>
						</Tab>

						<Tab
							value={PortfolioTabEnum.PROPERTIES}
							disabled={isPortfolioEmpty}
						>
							Properties
						</Tab>
					</TabList>
					<PortfolioStatsV2 stats={portfolio.metrics} />
				</PageTitleRow>
			</Container>
		</>
	);
};

const Container = styled.div`
	padding: 1.5rem 1.5rem 0 1.5rem;
	background-color: ${(p) => p.theme.colors.white.white};
`;

const RedIndicator = styled.div`
	width: 0.25rem;
	height: 0.25rem;
	border-radius: 50%;

	background-color: ${(p) => p.theme.colors.red.red500};
`;

const PageTitleAndDiscriptionContainer = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 0.5rem;
	max-width: 550px;
	@media (min-width: ${MQB.D_1536}) {
		max-width: 650px;
	}
`;

const Description = styled.p`
	display: -webkit-box;
	-webkit-box-orient: vertical;
	color: ${(p) => p.theme.colors.gray.n100};
	-webkit-line-clamp: 5;
	font-size: 0.875em;
	font-style: normal;
	font-weight: 325;
	line-height: 1.25em;
	word-break: break-word;
	overflow-y: hidden;
	text-overflow: ellipsis;
	white-space: pre-wrap;
	margin-bottom: 0;
`;

const SendDataRequest = styled(Button)`
	font-size: 0.875em;
`;

const TabList = styled(Tabs.List)`
	display: flex;
	gap: 1em;
	margin-top: 1.5em;
	font-size: 0.75em;
`;

const Tab = styled(Tabs.Trigger)`
	all: unset;
	line-height: 1rem;
	letter-spacing: 0.3px;
	font-weight: 325;
	padding: 0.75rem 0.5rem;
	cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
	box-sizing: border-box;
	text-transform: uppercase;
	position: relative;
	color: ${(p) => p.theme.colors.gray.n100};
	text-align: center;

	text-transform: uppercase;
	&:hover {
		background-color: ${(p) =>
			p.disabled ? 'initial' : p.theme.colors.neutral.n20};
	}
	&[data-state='active'] {
		font-weight: 400;
		color: ${(p) => p.theme.colors.blue.blue400};
		border-right: 1px solid ${(p) => p.theme.colors.gray.gray300};
		border-left: 1px solid ${(p) => p.theme.colors.gray.gray300};
		border-top: 3px solid ${(p) => p.theme.colors.blue.blue400};
		background-color: ${(p) => p.theme.colors.neutral.n20};
	}

	button {
		pointer-events: ${({ disabled }) => (disabled ? 'none' : 'inherit')};
	}
`;
