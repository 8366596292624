import { Portfolio, PortfolioArrayKeys } from 'api/types/portfolio';
import {
	Legend2,
	LegendItem2,
	LegendColor2,
	LegendLabel2,
} from 'PortfolioAnalytics/styles/PortfolioUI';

export type LegendData = {
	name: string;
	hasData: boolean;
};

type CommonProps = { colors: string[] };
type PropsV2 = CommonProps & {
	portfoliosLegendData: LegendData[];
};

export const ChartLegendV2 = ({ portfoliosLegendData, colors }: PropsV2) => {
	return (
		<Legend2>
			{portfoliosLegendData.map((portfolioLegendData, index) => {
				if (!portfolioLegendData) return null;
				return (
					<LegendItem2 key={index}>
						<LegendColor2 color={colors[index]} />
						<LegendLabel2>
							{!portfolioLegendData.hasData && 'No Data: '}
							{portfolioLegendData.name}
						</LegendLabel2>
					</LegendItem2>
				);
			})}
		</Legend2>
	);
};

type PropsV1 = CommonProps & {
	portfolios: Portfolio[] | [];
	metric: Exclude<PortfolioArrayKeys, undefined>;
};

export const ChartLegendV1 = ({ portfolios, colors, metric }: PropsV1) => {
	return (
		<Legend2>
			{portfolios.map((portfolio, index) => {
				return (
					<LegendItem2 key={portfolio.id}>
						<LegendColor2 color={colors[index]} />
						<LegendLabel2>
							{(!portfolio[metric] || portfolio[metric].length === 0) &&
								'No Data: '}{' '}
							{portfolio.name}
						</LegendLabel2>
					</LegendItem2>
				);
			})}
		</Legend2>
	);
};
