import { COLUMN_CONFIG } from 'Components/SearchTable/columnConfigs';
import { BASE_PROPERTY_SECTIONS } from 'Components/SearchTable/columnSectionsConfig';
import { PropertyComp } from 'types';

export const PORTFOLIO_LIMIT = 60_000;

export const MAP_VIEW_INCLUDE_FIELDS: (keyof PropertyComp)[] = [
	'id',
	'marketId',
	'buildingAddressAndCity',
	'geoPoint',
];

export const LIST_VIEW_INCLUDE_FIELDS = BASE_PROPERTY_SECTIONS.flatMap(
	({ ids }) => ids
)
	.map((columnId) => COLUMN_CONFIG[columnId]?.name)
	.filter(Boolean) as (keyof PropertyComp)[];
