import { useModal } from '../../../../../providers/ModalProvider';
import { DataSet } from 'Pages/Analytics/analytics';
import {
	ModalTitle,
	ModalButton,
	ModalButtons,
	ModalParagraph,
} from '../../../../../Components/Modals/common/UI';
import { addFeedback } from 'Singletons/Feedback/actions';
import { useDispatch } from 'react-redux';

type Props = {
	restoreDataSetFn: (dataSetWithoutExcludedCompIds: DataSet) => void;
	dataSetWithoutExcludedCompIds: DataSet;
};

const RestoreDataSetConfirmationModal = ({
	restoreDataSetFn,
	dataSetWithoutExcludedCompIds,
}: Props) => {
	const { closeModal } = useModal();
	const dispatch = useDispatch();
	const restoreDataSet = () => {
		if (restoreDataSetFn) {
			try {
				restoreDataSetFn(dataSetWithoutExcludedCompIds);
			} catch (e) {
				console.error(e);
				dispatch(addFeedback('Error resetting the data set', 'error'));
			}
			closeModal();
		}
	};

	return (
		<>
			<ModalTitle>Restore Your Dataset?</ModalTitle>
			<ModalParagraph>
				Restoring your dataset will remove all changes you have made and revert
				the dataset back to its original state. You <strong>cannot</strong> undo
				this action.
			</ModalParagraph>
			<ModalButtons>
				<ModalButton variant="secondary" onClick={closeModal}>
					Go Back
				</ModalButton>
				<ModalButton variant={'error'} onClick={restoreDataSet}>
					Restore Dataset
				</ModalButton>
			</ModalButtons>
		</>
	);
};

export function useShowRestoreDataSetConfirmationModal() {
	const { openModal } = useModal();
	return (props: Props) => {
		openModal({
			modalContent: <RestoreDataSetConfirmationModal {...props} />,
		});
	};
}
