import { SearchTable } from 'Components';
import { routes } from 'router';
import { useLeasesListAndMapViewContext } from './LeasesListAndMapViewProvider';
import { ExportCompsButton } from 'Components/Export';

export const PortfolioTableLeases = () => {
	const {
		getIsRowSelected,
		toggleRow,
		getAreAllSelected,
		toggleAllSelected,
		areSomeSelected,
		leases,
		isLoading,
		isFetching,
	} = useLeasesListAndMapViewContext();

	const totalCount = leases?.length ?? 0;

	return (
		<SearchTable
			rows={leases ?? []}
			getRowId={(row) => row.id}
			isLoading={isLoading}
			noData={!isFetching && totalCount === 0}
			columnPreferencesType="lease"
			getIsRowSelected={(row) => getIsRowSelected(row.id)}
			areAllSelected={getAreAllSelected()}
			areSomeSelected={areSomeSelected}
			toggleRowSelection={(row) => toggleRow(row.id)}
			toggleAllRowsSelection={toggleAllSelected}
			renderActionButton={(row) =>
					<ExportCompsButton
						compType="lease"
						compIds={[row.id]}
						buttonType="wide-row"
					/>
			}
			getBodyCellProps={(row) => ({
				to: routes.leaseById.toHref({ id: row.id }),
			})}
		/>
	);
};
