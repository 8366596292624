import { calendarUtils, Flex, TooltipV2 } from '@compstak/ui-kit';
import { APP_HEADER_HEIGHT } from 'constants/sizes';
import { useModal } from 'providers/ModalProvider';
import styled from 'styled-components';
import { PromotionMarketsDialog } from './PromotionMarketsDialog';
import { Promotion } from './promotionTypes';
import { useMemo } from 'react';
import { BetaChip } from 'Components';
import { formatCsv, formatMoney } from 'format';
import IconQuestionMark from 'ui/svg_icons/question_mark_outline.svg';

export const PromoPageOutline = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	position: fixed;
	inset: ${APP_HEADER_HEIGHT}px 0 0 0;
	overflow: auto;
`;

export const PROMO_TABS_MAX_WIDTH = 1600;

function formatPromoDate(date: string) {
	if (!date) {
		return 'N/A';
	}
	return calendarUtils.getDate(date, 'fullDate2');
}

export function PromoTitleAndDate({ promotion }: { promotion: Promotion }) {
	return (
		<Flex gap="0.5rem" style={{ flexWrap: 'wrap' }}>
			<Flex gap="0.5rem" style={{ flexWrap: 'wrap' }}>
				<PromoTitle>{promotion.title}</PromoTitle>
				<BetaChip>
					{promotion.compType === 'Leases' ? 'Lease Comps' : 'Sale Comps'}
				</BetaChip>
			</Flex>
			<div>
				{formatPromoDate(promotion.marketingStartDate)} -{' '}
				{formatPromoDate(promotion.marketingEndDate)}
			</div>
		</Flex>
	);
}

const useRewardLabelText = ({ promotion }: { promotion: Promotion }) => {
	const creditRewardMultiplier = promotion.creditReward?.multiplier ?? 0;
	const giftcardTiers = promotion.giftCardReward?.tiers ?? [];
	const hasCreditReward = creditRewardMultiplier !== 0;
	const hasGiftcardReward =
		Array.isArray(giftcardTiers) && giftcardTiers.length > 0;
	if (hasCreditReward && hasGiftcardReward) {
		return `${creditRewardMultiplier}x Credits + Gift Card`;
	}
	if (hasCreditReward) {
		return `${creditRewardMultiplier}x Credits`;
	}
	if (hasGiftcardReward) {
		return 'Gift Card';
	}
	return 'N/A';
};

export const PromoDetailsSection = ({
	promotion,
}: {
	promotion: Promotion;
}) => {
	const rewardLabelText = useRewardLabelText({ promotion });

	const { openModal } = useModal();

	const sortedMarkets = useMemo(() => {
		return promotion.markets.sort((a, b) => a.localeCompare(b));
	}, [promotion.markets]);

	return (
		<PromoDetailsContainer>
			<GridStyled>
				{promotion.compType === 'Leases' && (
					<>
						<div>
							<BoldLabel>Space Types</BoldLabel>
							<div>{formatCsv(promotion.spaceTypes)}</div>
						</div>
						<>
							{promotion.propertyTypes != null &&
								promotion.propertyTypes.length > 0 && (
									<div>
										<BoldLabel>Property Type</BoldLabel>
										<div>{formatCsv(promotion.propertyTypes)}</div>
									</div>
								)}
							{promotion.propertySubtypes != null &&
								promotion.propertySubtypes.length > 0 && (
									<div>
										<BoldLabel>Property Subtype</BoldLabel>
										<div>{formatCsv(promotion.propertySubtypes)}</div>
									</div>
								)}
						</>
						{promotion.executionDateLookback && (
							<div>
								<BoldLabel>Lease Executed In</BoldLabel>
								<div>Last {promotion.executionDateLookback.slice(1)} days</div>
							</div>
						)}
						{!promotion.executionDateLookback && (
							<div>
								<BoldLabel>Execution Date</BoldLabel>
								<div>
									Since {formatPromoDate(promotion.marketingExecutionStartDate)}
								</div>
							</div>
						)}
						<div>
							<BoldLabel>Rewards</BoldLabel>
							<div>{rewardLabelText}</div>
						</div>
						{promotion.rewardOnlyMasterCreator && (
							<div>
								<BoldLabelAndTooltip>
									Primary or Versions
									<TooltipV2 content="A primary version is the first version that CompStak receives for a Lease or Sales Comp">
										<span>
											<StyledIconQuestionMark />
										</span>
									</TooltipV2>
								</BoldLabelAndTooltip>
								<div>Primary Versions Only</div>
							</div>
						)}
					</>
				)}
				{promotion.compType === 'Sales' && (
					<>
						{promotion.saleDateLookback && (
							<div>
								<BoldLabel>Sale Executed In</BoldLabel>
								<div>Last {promotion.saleDateLookback.slice(1)} days</div>
							</div>
						)}
						{!promotion.saleDateLookback && (
							<div>
								<BoldLabel>Sale Date</BoldLabel>
								<div>
									Since {formatPromoDate(promotion.marketingSaleStartDate)}
								</div>
							</div>
						)}
						<div>
							<BoldLabel>Sale price</BoldLabel>
							<div>
								Over {formatMoney(promotion.salePrice, { shorten: false })}
							</div>
						</div>
						{promotion.premiumOnly && (
							<div>
								<BoldLabelAndTooltip>
									Premium or Full
									<TooltipV2 content="A premium comp must include at least 2 of the fields below: Cap Rate, NOI / NOI PSF, OpEx / OpEx PSF">
										<span>
											<StyledIconQuestionMark />
										</span>
									</TooltipV2>
								</BoldLabelAndTooltip>
								<div>Premium</div>
							</div>
						)}
						<div>
							<BoldLabel>Rewards</BoldLabel>
							<div>{rewardLabelText}</div>
						</div>
						<div>
							<BoldLabel>Property Type</BoldLabel>
							<div>{formatCsv(promotion.propertyTypes)}</div>
						</div>
						{promotion.propertySubtypes != null &&
							promotion.propertySubtypes.length > 0 && (
								<div>
									<BoldLabel>Property Subtype</BoldLabel>
									<div>{formatCsv(promotion.propertySubtypes)}</div>
								</div>
							)}
					</>
				)}
			</GridStyled>
			<div>
				<BoldLabel>Markets</BoldLabel>
				{promotion.marketsDisplayText && promotion.markets.length > 10 ? (
					<div>
						{promotion.marketsDisplayText} (
						<SeeMore
							onClick={() => {
								openModal({
									modalContent: (
										<PromotionMarketsDialog promotion={promotion} />
									),
								});
							}}
						>
							See List of Markets
						</SeeMore>
						)
					</div>
				) : (
					<div>{formatCsv(sortedMarkets)}</div>
				)}
			</div>
		</PromoDetailsContainer>
	);
};

const PromoTitle = styled.h4`
	text-transform: uppercase;
`;

const PromoDetailsContainer = styled(Flex)`
	flex-direction: column;
	align-items: flex-start;
	gap: 8px;
	width: 100%;
`;

const GridStyled = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	width: 100%;
	gap: 8px;
`;

const BoldLabel = styled.b`
	font-size: 16px;
	font-weight: 600;
`;

export const PromoSimpleWarning = styled.div`
	margin: 16px;
`;

const SeeMore = styled.button`
	all: unset;
	color: ${(p) => p.theme.colors.blue.blue400};
	text-decoration: underline;
	cursor: pointer;
`;

const BoldLabelAndTooltip = styled(BoldLabel)`
	display: flex;
	gap: 4px;
	align-items: center;
`;

const StyledIconQuestionMark = styled(IconQuestionMark)`
	width: 1rem;
	height: 1rem;
`;
