import React, { memo } from 'react';
import { BUILDING_CLASS_ID_TO_NAME, BuildingClassId, Portfolio } from 'api';
import { NoDataTab } from 'Components/NoDataMessaging/NoDataMessaging';
import { formatPercent } from 'format';
import { groupBy, mapValues } from 'lodash';
import { ChartContainer } from 'PortfolioAnalytics/styles/PortfolioUI';
import {
	ChartBox,
	ChartTypeProperty,
	getTotalValuesProperty,
} from './ChartSelect';
import { PortfolioPieChart } from './PortfolioPieChart';
import { useNavigate } from 'react-router';
import { routes } from 'router';
import { OVERVIEW_CHARTS_NO_DATA_COMPONENT_HEIGHT } from 'PortfolioAnalytics/constants';

type BuildingClassChartProps = {
	portfolio: Portfolio;
};

const BUILDING_CLASS_BY_PROPERTY_TYPE_CHART_NAME = 'Building Class';

export const BuildingClassByPropertyTypeChart = memo(
	({ portfolio }: BuildingClassChartProps) => {
		const groupsByBuildingClassId = groupBy(
			portfolio.propertyMetricsBuildingClassesByMarkets,
			(p) => p.buildingClassId
		);

		const buildingClassIds = Object.keys(groupsByBuildingClassId).map(
			(buildingClassId) => Number(buildingClassId)
		) as BuildingClassId[];

		const totalValuesPerBuildingClass = mapValues(
			groupsByBuildingClassId,
			getTotalValuesProperty
		);

		const totalValuesForPortfolio = getTotalValuesProperty(
			Object.values(totalValuesPerBuildingClass)
		);

		const buildingClassByTotalBuildingSize = buildingClassIds.map(
			(buildingClassId) => {
				return getChartPoint(
					buildingClassId,
					totalValuesPerBuildingClass[buildingClassId].totalBuildingSize,
					totalValuesForPortfolio.totalBuildingSize
				);
			}
		);

		const buildingClassBypropertyCount = buildingClassIds.map(
			(buildingClassId) => {
				return getChartPoint(
					buildingClassId,
					totalValuesPerBuildingClass[buildingClassId].propertyCount,
					totalValuesForPortfolio.propertyCount
				);
			}
		);

		const getData = (chartType: ChartTypeProperty) => {
			switch (chartType) {
				case 'totalBuildingSize':
					return buildingClassByTotalBuildingSize;
				case 'propertyCount':
					return buildingClassBypropertyCount;
				default:
					return [];
			}
		};

		const navigate = useNavigate();

		return (
			<ChartBox<'property'>
				chartName={BUILDING_CLASS_BY_PROPERTY_TYPE_CHART_NAME}
				chartDataType="property"
			>
				{(selectedChart) => {
					const data = getData(selectedChart.type);
					if (data.length === 0 || !data.some((d) => d.y > 0))
						return (
							<ChartContainer>
								<NoDataTab
									minHeight={OVERVIEW_CHARTS_NO_DATA_COMPONENT_HEIGHT}
									message=""
								/>
							</ChartContainer>
						);

					return (
						<PortfolioPieChart
							data={data}
							events={[
								{
									target: 'data',
									eventHandlers: {
										onClick: (_, clickedProps) => {
											const buildingClassId = clickedProps.datum
												.buildingClassId as BuildingClassId;
											navigate(
												routes.portfolioByIdView.toHref(
													{ portfolioId: portfolio.id, viewType: 'list' },
													{ buildingClassIds: [buildingClassId] }
												)
											);
										},
									},
								},
							]}
							dataType="property"
						/>
					);
				}}
			</ChartBox>
		);
	}
);

BuildingClassByPropertyTypeChart.displayName = 'BuildingClassChart';

const getChartPoint = (
	buildingClassId: BuildingClassId,
	value: number,
	totalValue: number
) => {
	return {
		x: BUILDING_CLASS_ID_TO_NAME[buildingClassId],
		y: value,
		label:
			totalValue !== 0
				? formatPercent(value / totalValue, 1)
				: formatPercent(0, 1),
		buildingClassId,
	};
};
