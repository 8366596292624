import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { Portfolio } from '../../types';
import { composeEnabled } from '../../utils';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { API } from '@compstak/common';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';

export const usePortfolioByIdQuery = (
	params: PortfolioByIdParams,
	options?: UseQueryOptions<PortfolioByIdResponse>
) => {
	const queryParams = getQueryStringFromPortfolioFilters(params.filters);

	return useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_BY_ID, params.id, queryParams],
		queryFn: async () => {
			const response = await API.get<PortfolioByIdResponse>(
				`/pa/v2/api/portfolios/${params.id}${queryParams}`
			);
			return response.data;
		},
		...options,
		enabled: composeEnabled(params.id != null, options?.enabled),
	});
};

export type PortfolioByIdParams = {
	id: number | undefined;
	filters?: FiltersState;
};

export type PortfolioByIdResponse = Portfolio;
