import { ChartBox } from './ChartSelect';
import { Spacer } from 'PortfolioAnalytics/UI';
import { Strong } from 'utils/highlightText';
import {
	PerformanceDistributionChartContent,
	PERFORMANCE_DIST_CHART_TYPE,
} from './PortfolioPerformanceDistributionContent';

type PropertyPerformanceChartProps = {
	portfolioId: number;
};

export const PortfolioPerformanceDistributionChart = ({
	portfolioId,
}: PropertyPerformanceChartProps) => {
	return (
		<ChartBox
			chartName="Portfolio Performance Distribution"
			displaySelector={false}
			chartToolTip={PORTFOLIO_PERFORMANCE_DISTRIBUTION_TOOLTIP}
		>
			<PerformanceDistributionChartContent
				portfolioIds={[portfolioId]}
				chartType={PERFORMANCE_DIST_CHART_TYPE.singlePortfolio}
			/>
		</ChartBox>
	);
};

const PORTFOLIO_PERFORMANCE_DISTRIBUTION_TOOLTIP = (
	<>
		<>
			<Spacer>
				The Portfolio Performance Distribution chart displays a distribution of
				risk and performance of properties in the portfolio.
			</Spacer>
			<Spacer>
				<Strong>High Performance:</Strong> Properties with higher rent than the
				market.
			</Spacer>
			<Spacer>
				<Strong>Low Performance:</Strong> Properties with lower rent than the
				market.
			</Spacer>
			<Spacer>
				<Strong>High Risk:</Strong> Properties with many leases expiring in the
				next 24 months.
			</Spacer>
			<Spacer>
				<Strong>Low Risk:</Strong> Properties with few leases expiring in the
				next 24 months.
			</Spacer>
		</>
	</>
);
