import constate from 'constate';
import { useSelectionState } from 'utils/useSelectionState';
import { useCallback, useEffect, useMemo } from 'react';
import {
	PortfolioPropertiesResponse,
	PortfolioV2,
	usePortfolioPropertiesQuery,
} from 'api';
import { useSearchTableRows } from 'Pages/Search/useSearchTableRows';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import {
	PortfolioPropertiesInfiniteQueryParams,
	usePortfolioPropertiesInfiniteQuery,
} from 'api/portfolio/portfolioProperties/usePortfolioPropertiesInfiniteQuery';
import { PropertyComp } from 'types';
import { PortfolioByIdRouteSearchParams } from 'router';

type Props = {
	portfolio: PortfolioV2;
	includeFields: (keyof PropertyComp)[];
	searchParams?: PortfolioByIdRouteSearchParams;
};

export const [PortfolioPropertiesProvider, usePortfolioProperties] = constate(
	({ portfolio, includeFields, searchParams }: Props) => {
		const { filters: portfolioFilters } = usePortfolioFilters();

		const filters: PortfolioPropertiesInfiniteQueryParams = useMemo(() => {
			return {
				filters: { ...portfolioFilters, ...searchParams },
				portfolioId: portfolio.portfolio.id,
				order: 'desc',
				sort: 'buildingSize',
				includeFields,
			};
		}, [includeFields, portfolioFilters, portfolio, searchParams]);

		const infinitePropertiesQuery =
			usePortfolioPropertiesInfiniteQuery(filters);
		const noLimitPropertiesQuery = usePortfolioPropertiesQuery(
			{ ...filters, includeFields: ['id', 'marketId'] },
			{
				enabled: false,
			}
		);

		const infiniteRows = useSearchTableRows<
			PortfolioPropertiesResponse[number]
		>({
			data: infinitePropertiesQuery.data,
			getRows: useCallback((page: PortfolioPropertiesResponse) => page, []),
			isFetching: infinitePropertiesQuery.isFetchingNextPage,
		});
		const rowsForSelection = noLimitPropertiesQuery.data ?? infiniteRows;

		const _selectionState = useSelectionState(rowsForSelection);
		const selectionState = {
			..._selectionState,
			toggleAllSelected: () => {
				// fetch all leases if we haven't yet and there is more to load
				if (
					!noLimitPropertiesQuery.data &&
					infinitePropertiesQuery.hasNextPage
				) {
					noLimitPropertiesQuery.refetch();
				} else {
					_selectionState.toggleAllSelected();
				}
			},
		};

		useEffect(() => {
			if (noLimitPropertiesQuery.data) {
				selectionState.toggleAllSelected();
			}
		}, [noLimitPropertiesQuery.data]);

		useEffect(() => {
			selectionState.resetSelection();
		}, [filters]);

		const isFetching =
			infinitePropertiesQuery.isFetching || noLimitPropertiesQuery.isFetching;
		const isError =
			infinitePropertiesQuery.isError || noLimitPropertiesQuery.isError;
		const isFetchingNextPage = infinitePropertiesQuery.isFetchingNextPage;
		const hasNextPage = infinitePropertiesQuery.hasNextPage;
		const fetchNextPage = infinitePropertiesQuery.fetchNextPage;

		return {
			data: infiniteRows,
			isFetchingNextPage,
			isError,
			isFetching,
			isFetchingAll: noLimitPropertiesQuery.isFetching,
			hasNextPage,
			fetchNextPage,
			selectionState,
			portfolio,
		};
	}
);
