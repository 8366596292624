// This serves as an inventory of date formats used
// TODO: Should live in some shared package
export const DATE_FORMATS = {
	['M/D/YYYY']: 'M/D/YYYY',
	['MM/YYYY']: 'MM/YYYY',
	['MMM YYYY']: 'MMM YYYY',
	['MMM D, YYYY']: 'MMM D, YYYY',
	['MMM D YYYY']: 'MMM D YYYY',
	['MMM DD, YYYY']: 'MMM DD, YYYY',
	['MM/DD/YYYY']: 'MM/DD/YYYY',
	['DD/MM/YYYY']: 'DD/MM/YYYY',
	['MMM‘YYYY']: 'MMM‘YYYY',
	['YYYY-MM-DD']: 'YYYY-MM-DD',
	['YYYY_MM_DD_HH-mm']: 'YYYY_MM_DD_HH-mm',
	['MMMM DD, YYYY']: 'MMMM DD, YYYY',
	['MMMM D, YYYY [at] h:mm A']: 'MMMM D, YYYY [at] h:mm A',
	['M/D/YY [at] h:mmA']: 'M/D/YY [at] h:mmA',
	['YYYY-MM-DDTHH:mm:ss[Z]']: 'YYYY-MM-DDTHH:mm:ss[Z]',
	['YYYY-MM-DDTHH:mm:ss']: 'YYYY-MM-DDTHH:mm:ss',
} as const;
