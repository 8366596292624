import { Portfolio, PortfolioV2, SPACE_TYPE_NAME_TO_ID } from 'api';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import dayjs from 'dayjs';
import { CreateFiltersArg, createFilters } from 'models/filters';

type Props = FiltersState & { compType?: CreateFiltersArg } & (
		| {
				excludeSubmarkets?: false;
				portfolio: Portfolio | PortfolioV2;
		  }
		| { excludeSubmarkets: true }
	);

export const getFiltersFromPortfolioFilters = ({
	buildingClassIds,
	leaseExpirationsMonths,
	propertyTypeIds,
	markets,
	submarketIds,
	buildingSize,
	spaceTypes,
	compType = 'lease',
	...props
}: Props) => {
	const filters = createFilters(compType);

	if (compType !== 'property' && compType !== 'properties') {
		filters.partial = 'false';
		if (spaceTypes.length > 0) {
			filters.spaceTypeId = spaceTypes.map(
				(spaceTypeName) => SPACE_TYPE_NAME_TO_ID[spaceTypeName]
			);
		}
	}

	if (buildingClassIds.length > 0) {
		filters.buildingClassId = buildingClassIds;
	}
	if (leaseExpirationsMonths) {
		filters.expirationDate = {
			min: null,
			max: dayjs().add(leaseExpirationsMonths, 'month').toDate(),
		};
	}
	if (propertyTypeIds.length > 0) {
		filters.buildingPropertyTypeId = propertyTypeIds;
	}
	if (!props.excludeSubmarkets) {
		filters.markets = markets;
		filters.submarkets = props.portfolio.markets
			.flatMap((market) => market.submarkets)
			.map(({ submarketId, submarket }) => ({
				id: submarketId,
				name: submarket,
			}))
			.filter(({ id }) => submarketIds.includes(id));
	}

	if (buildingSize) {
		filters.buildingSize = buildingSize;
	}

	return filters;
};
