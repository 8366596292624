import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { QUERY_KEYS } from '../../constants';
import { PortfolioV2 } from '../../types';
import { composeEnabled } from '../../utils';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { API } from '@compstak/common';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';

export const fetchPortfolioV2 = async ({
	id,
	filters,
}: PortfolioByIdParams) => {
	const queryParams = getQueryStringFromPortfolioFilters(filters);
	const response = await API.get<PortfolioV2>(
		`/pa/v2/api/v2/portfolios/${id}${queryParams}`
	);
	return response.data;
};

export const usePortfolioByIdQueryV2 = (
	params: PortfolioByIdParams,
	options?: UseQueryOptions<PortfolioV2>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_BY_ID_V2, params.id, params.filters],
		queryFn: () => fetchPortfolioV2(params),
		...options,
		enabled: composeEnabled(params.id != null, options?.enabled),
	});
};

export type PortfolioByIdParams = {
	id: number | undefined;
	filters?: FiltersState;
};
