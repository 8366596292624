import { Spinner } from '@compstak/ui-kit';
import { usePropertyByIdQuery, usePropertySalesQuery } from 'api';
import { Link } from 'router';
import styled from 'styled-components';
import { CompPopupHeader } from './CompPopupHeader';
import { GenerateCompSetButton } from './GenerateCompSetButton';
import { NearbyCompsButton } from './NearbyCompsButton';
import { CompPopupActionsV2, CompPopupRoot, CompPopupRootLoading } from './UI';
import { useFiltersWithAddressString } from './useFiltersWithAddressString';
import { MapPopupAddToPortfolioButton } from './MapPopupAddToPortfolioButton';
import { PropertyPageLink } from './PropertyPageLink';

type Props = {
	propertyId: number;
	closePopup: () => void;
};

export const SalePopup = ({ propertyId, closePopup }: Props) => {
	const { data: property } = usePropertyByIdQuery({
		id: propertyId,
	});

	const { data: propertySales } = usePropertySalesQuery({
		propertyId,
	});

	const filtersWithAddressString = useFiltersWithAddressString({
		property,
	});

	const listSaleHistoryUrl = `/search/sales/list?${filtersWithAddressString}`;

	const comps = propertySales?.comps;

	if (!property || !comps)
		return (
			<CompPopupRootLoading>
				<Spinner size="l" />
			</CompPopupRootLoading>
		);

	return (
		<CompPopupRoot>
			<CompPopupHeader property={property} closePopup={closePopup} />
			<CompPopupActionsV2>
				<PropertyPageLink propertyId={property.id}>
					Detailed Property Info
				</PropertyPageLink>

				<GenerateCompSetButton property={property} closePopup={closePopup} />

				<NearbyCompsButton
					property={property}
					closePopup={closePopup}
					compType="sale"
					data-qa-id="nearby-sale-comps-btn"
				>
					Nearby Properties
				</NearbyCompsButton>
				<MapPopupAddToPortfolioButton
					variant="secondary"
					propertyIds={[property.id]}
				/>
				<ListSaleHistoryLink to={listSaleHistoryUrl} onClick={closePopup}>
					List Sale History ({comps.length})
				</ListSaleHistoryLink>
			</CompPopupActionsV2>
		</CompPopupRoot>
	);
};

const ListSaleHistoryLink = styled(Link)`
	color: white;
	margin: 10px;
	margin-left: 0;
	text-transform: uppercase;
	font-family: 'Gotham', 'Open Sans', Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-size: 11px;
	letter-spacing: 0.06em;
	border-bottom: grey solid 1px;
	padding-bottom: 5px;
	width: fit-content;
	display: block;
	&:hover {
		color: white;
	}
`;
