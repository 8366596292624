import { UseQueryOptions, useQuery } from '@tanstack/react-query';
import { PropertyComp } from '../../../types';
import { QUERY_KEYS } from '../../constants';
import { PORTFOLIO_LIMIT } from '../constants';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { PortfolioPropertiesParams } from './types';
import { API } from '@compstak/common';

export const usePortfolioPropertiesQuery = (
	params: PortfolioPropertiesParams,
	options?: UseQueryOptions<PortfolioPropertiesResponse>
) => {
	return useQuery({
		queryKey: [QUERY_KEYS.PORTFOLIO_PROPERTIES, params],
		queryFn: async () => {
			const { portfolioId, filters, ..._otherParams } = params;
			const otherParams = {
				limit: PORTFOLIO_LIMIT,
				..._otherParams,
			};

			const response = await API.get<PortfolioPropertiesResponse>(
				`/pa/v2/api/portfolios/${portfolioId}/properties${getQueryStringFromPortfolioFilters(filters, otherParams)}`
			);
			return response.data;
		},
		...options,
	});
};

export type PortfolioPropertiesResponse = PropertyComp[];
