import { Map, MapInteractivity, MapStyle } from '@compstak/maps';
import { deselectMapControl, MAP_CONTROLS } from 'actions/search';
import { MarketsLayer } from 'Pages/Search/Map/Components/MarketsLayer/MarketsLayer';
import { useMarketSelectorExpanded } from 'hooks/useMarketSelectorExpanded';
import { useShowMapAnalytics } from 'hooks/useShowMapAnalytics';
import { SearchLayoutRouteParams } from 'Layouts/SearchLayout';
import { useSearchPageContext } from 'Layouts/SearchProvider';
import {
	MapButtonsContainer,
	MapStyleButton,
	MarketRentAnalyticsProvider,
	TenantIndustryAnalyticsProvider,
} from 'maps';
import {
	SearchMapProvider,
	useSearchMapState,
} from 'Pages/Search/Map/SearchMapProvider';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useFilters } from 'reducers/filtersReducer';
import styled from 'styled-components';
import { CompType } from 'types/comp';
import { CENTER_OF_CONTINENTAL_US } from '../constants';
import { useMultiSelect } from '../MultiSelectProvider';
import { useSearch } from '../searchReducer';
import { removeProperty } from './actions';
import {
	CenterEffects,
	MapAnalyticsButton,
	MapAnalyticsSelectMenu,
	MapExport,
	MultiSelectButton,
	OpportunityZonesLayer,
	PropertyPopupLayer,
	SearchWithinView,
	SubmarketsLayer,
	TopRightContainer,
	useOpportunityZonesFeatures,
	useSubmarketFeatures,
} from './Components';
import { FilterMenuToggler } from './Components/FilterMenuToggler';
import {
	LeaseExpirationAnalytics,
	LeaseExpirationMenu,
} from './Components/LeaseExpirationAnalytics';
import { LeaseExpirationAnalyticsProvider } from './Components/LeaseExpirationAnalytics/LeaseExpirationProvider';
import {
	MarketRentAnalytics,
	MarketRentAnalyticsMenu,
} from './Components/MarketRentAnalytics';
import { PinsMVTLayer } from './Components/PinsMVTLayer/PinsMVTLayer';
import { PolygonFilter } from './Components/PolygonFilter/PolygonFilter';
import { RadiusFilter } from './Components/RadiusFilter/RadiusFilter';
import {
	SpaceTypeAnalytics,
	SpaceTypeAnalyticsMenu,
	SpaceTypeAnalyticsProvider,
} from './Components/SpaceTypeAnalytics';
import {
	TenantIndustryAnalytics,
	TenantIndustryAnalyticsMenu,
} from './Components/TenantIndustryAnalytics';
import { useAllMarketsPolygons } from 'api/allMarketsPolygons/useAllMarketsPolygons';
import { useMarketFeatures } from './Components/MarketsLayer/useMarketFeatures';
import { CountiesLayer } from './Components/CountiesLayer/CountiesLayer';
import { useCountyFeatures } from './Components/CountiesLayer/useCountyFeatures';
import { useAPNFeatures } from './Components/APNLayer/useAPNFeatures';
import { APNLayer } from './Components/APNLayer/APNLayer';
import { useHasAccessToLeagueTables } from 'hooks/useHasAccessToLeagueTables';

export const MAP_LAYERS_ORDER = {
	PinsMVTLayer: 4,
	MarketsLayer: 3,
	OpportunityZonesLayer: 2,
	SubmarketsLayer: 2,
	APNLayer: 1,
	CountiesLayer: 1,
	PropertyPopupLayer: 0,
	MarketRentAnalyticsLayers: 0,
	LeaseExpirationAnalyticsLayer: 0,
	SpaceTypeAnalyticsLayer: 0,
	TenantIndustryAnalyticsLayer: 0,
};

type SearchMapContainerProps = {
	compType: CompType;
	params: SearchLayoutRouteParams;
	setSideBarState: () => void;
};

const SearchMapContainer = ({
	compType,
	params,
	setSideBarState,
}: SearchMapContainerProps) => {
	return (
		<React.Suspense fallback={null}>
			<SearchMapProvider>
				<SearchMapContainerContent
					compType={compType}
					params={params}
					setSideBarState={setSideBarState}
				/>
			</SearchMapProvider>
		</React.Suspense>
	);
};

const SearchMapContainerContent = ({
	compType,
	params,
	setSideBarState,
}: SearchMapContainerProps) => {
	const [filters, setFilters] = useFilters();
	const dispatch = useDispatch();
	const searchStateRedux = useSearch();
	const multiSelect = useMultiSelect();
	const [searchMapState, setMapState] = useSearchMapState();
	const { mapStyle, isAnalyticsMenuOpen } = searchMapState;
	const { searchState, setSearchState } = useSearchPageContext();
	const { mapAnalyticsType } = searchState;
	const showMapAnalytics = useShowMapAnalytics({ compType });
	const marketSelectorExpanded = useMarketSelectorExpanded();

	const hasAccessToLeagueTables = useHasAccessToLeagueTables();

	const { activeMapControl } = searchStateRedux;
	const { isMultiSelectOn, resetMultiSelect } = multiSelect;

	// close the popup when user moves to different page
	// or goes to list view, or switches to map analytics
	useEffect(() => {
		dispatch(removeProperty());
	}, [dispatch, compType, params.view, mapAnalyticsType]);

	// when a map filter is turned on, turn off multiselect
	useEffect(() => {
		if (activeMapControl && isMultiSelectOn) {
			resetMultiSelect();
		}
		// isMultiSelectOn is ommited intentionally
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [activeMapControl, dispatch, resetMultiSelect]);

	// when multiselect is turned on, turn off map filters
	useEffect(() => {
		if (activeMapControl && isMultiSelectOn) {
			dispatch(deselectMapControl());
		}
		// activeMapControl is ommited intentionally
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isMultiSelectOn, dispatch]);

	// use Monochrome style & reset multi-select if map analytics is on
	useEffect(() => {
		if (!mapAnalyticsType) {
			setMapState((s) => ({ ...s, mapStyle: MapStyle.DEFAULT }));
		} else {
			setMapState((s) => ({ ...s, mapStyle: MapStyle.MONOCHROME }));
			resetMultiSelect();
		}
	}, [mapAnalyticsType, setMapState, resetMultiSelect]);

	// reset mapAnalytics-related states when it's not allowed to be rendered
	useEffect(() => {
		if (!showMapAnalytics) {
			setMapState((s) => ({ ...s, isAnalyticsMenuOpen: false }));
			setSearchState((s) => ({ ...s, mapAnalyticsType: null }));
		}
	}, [showMapAnalytics, setMapState, setSearchState]);

	const onCloseMapAnalytics = () => {
		setSearchState((s) => ({
			...s,
			mapAnalyticsType: null,
		}));
		setMapState((s) => ({
			...s,
			isAnalyticsSelectMenuOpen: false,
			isAnalyticMenuOpen: false,
			mapStyle: MapStyle.MONOCHROME,
		}));
	};

	const onClickMapStyle = () => {
		const newStyle =
			mapAnalyticsType !== null
				? mapStyle === MapStyle.MONOCHROME
					? MapStyle.SATELLITE
					: MapStyle.MONOCHROME
				: mapStyle === MapStyle.DEFAULT
					? MapStyle.SATELLITE
					: MapStyle.DEFAULT;

		setMapState((s) => ({ ...s, mapStyle: newStyle }));
	};

	const { data: marketFeatures } = useAllMarketsPolygons();

	const { selectedMarketFeatures, onMarketsChange } = useMarketFeatures(
		marketFeatures?.features
	);

	const { opportunityZoneFeatures, selectedOpportunityZoneFeatures } =
		useOpportunityZonesFeatures(filters, {
			enabled: activeMapControl === MAP_CONTROLS.OPPORTUNITY_ZONES,
		});

	const { submarketFeatures, selectedSubmarketFeatures } =
		useSubmarketFeatures();

	const { countyFeatures } = useCountyFeatures();
	const { apnFeatures } = useAPNFeatures();

	return (
		<SearchMapContainerContentContainer
			isVisible={
				params.view !== 'list' &&
				(hasAccessToLeagueTables ? params.view !== 'leagueTables' : true)
			}
		>
			<MapContainer>
				<TopRightContainer>
					<FilterMenuToggler onClick={setSideBarState} />
				</TopRightContainer>
				<Map
					mapStyle={searchMapState.mapStyle}
					interactivity={MapInteractivity.INTERACTIVE}
					initialViewState={{ ...CENTER_OF_CONTINENTAL_US }}
				>
					{mapAnalyticsType == null && (
						<PinsMVTLayer
							activeMapControl={activeMapControl}
							compType={compType}
							filters={filters}
							selectedSubmarketFeatures={selectedSubmarketFeatures}
							selectedOpportunityZoneFeatures={selectedOpportunityZoneFeatures}
							multiSelect={multiSelect}
							countyFeatures={countyFeatures}
							apnFeatures={apnFeatures}
							view={params.view}
						/>
					)}
					{searchStateRedux.property && searchStateRedux.propertyPopup && (
						<PropertyPopupLayer
							compType={compType}
							property={searchStateRedux.property}
							propertyPopup={searchStateRedux.propertyPopup}
						/>
					)}
					{activeMapControl === MAP_CONTROLS.SEARCH_WITHIN_VIEW && (
						<SearchWithinView />
					)}
					{activeMapControl === MAP_CONTROLS.POLYGON && (
						<PolygonFilter
							polygonFilter={filters.polygon}
							setFilters={setFilters}
						/>
					)}
					{activeMapControl === MAP_CONTROLS.RADIUS && (
						<RadiusFilter
							radiusFilter={filters.radius}
							setFilters={setFilters}
						/>
					)}
					{activeMapControl === MAP_CONTROLS.SUBMARKETS && (
						<SubmarketsLayer submarketFeatures={submarketFeatures} />
					)}
					{activeMapControl === MAP_CONTROLS.OPPORTUNITY_ZONES && (
						<OpportunityZonesLayer
							opportunityZoneFeatures={opportunityZoneFeatures}
						/>
					)}
					{marketSelectorExpanded && marketFeatures && (
						<MarketsLayer
							marketFeatures={marketFeatures.features}
							selectedMarketFeatures={selectedMarketFeatures}
							onMarketsChange={onMarketsChange}
						/>
					)}
					{countyFeatures.length > 0 && (
						<CountiesLayer countyFeatures={countyFeatures} />
					)}
					{apnFeatures.length > 0 && <APNLayer apnFeatures={apnFeatures} />}
					<CenterEffects />
					{mapAnalyticsType != null && (
						<MarketRentAnalyticsProvider>
							<LeaseExpirationAnalyticsProvider>
								<SpaceTypeAnalyticsProvider>
									<TenantIndustryAnalyticsProvider>
										{mapAnalyticsType === 'marketRent' && (
											<>
												<MarketRentAnalytics />
												{searchMapState.isAnalyticsDetailMenuOpen && (
													<MarketRentAnalyticsMenu
														onClose={onCloseMapAnalytics}
													/>
												)}
											</>
										)}
										{mapAnalyticsType === 'leaseExpiration' && (
											<>
												<LeaseExpirationAnalytics />
												{searchMapState.isAnalyticsDetailMenuOpen && (
													<LeaseExpirationMenu onClose={onCloseMapAnalytics} />
												)}
											</>
										)}
										{mapAnalyticsType === 'spaceType' && (
											<>
												<SpaceTypeAnalytics />
												{searchMapState.isAnalyticsDetailMenuOpen && (
													<SpaceTypeAnalyticsMenu
														onClose={onCloseMapAnalytics}
													/>
												)}
											</>
										)}
										{mapAnalyticsType === 'tenantIndusty' && (
											<>
												<TenantIndustryAnalytics />
												{searchMapState.isAnalyticsDetailMenuOpen && (
													<TenantIndustryAnalyticsMenu
														onClose={onCloseMapAnalytics}
													/>
												)}
											</>
										)}
										{searchMapState.isExporting && (
											<MapExport mapAnalyticsType={mapAnalyticsType} />
										)}
									</TenantIndustryAnalyticsProvider>
								</SpaceTypeAnalyticsProvider>
							</LeaseExpirationAnalyticsProvider>
						</MarketRentAnalyticsProvider>
					)}
					{showMapAnalytics && isAnalyticsMenuOpen && (
						<MapAnalyticsSelectMenu />
					)}
					<MapButtonsContainer data-html2canvas-ignore>
						{showMapAnalytics && <MapAnalyticsButton />}
						<MapStyleButton mapStyle={mapStyle} onClick={onClickMapStyle} />
						{!mapAnalyticsType && <MultiSelectButton />}
					</MapButtonsContainer>
				</Map>
			</MapContainer>
		</SearchMapContainerContentContainer>
	);
};

const MapContainer = styled.div`
	width: 100%;
	height: 100%;
`;

const SearchMapContainerContentContainer = styled.div<{
	isVisible: boolean;
}>`
	width: 100%;
	height: 100%;
	${({ isVisible }) => !isVisible && 'visibility: hidden;'};
`;

export default SearchMapContainer;
