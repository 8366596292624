import { UseQueryOptions } from '@tanstack/react-query';
import { LeaseComp } from '../../../types';
import { QUERY_KEYS } from '../../constants';
import { createFetchFn, useQueryHook } from '../../utils';
import { getQueryStringFromPortfolioFilters } from 'util/getQueryStringsFromPortfolioFilter';
import { FiltersState } from 'PortfolioAnalytics/PortfolioFiltersProvider';

type PortfolioLeasesQueryParams = {
	portfolioId: number | undefined;
	filters?: FiltersState;
	offset: number;
	limit: number;
};

export const fetchPortfolioLeases = createFetchFn<
	PortfolioLeasesQueryParams,
	PortfolioLeasesResponse
>({
	method: 'get',
	getURL: ({ portfolioId, filters, ...otherParams }) => {
		return `/pa/v2/api/portfolios/${portfolioId}/leases${getQueryStringFromPortfolioFilters(filters, otherParams)}`;
	},
});

export const usePortfolioLeasesQuery = (
	params: PortfolioLeasesQueryParams,
	options?: UseQueryOptions<PortfolioLeasesResponse>
) => {
	return useQueryHook({
		queryKey: [QUERY_KEYS.PORTFOLIO_LEASES, params],
		queryFn: fetchPortfolioLeases(params),
		...options,
		enabled: params.portfolioId != null && (options?.enabled ?? true),
	});
};

export type PortfolioLeasesResponse = LeaseComp[];
