import { MQB, Spinner } from '@compstak/ui-kit';
import { usePortfolioByIdQueryV2 } from 'api/portfolio/portfolioById/usePortfolioByIdQueryV2';
import { routes } from 'router';
import styled from 'styled-components';
import { APP_HEADER_HEIGHT } from '../constants';
import { Dashboard } from './Dashboard/Dashboard';
import { NoPortfolios } from './NoPortfolios';
import { ListViewV2 } from './ListView/ListViewV2';
import { MapViewV2 } from './MapView/MapViewV2';
import { PortfolioFiltersProvider } from './PortfolioFiltersProvider';
import { PortfolioSidebar } from './PortfolioSidebar';
import { useRedirectPortfolio } from './hooks/useRedirectPortfolio';
import { PortfolioLeasesFiltersProvider } from './LeaseActivity/PortfolioLeasesFiltersProvider';
import { LeasesListAndMapViewProvider } from './ListView/LeasesListAndMapViewProvider';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { PortfolioAlertsStateProvider } from './PortfolioAlertsStateProvider';
import { PortfolioSidebarV2 } from './PortfolioSidebar/PortfolioSidebarV2';
import { PortfolioPropertiesProvider } from './Properties/PortfolioPropertiesProvider';
import { LIST_VIEW_INCLUDE_FIELDS } from 'api/portfolio/constants';

export const PortfolioLayout = () => {
	const { portfolioRevampBenchmarkingFF } = useFeatureFlags();

	const params = routes.portfolioByIdView.useParams();
	const [searchParams] = routes.portfolioByIdView.useSearchParams();

	const portfolioId = params.portfolioId
		? Number(params.portfolioId)
		: undefined;

	const { data: portfolio, isFetching: isFetchingPortfolioById } =
		usePortfolioByIdQueryV2({
			id: portfolioId,
		});

	const { isFetching: isRedirecting } = useRedirectPortfolio();

	const isFetching = isRedirecting || isFetchingPortfolioById;

	const content = (() => {
		if (isFetching) {
			return (
				<LoadingContainer>
					<Spinner />
				</LoadingContainer>
			);
		}

		if (!portfolio) {
			return <NoPortfolios />;
		}

		const portfolioId = portfolio.portfolio.id;

		return (
			<PortfolioFiltersProvider
				key={portfolioId}
				portfolioMarkets={portfolio.markets}
			>
				<PortfolioLeasesFiltersProvider key={portfolioId} portfolio={portfolio}>
					<Content>
						{params.viewType === 'map' && (
							<MapViewV2 portfolioId={portfolioId} />
						)}
						{params.viewType === 'list' && (
							<PortfolioPropertiesProvider
								portfolio={portfolio}
								includeFields={LIST_VIEW_INCLUDE_FIELDS}
								searchParams={searchParams}
							>
								<LeasesListAndMapViewProvider portfolioId={portfolioId}>
									<ListViewV2 portfolioId={portfolioId} />
								</LeasesListAndMapViewProvider>
							</PortfolioPropertiesProvider>
						)}
						{(!params.viewType || params.viewType === 'dashboard') && (
							<Dashboard portfolio={portfolio} />
						)}
					</Content>
				</PortfolioLeasesFiltersProvider>
			</PortfolioFiltersProvider>
		);
	})();

	return (
		<PortfolioAlertsStateProvider>
			<Root>
				{portfolioRevampBenchmarkingFF ? (
					<PortfolioSidebarV2 portfolioId={portfolioId} />
				) : (
					<PortfolioSidebar portfolioId={portfolioId} />
				)}
				{content}
			</Root>
		</PortfolioAlertsStateProvider>
	);
};

const Root = styled.div`
	display: flex;
	height: calc(100vh - ${APP_HEADER_HEIGHT}px);
	// defaults
	font-family: ${(p) => p.theme.typography.fontFamily.gotham};
	line-height: 1.2;
	box-sizing: border-box;

	font-size: 1rem;

	@media (max-width: ${MQB.D_1280}) {
		font-size: 0.875rem;
	}
`;

const Content = styled.div`
	flex: 1;
	height: 100%;
`;

const LoadingContainer = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
`;
