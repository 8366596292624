import { APIClientNotOkResponseError } from '@compstak/common';
import {
	UseMutationOptions,
	useQuery,
	useQueryClient,
} from '@tanstack/react-query';
import { usePortfolioInteractionsQuery } from 'api/portfolio/interactions/usePortfolioInteractionsQuery';
import { useSelectPortfolioMutation } from 'api/portfolio/interactions/useSelectPortfolioMutation';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useCallback } from 'react';

export type SelectedPortfolioIds = number[];

type SelectPortfolioParams = { portfolioId: number; selected: boolean };

type UsePortfoliosSelectionResult = {
	selectedPortfolioIds: SelectedPortfolioIds;
	selectPortfolio: (
		args: SelectPortfolioParams,
		options?: UseMutationOptions<
			void,
			APIClientNotOkResponseError,
			SelectPortfolioParams
		>
	) => void;
	resetSelectedPortfolios: () => void;

	isFetching: boolean;
	isUpdatingPortfolioSelection: boolean;
};

export const PORTFOLIO_SELECTION = 'PORTFOLIO_SELECTION';
const LOCAL_STORAGE_KEY = 'selectedPortfoliosOrder';

export const usePortfoliosSelection = (): UsePortfoliosSelectionResult => {
	const { portfolioRevampBenchmarkingFF } = useFeatureFlags();
	const queryClient = useQueryClient();

	const { refetch } = usePortfolioInteractionsQuery({ enabled: false });

	const { data: _selectedPortfolioIds, isFetching } = useQuery({
		queryKey: [PORTFOLIO_SELECTION],
		queryFn: async () => {
			const { data } = await refetch();

			if (!data) {
				return [];
			}

			const storedPortfolioIds: number[] = JSON.parse(
				localStorage.getItem(LOCAL_STORAGE_KEY) || '[]'
			);

			const selectedPortfoliosFromStorage = storedPortfolioIds.filter(
				(id) => data[id].selected
			);

			// If there are no selected portfolios in local storage, use the selected portfolios from the BE
			// just in case the user has selected some portfolios before this logic was implemented
			// https://github.com/compstak/apps/pull/5252/files#r1941408565
			const selectedPortfoliosFromBE = Object.typedKeys(data)
				.filter((portfolioId) => data[portfolioId].selected)
				.map(Number);

			const initialSelectedPortfolioIds =
				storedPortfolioIds.length === 0
					? selectedPortfoliosFromBE
					: selectedPortfoliosFromStorage;

			return initialSelectedPortfolioIds;
		},
		enabled: portfolioRevampBenchmarkingFF,
	});

	const selectedPortfolioIds = _selectedPortfolioIds ?? [];

	const setSelectedPortfoliosIds = useCallback(
		(newSelectedPortfolioIds: SelectedPortfolioIds) => {
			localStorage.setItem(
				LOCAL_STORAGE_KEY,
				JSON.stringify(newSelectedPortfolioIds)
			);
			queryClient.setQueryData([PORTFOLIO_SELECTION], newSelectedPortfolioIds);
		},
		[queryClient]
	);

	const {
		mutate: selectPortfolioMutation,
		isLoading: isUpdatingPortfolioSelection,
	} = useSelectPortfolioMutation();

	const selectPortfolio = (
		{ portfolioId, selected }: SelectPortfolioParams,
		options?: UseMutationOptions<
			void,
			APIClientNotOkResponseError,
			SelectPortfolioParams
		>
	) => {
		setSelectedPortfoliosIds(
			selected
				? [...selectedPortfolioIds, portfolioId]
				: selectedPortfolioIds.filter(
						(prevPortfolioId) => prevPortfolioId !== portfolioId
					)
		);
		selectPortfolioMutation({ portfolioId, selected }, options);
	};

	const resetSelectedPortfolios = () => {
		setSelectedPortfoliosIds([]);
		for (const selectedPortfolioId of selectedPortfolioIds) {
			selectPortfolioMutation({
				portfolioId: selectedPortfolioId,
				selected: false,
			});
		}
	};

	return {
		selectedPortfolioIds,
		selectPortfolio,
		resetSelectedPortfolios,
		isFetching,
		isUpdatingPortfolioSelection,
	};
};
