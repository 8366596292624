import {
	ChartBox,
	ChartOption,
	ChartTypeLease,
} from 'PortfolioAnalytics/Charts/ChartSelect';
import { CHART_NAME } from './BenchmarkingLeaseExpirationChartV2';
import { ChartLegendV1 } from '../ChartLegend';
import { useMemo } from 'react';
import { usePortfoliosByIdsQueries } from 'api/portfolio/portfolioById/usePortfoliosByIdsQueries';
import { usePortfolioFilters } from 'PortfolioAnalytics/PortfolioFiltersProvider';
import { usePortfoliosSelection } from 'PortfolioAnalytics/hooks/usePortfoliosSelection';
import { BENCHMARKING_COLORS } from 'PortfolioAnalytics/Benchmarking/constants';
import { PortfolioLeaseExpirationsBySpaceTypesByYears } from 'api';
import {
	VictoryAxis,
	VictoryBar,
	VictoryChart,
	VictoryGroup,
	VictoryTooltip,
	VictoryVoronoiContainer,
} from 'victory';
import { LeaseExpirationTooltip } from './LeaseExpirationTooltip';
import { Portfolio } from 'api';
import { BenchmarkingChartStateWrapper } from '../BenchmarkingChartStatusWrapper';

export const BenchmarkingLeaseExpirationChartV1 = () => {
	return (
		<ChartBox chartName={CHART_NAME}>
			{(selectedChart) => (
				<BenchmarkingLeaseExpirationChartV1Content
					selectedChart={selectedChart}
				/>
			)}
		</ChartBox>
	);
};

export const BenchmarkingLeaseExpirationChartV1Content = ({
	selectedChart,
}: {
	selectedChart: ChartOption<'lease'>;
}) => {
	const { selectedPortfolioIds } = usePortfoliosSelection();
	const { filters } = usePortfolioFilters();

	const selectedPortfoliosResults = usePortfoliosByIdsQueries(
		selectedPortfolioIds.map((id) => ({ id, filters }))
	);

	const selectedPortfoliosResultsWithColors = useMemo(() => {
		return selectedPortfoliosResults
			.map((result, index) => ({
				...result,
				color: BENCHMARKING_COLORS[index],
			}))
			.reverse()
			.filter(
				(result) =>
					result.data?.leaseExpirationsBySpaceTypesByYears &&
					result.data?.leaseExpirationsBySpaceTypesByYears?.length > 0
			);
	}, [selectedPortfoliosResults]);

	const isFetching = selectedPortfoliosResults.some(
		(result) => result.isFetching
	);

	const isError = selectedPortfoliosResults.some((result) => result.isError);

	const isLeaseExpirationsEmpty = useMemo(
		() =>
			selectedPortfolioIds.length > 0 &&
			selectedPortfoliosResults.every(
				(result) =>
					result.data?.leaseExpirationsBySpaceTypesByYears?.length === 0
			),
		[selectedPortfoliosResults, selectedPortfolioIds]
	);

	const noData =
		!isFetching &&
		(selectedPortfolioIds.length === 0 || isLeaseExpirationsEmpty);

	return (
		<BenchmarkingChartStateWrapper
			isError={isError}
			isFetching={isFetching}
			noData={noData}
			showNoDataMessage={!isLeaseExpirationsEmpty}
		>
			<VictoryChart
				domainPadding={20}
				containerComponent={
					<VictoryVoronoiContainer
						voronoiDimension="x"
						labels={() => ' '}
						labelComponent={
							<VictoryTooltip
								flyoutComponent={
									<LeaseExpirationTooltip
										width={128}
										height={20}
										selectedChartType={selectedChart.type}
									/>
								}
							/>
						}
					/>
				}
			>
				<VictoryAxis
					style={{
						tickLabels: { fontSize: 12 },
						axis: { stroke: 'transparent' },
					}}
					// For some reason, if tickValues are not converted to strings, the values will be overlapping
					tickValues={new Array(5)
						.fill(new Date())
						.map((date: Date, i) => (date.getFullYear() + i).toString())}
				/>
				<VictoryGroup offset={6} horizontal>
					{selectedPortfoliosResultsWithColors.map((result, idx) => {
						const firstFiveYears =
							result.data?.leaseExpirationsBySpaceTypesByYears
								.filter((_, i) => i < 5)
								.map((value, i) => {
									return {
										x: i + 1, // x is the year
										y: getSelectedChartValue(selectedChart.type, value),
									};
								});

						return (
							<VictoryBar
								barWidth={6}
								key={idx}
								data={firstFiveYears}
								style={{ data: { fill: `${result.color}` } }}
							/>
						);
					})}
				</VictoryGroup>
			</VictoryChart>
			<ChartLegendV1
				portfolios={
					selectedPortfoliosResults
						.map((result) => result?.data)
						.filter((portfolio) => portfolio) as Portfolio[]
				}
				colors={BENCHMARKING_COLORS}
				metric="leaseExpirationsBySpaceTypesByYears"
			/>
		</BenchmarkingChartStateWrapper>
	);
};

const getSelectedChartValue = (
	chartType: ChartTypeLease,
	value: PortfolioLeaseExpirationsBySpaceTypesByYears
) => {
	switch (chartType) {
		case 'leaseAmount':
			return value.expiringLeaseAmount;
		case 'leaseCount':
			return value.expiringLeaseCount;
		default:
			return value.totalSqFt;
	}
};
